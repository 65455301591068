export class TextHandler {
  constructor(words, textElement, colors = ['#fff']) {
    this.words = [...words];
    this.colors = [...colors];
    this._textElement = textElement;
    this.initialize();
  }

  initialize() {
    const word = this.words[0];
    this.word = word;
    this.setColorText(this.colors[0]);
    this.isWaiting = false;
    this.isBacking = false;
    this.index = 0;
    this.substring = '';
    this.shiftColorsAndWords();
  }

  get length() {
    return this.word.length;
  }

  get done() {
    return this.index === 0 && this.isBacking;
  }

  init() {
    this.handleText();
  }

  handleText() {
    setInterval(() => {
      if (!this.isWaiting) {
        this.changeText(this.nextSubString());
      }
      if (this.done) {
        this.initialize();
      }
      this.handleStep();
    }, 120);
  }

  nextSubString() {
    this.index += this.isBacking ? -1 : 1;

    return this.word.substring(0, this.index);
  }

  handleStep() {
    if (this.isEndWord()) {
      this.isBacking = true;
      this.isWaiting = true;
      window.setTimeout(() => {
        this.isWaiting = false;
      }, 1000);
    }
  }

  isEndWord() {
    return this.index === this.length && !this.isWaiting;
  }

  shiftColorsAndWords() {
    this.colors.push(this.colors.shift());
    this.words.push(this.words.shift());
  }

  changeText(text) {
    this._textElement.changeText(text);
  }

  setColorText(color) {
    this._textElement.changeTextColor(color);
  }
}
