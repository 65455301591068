import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { theme } from '../../utils/colors';

export const ListContainer = styled.ul`
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  position: relative;
  margin-top: 20px;

  & > h3 {
    display: flex;
    justify-content: center;
    width: 100%;
    color: ${theme.lightgrey};
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
`;
