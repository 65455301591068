/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { MessageList, Input, Button as Btn } from 'react-chat-elements';
import { Container, Content, Header } from './styles';
import { theme } from '../../utils';
import { LoadInbox } from './hooks';
import { Loader, LottieWraper } from '../../componets';
import { history } from '../../services';

export default function Questions() {
  const [text, setText] = useState('');
  const [inputRef, setInputRef] = useState();
  const [inbox, sendMenssage, loading] = LoadInbox(
    history.location?.state?.packs
  );
  const { order } = inbox;
  return (
    <Container>
      {order ? (
        <Content>
          <Header>
            {loading ? (
              <Loader full loading={loading} />
            ) : (
              <section>
                <p>{order?.buyer?.nickname}</p>
                <p>{`${order?.buyer?.first_name}  ${order?.buyer?.last_name}`}</p>
                <div>
                  <img alt="product" src={order?.item?.secure_thumbnail} />
                  <p>{order?.item?.title}</p>
                </div>
              </section>
            )}
          </Header>
          <MessageList className="message-list" dataSource={inbox.messages} />
          <Input
            ref={el => setInputRef(el)}
            onChange={e => setText(e.target.value)}
            placeholder="Digite aqui..."
            multiline
            rightButtons={
              <Btn
                onClick={() => {
                  inputRef.clear();
                  sendMenssage(inbox.order, text);
                }}
                backgroundColor={theme.primary}
                disabled={!text}
                text="Enviar"
              />
            }
          />
        </Content>
      ) : (
        <LottieWraper />
      )}
    </Container>
  );
}
