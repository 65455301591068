import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  width: 100%;
  height: 400px;
  padding: 0 20px;
  background: ${theme.white};
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  border-radius: 5px;
  grid-area: row0;
  g {
    text-transform: capitalize;
  }
`;
