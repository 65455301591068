import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import MaskedInput from 'react-text-mask';
import { theme } from '../../utils/colors';

const shrinkLabelStyles = css`
  top: -18px;
  font-size: 12px;
`;

export const GroupContainer = styled.div`
  position: relative;
  margin: 35px 0 0;

  input[type='password'] {
    letter-spacing: 0.3em;
  }
`;

const inputIsInverted = css`
  background-color: ${lighten(0.1, theme.white)};
  color: ${theme.text};
  border: 1px solid ${theme.lightgrey};
  border-radius: 5px;
`;

const labelIsInverted = css`
  background-color: ${lighten(0.1, theme.white)};
  color: ${theme.lightgrey};
`;

const getInputStyles = props => {
  if (props.inverted) {
    return inputIsInverted;
  }
  return '';
};

const getLabelStyles = props => {
  if (props.inverted) {
    return labelIsInverted;
  }
  return '';
};

export const FormInputContainer = styled.input`
  background-color: ${lighten(0.15, theme.primary)};
  color: ${theme.white};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: 1px solid ${theme.white};
  border-radius: 6px;
  ${getInputStyles}

  &:focus {
    outline: none;
  }

  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

export const MaskedInputStyled = styled(MaskedInput)`
  background-color: ${lighten(0.15, theme.primary)};
  color: ${theme.white};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border-radius: 0;
  border: 1px solid ${theme.white};
  margin: 25px 0;
  border-radius: 6px;
  ${getInputStyles}

  &:focus {
    outline: none;
  }

  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

export const FormInputLabel = styled.label`
  color: ${theme.white};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  ${getLabelStyles}
  &.shrink {
    ${shrinkLabelStyles}
  }
`;

export const ErrorContainer = styled.div`
  color: ${theme.negative};
`;
