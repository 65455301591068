import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import {
  updateProfileSuccess,
  updateProfileFailure,
  addAccountSuccess,
  addAccountFailure,
  deleteAccountFailure,
  deleteAccountSuccess,
  updateAccount,
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const { first_name, last_name, email, ...rest } = payload.data;
    const profile = {
      first_name,
      last_name,
      email,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'users', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response));
  } catch (err) {
    toast.error('Erro ao atualizar perfil, confira seus dados!');
    yield put(updateProfileFailure());
  }
}

export function* addAccount({ payload }) {
  try {
    const { code } = payload;

    const { account, actionType } = yield call(api.post, 'account', { code });
    if (actionType === 'create') {
      yield put(addAccountSuccess(account));
      toast.success('Conta adicionada com sucesso!');
      api.defaults.headers.accountId = account._id;
      history.push('/accounts');
    } else {
      toast.success(`Token de ${account.nickname} renovado com sucesso!`);
      history.push('/accounts');
      yield put(updateAccount(account));
    }
  } catch (err) {
    toast.error('Erro ao adicionar conta !');
    toast.error(err.message);
    yield put(addAccountFailure());
  }
}

export function* deleteAccount({ payload }) {
  try {
    const { accountId } = payload;
    yield call(api.delete, `account/${accountId}`);

    toast.success('Conta deletada com sucesso!');
    yield put(deleteAccountSuccess(accountId));
  } catch (err) {
    toast.error('Erro ao deletar conta !');
    yield put(deleteAccountFailure());
  }
}

export function setAccount({ payload }) {
  if (!payload) return;

  const { account } = payload.user;

  if (account) {
    api.defaults.headers.accountId = account._id;
  }
}

export function selectAccount({ payload }) {
  if (!payload) return;

  const { accountId } = payload;

  if (accountId) {
    api.defaults.headers.accountId = accountId;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setAccount),
  takeLatest('@user/SELECT_ACCOUNT', selectAccount),
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/ADD_ACCOUNT_REQUEST', addAccount),
  takeLatest('@user/DELETE_ACCOUNT_REQUEST', deleteAccount),
]);
