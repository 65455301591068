import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import restL from './json/rest.json';

const LottieWraper = ({ height, width, isStopped, isPaused }) => {
  const data = restL;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    </div>
  );
};

LottieWraper.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
};

LottieWraper.defaultProps = {
  height: '100%',
  width: '100%',
  isStopped: false,
  isPaused: false,
};

export default LottieWraper;
