import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 5px;
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    align-items: center;

    h4 {
      font-weight: 600;
      color: ${theme.white};
    }

    @media (max-width: ${theme.mobile}) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`;

export const List = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & + li {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid ${transparentize(0.8, theme.black)};
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 40%;

  img {
    width: 100px;
    height: 100px;
  }
  div {
    margin-right: 5px;
    span {
      color: ${theme.positive};
      font-weight: lighter;
    }
    p {
      padding: 5px;
      color: ${theme.text};
      margin-top: 10px;
    }
    a {
      text-decoration: none;
      color: inherit;
      font-weight: lighter;
    }
  }
  @media (max-width: ${theme.mobile}) {
    width: 100%;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  color: ${theme.text};

  div {
    display: flex;
    width: 100%;
    align-items: center;

    p {
      padding: 5px;
    }
  }
  .time {
    color: ${theme.lightgrey};
    font-size: 10px;
    margin-bottom: 10px;
  }
  svg {
    min-width: 20px;
  }
  @media (max-width: ${theme.mobile}) {
    width: 100%;
  }
`;

export const TimeAnswer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  p {
    color: ${theme.text};
    font-weight: bold;
    padding: 5px;
    font-size: 20px;
  }
  span {
    color: ${theme.lightgrey};
  }
  @media (max-width: ${theme.mobile}) {
    width: 100%;
  }
`;
