import produce from 'immer';
import * as types from '../constants';

const INITIAL_STATE = {
  items: { data: [], paging: {} },
  loading: false,
};

export function adverts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.FETCH_ITEMS_REQUEST: {
        draft.loading = true;
        break;
      }
      case types.FETCH_ITEMS_FAILURE: {
        draft.loading = false;
        break;
      }
      case types.FETCH_ITEMS_SUCCESS: {
        draft.items.data =
          action.payload.paging.page >= 2
            ? [...draft.items.data, ...action.payload.items]
            : action.payload.items;
        draft.items.paging = action.payload.paging;
        draft.loading = false;
        break;
      }

      default:
    }
  });
}

const INITIAL_STATE_COPY = {
  items: [],
  loading: false,
  copied: [],
};

export function copy(state = INITIAL_STATE_COPY, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.ADD_TO_COPY: {
        if (draft.items.some(item => item.id === action.payload.id)) break;
        draft.items.push(action.payload);
        break;
      }

      case types.REMOVE_TO_COPY: {
        const filter_action = Array.isArray(action.payload)
          ? item => !action.payload.includes(item.id)
          : item => item.id !== action.payload;

        draft.items = draft.items.filter(filter_action);
        break;
      }
      case types.EDIT_TO_COPY: {
        const idx = draft.items.findIndex(
          element => element.id === action.payload.id
        );
        if (idx > -1) draft.items.splice(idx, 1, action.payload);
        break;
      }

      case types.REQUEST_TO_COPY: {
        draft.loading = true;
        break;
      }

      case types.SUCCESS_TO_COPY: {
        draft.loading = false;
        draft.items = [];
        break;
      }

      case types.FAILURE_TO_COPY: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
