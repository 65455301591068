/* eslint-disable dot-notation */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  BlockingResult,
  Table,
  ActionsContainer,
  Summary,
} from './styles';
import {
  CustomButton,
  Checkbox,
  ReadCSV,
  Select,
  Loader,
} from '../../componets';

import api from '../../services/api';
import { AccountOptions, useScroll } from '../../utils';

export default function BlacklistList() {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [sliceIndex, setSliceIndex] = useState(1);
  const [blocked, setBlocked] = useState({
    questions: false,
    order: false,
  });

  const options = AccountOptions();

  const handleBlocked = event => {
    const { name, checked } = event.target;
    setBlocked({
      ...blocked,
      [name]: checked,
    });
  };

  function createObj(obj) {
    return Object.keys(obj)
      .sort((a, b) => obj[a].length - obj[b].length)
      .reduce(
        (prev, key) => obj[key].map((v, i) => ({ ...prev[i], [key]: v })),
        []
      );
  }

  const handleCSVData = data => {
    setUsers(createObj(data));
  };

  const handleAccounts = e => {
    setAccounts(e?.map(({ value }) => value) ?? []);
  };

  const handleSubmit = async () => {
    if (accounts.length === 0) {
      toast.info('Selecione pelo menos uma conta', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      setLoading(true);
      const data = accounts
        .map(accountId =>
          users.map(user => ({
            accountId,
            blockedQuestion: blocked.questions,
            blockedBuy: blocked.order,
            id: user?.id,
          }))
        )
        .reduce((prev, curr) => [...prev, ...curr], []);

      await api.post(`blacklist`, {
        users: data,
      });
      setLoading(false);
      setUsers([]);
      toast.success('Lista importada com sucesso', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useScroll({ onYReachEnd: () => setSliceIndex(s => s + 1) });

  return (
    <Container>
      <Summary>
        <h3>Lista</h3>
      </Summary>
      {!!users.length && (
        <BlockingResult>
          <label>
            <Select
              closeMenuOnSelect={false}
              placeholder="Contas"
              isMulti
              onChange={handleAccounts}
              options={options}
              allowSelectAll
            />
          </label>
          <label>
            <Checkbox
              name="questions"
              checked={blocked?.questions}
              onChange={handleBlocked}
            />
            <span style={{ marginLeft: 8 }}>Bloquear para perguntas</span>
          </label>
          <label>
            <Checkbox
              name="order"
              checked={blocked.order}
              onChange={handleBlocked}
            />
            <span style={{ marginLeft: 8 }}>Bloquear para compras</span>
          </label>
          <CustomButton loading={loading} onClick={handleSubmit}>
            Bloquear
          </CustomButton>
        </BlockingResult>
      )}
      <ActionsContainer>
        <ReadCSV onload={handleCSVData} />
      </ActionsContainer>
      {!!users.length && (
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nickname</th>
              <th>Quantidade {users.length} </th>
            </tr>
          </thead>
          <tbody>
            {<Loader full loading={loading} />}
            {users.slice(0, 50 * sliceIndex)?.map(blockeds => (
              <tr key={blockeds._id}>
                <td>
                  <strong>ID</strong>
                  {blockeds.id}
                </td>
                <td>
                  <strong>Nickname</strong>
                  {blockeds.nickname}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
