/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  FaRegCommentDots,
  FaHistory,
  FaRegCommentAlt,
  FaRegTrashAlt,
} from 'react-icons/fa';
import { DateRangeWrapped, Input, Select, ListScroll } from '../../componets';
import api from '../../services/api';
import { timeAgo, formatLong, useScroll } from '../../utils';
import { Container, Content, List, Item, Question, TimeAnswer } from './styles';

export const questionsOptions = [
  {
    value: 'ANSWERED',
    label: 'Respondida',
  },
  {
    value: 'DELETED',
    label: 'Deletada',
  },
];

export default function QuestionsHistory() {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [query, setQuery] = useState({
    text: '',
    startDate: '',
    endDate: '',
    status: '',
  });

  const [questions, setQuestions] = useState([]);

  async function loadHist(params) {
    try {
      setLoading(true);
      const response = await api.get('question_historic', {
        params,
      });
      const { docs, ...info } = response;

      setQuestions(docs);
      setPagination(info);
      setLoading(false);
    } catch (e) {
      setQuestions([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadHist();
  }, []);

  const handleChange = event => {
    const { value, name } = event.target;
    if (name === 'status') {
      loadHist({ ...query, [name]: value });
    }

    if (name === 'date') {
      const date = {
        startDate: value.startDate,
        endDate: value.endDate,
      };
      loadHist({ ...query, ...date });

      setQuery({
        ...query,
        ...date,
      });
    } else {
      setQuery({
        ...query,
        [name]: value,
      });
    }
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        loadHist(query);
        break;
      default:
    }
  };

  const handleOnYReachEnd = async () => {
    try {
      if (pagination.hasNextPage && !loading) {
        setLoading(true);
        const response = await api.get('question_historic', {
          params: { ...query, page: pagination.nextPage },
        });
        const { docs, ...info } = response;

        setPagination(info);
        setQuestions([...questions, ...docs]);
        setLoading(false);
      }
    } catch (e) {
      setQuestions([]);
      setLoading(false);
    }
  };
  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <>
      <Container>
        <Content>
          <div>
            <h4>Histórico</h4>
            <Input
              primary
              name="text"
              fill="white"
              onChange={handleChange}
              value={query.text}
              icon="searchIcon"
              onKeyDown={handleKeyDown}
              onClick={() => query.text && loadHist(query)}
              loading={loading}
              placeholder="Publicação"
            />

            <Select
              primary
              name="status"
              onChange={({ value }) =>
                handleChange({ target: { value, name: 'status' } })
              }
              isLoading={loading}
              options={questionsOptions}
              placeholder="Status"
            />

            <DateRangeWrapped
              primary
              name="date"
              placeholder="Selecione uma data"
              onChange={handleChange}
            />
          </div>
        </Content>
        <ListScroll loading={loading}>
          {questions.map(q => (
            <List key={q.id}>
              <Item>
                <img src={q.item.secure_thumbnail} alt="" />
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={q.item.permalink}
                  >
                    <h3>{q.item.title}</h3>
                  </a>

                  <p>
                    Publicação{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={q.item.permalink}
                    >
                      {q.item.id}
                    </a>
                  </p>
                  <p>
                    <strong>{q.from.nickname}</strong> ({q.from.points} pontos)
                  </p>
                </div>
              </Item>
              <Question>
                <div>
                  <FaRegCommentDots size={20} />
                  <p>{q.text}</p>
                </div>
                <div className="time">
                  <p>{formatLong(q.date_created)}</p>
                  <FaHistory size={12} />
                </div>
                {q.answer ? (
                  <>
                    <div>
                      <FaRegCommentAlt size={20} />
                      <p>{q.answer.text}</p>
                    </div>
                    <div className="time">
                      <p>{formatLong(q.answer.date_created)}</p>
                      <FaHistory size={12} />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <FaRegTrashAlt size={20} />
                      <p>A pergunta foi deletada</p>
                    </div>
                    <div className="time">
                      <p>{formatLong(q.date_created)}</p>
                      <FaHistory size={12} />
                    </div>
                  </>
                )}
              </Question>
              {q.answer && (
                <TimeAnswer>
                  <p>{timeAgo(q.answer.date_created, q.date_created)}</p>
                  <span>Tempo de resposta</span>
                </TimeAnswer>
              )}
            </List>
          ))}
        </ListScroll>
      </Container>
    </>
  );
}
