export function updateProfileRequest(data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}

export function addAccountRequest(code) {
  return {
    type: '@user/ADD_ACCOUNT_REQUEST',
    payload: { code },
  };
}

export function addAccountSuccess(account) {
  return {
    type: '@user/ADD_ACCOUNT_SUCCESS',
    payload: { account },
  };
}

export function addAccountFailure() {
  return {
    type: '@user/ADD_ACCOUNT_FAILURE',
  };
}

export function updateAccount(account) {
  return {
    type: '@user/UPDATE_ACCOUNT',
    payload: { account },
  };
}

export function selectAccount(accountId) {
  return {
    type: '@user/SELECT_ACCOUNT',
    payload: { accountId },
  };
}

export function deleteAccountRequest(accountId) {
  return {
    type: '@user/DELETE_ACCOUNT_REQUEST',
    payload: { accountId },
  };
}

export function deleteAccountSuccess(accountId) {
  return {
    type: '@user/DELETE_ACCOUNT_SUCCESS',
    payload: { accountId },
  };
}

export function deleteAccountFailure() {
  return {
    type: '@user/DELETE_ACCOUNT_FAILURE',
  };
}
