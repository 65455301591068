import React from 'react';
import Toggle from 'react-toggled';
import { FaChevronRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { DropDownList } from './styles';

const ToogleWrapper = ({ title, children, icon }) => {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <DropDownList open={on}>
          <button type="button" onClick={toggle}>
            {icon && <FaChevronRight size={20} />}
            <h3>{title}</h3>
          </button>
          <ul>{children}</ul>
        </DropDownList>
      )}
    </Toggle>
  );
};

ToogleWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  icon: PropTypes.bool,
};

ToogleWrapper.defaultProps = {
  icon: true,
};

export default ToogleWrapper;
