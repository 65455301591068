import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { Modal, Input, CustomButton } from '../../../../componets';
import { ModalContainer, ButtonContainer, InputsContainer } from './styles';

const ModalEdit = ({ onSubmit, item }) => {
  const [open, setOpen] = useState(false);
  const [itemFields, setItemFields] = useState(item);

  const handleChange = event => {
    const { value, name } = event.target;

    setItemFields({ ...itemFields, [name]: value });
  };

  const handleChangeValue = event => {
    const { value, name } = event.target;
    const b = value.replace(/[^0-9]/g, '').split('');
    b.splice(b.length - 2, 0, b.length > 2 ? '.' : '.0');

    const a = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(b.join(''));
    setItemFields({ ...itemFields, [name]: a });
  };
  const removeMask = fields => {
    const help = s =>
      typeof s === 'string'
        ? Number(s.replace(/[^0-9,]/g, '').replace(',', '.'))
        : s;
    const price = help(fields.price);

    return { ...fields, price };
  };

  const handleSubmit = e => {
    e.preventDefault();

    onSubmit(removeMask(itemFields));
    setOpen(false);
  };

  const form = () => (
    <form onSubmit={handleSubmit}>
      <InputsContainer>
        <div>
          <label htmlFor="title">Título</label>
          <Input
            onChange={handleChange}
            type="text"
            required
            value={itemFields.title}
            name="title"
            placeholder="Título"
            maxlength="60"
          />
        </div>
        <div>
          <label htmlFor="price">Preço</label>
          <Input
            onChange={handleChangeValue}
            name="price"
            placeholder="Preço de custo"
            value={itemFields.price}
          />
        </div>

        <div>
          <label htmlFor="available_quantity">Quantidade disponível</label>
          <Input
            onChange={handleChange}
            type="number"
            min="0"
            name="available_quantity"
            placeholder="Quantidade disponível"
            value={itemFields.available_quantity}
          />
        </div>

        <div className="descriptions">
          <label htmlFor="descriptions">Descrição</label>
          <TextareaAutosize
            onChange={handleChange}
            type="textarea"
            name="descriptions"
            placeholder="Descrição"
            value={itemFields.descriptions}
          />
        </div>
      </InputsContainer>
      <ButtonContainer>
        <CustomButton type="submit">Editar</CustomButton>
      </ButtonContainer>
    </form>
  );

  return (
    <CustomButton onClick={() => setOpen(true)}>
      Editar
      <Modal open={open} onClose={() => setOpen(false)} title="Editar produto">
        <ModalContainer>{form()}</ModalContainer>
      </Modal>
    </CustomButton>
  );
};

ModalEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    available_quantity: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
};
export default ModalEdit;
