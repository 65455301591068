import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import { darken } from 'polished';
import { theme } from '../../utils/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ButtonStyled = styled.button`
  width: 100%;
  background: ${({ color }) => color};
  margin: 5px 0;
  min-height: 44px;
  padding: 0 5px;
  font-weight: bold;
  color: ${theme.white};
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.4s;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  ${({ color }) => `&:hover {
    background: ${darken(0.04, color)};
  }};`}

  span {
    color: ${({ textcolor }) => textcolor};
  }
`;

export const FaSpinnerAnimated = styled(FaSpinner)`
  color: ${({ textcolor }) => textcolor};
  animation: ${rotate} 2s linear infinite;
`;
