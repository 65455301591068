/* eslint-disable no-template-curly-in-string */
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FaInfoCircle } from 'react-icons/fa';
import { Button, Checkbox, Loader } from '../../componets';
import { Container, Content, Card, Field, Info } from './styles';
import { LoadMessages } from './hooks';

export default function Message() {
  const [
    autoMessages,
    handleChange,
    salveAutoMessages,
    loading,
  ] = LoadMessages();

  const helperCheckBox = event => {
    const { checked: value, name } = event.target;
    handleChange({ target: { value, name } });
  };

  return (
    <Container>
      <Content>
        <Loader loading={loading} full />
        <Info>
          <FaInfoCircle name="FaInfoCircle" />
          <span>
            Não inclua links para redes sociais nem URLs encurtadas nas suas
            mensagens, pois o Mercado Livre as bloqueará e elas não serão
            entregues.
          </span>
        </Info>
        <Info>
          <FaInfoCircle name="FaInfoCircle" />
          <span>
            Escreva @NOME para chamar o comprador pelo seu nome ou @COMPRADOR
            para utilizar seu apelido. Escreva @CODIGORASTREIO para usar o
            código de rastreamento e @LINKRASTREIO para utilizar seu link.
          </span>
        </Info>
        <Card>
          <Field>
            <div>
              <label>
                <Checkbox
                  name="order_creation_checked"
                  checked={!!autoMessages.order_creation_checked}
                  onChange={helperCheckBox}
                />

                <span style={{ marginLeft: 8 }}>
                  Enviar automaticamente uma mensagem ao comprador assim que
                  realize a compra (seu pagamento já estará creditado)
                </span>
              </label>
            </div>
            <TextareaAutosize
              name="order_creation_text"
              value={autoMessages.order_creation_text}
              onChange={handleChange}
              placeholder="Escreva sua resposta ..."
              rows="8"
              autoFocus
            />
          </Field>
          <Field>
            <div>
              <label>
                <Checkbox
                  name="product_shipped_checked"
                  checked={!!autoMessages.product_shipped_checked}
                  onChange={helperCheckBox}
                />

                <span style={{ marginLeft: 8 }}>
                  Enviar uma mensagem ao comprador quando o produto estiver em
                  trânsito
                </span>
              </label>
            </div>
            <TextareaAutosize
              name="product_shipped_text"
              value={autoMessages.product_shipped_text}
              onChange={handleChange}
              placeholder="Escreva sua resposta ..."
              rows="8"
            />
          </Field>
          <Field>
            <div>
              <label>
                <Checkbox
                  name="product_delivered_checked"
                  checked={!!autoMessages.product_delivered_checked}
                  onChange={helperCheckBox}
                />
                <span style={{ marginLeft: 8 }}>
                  Enviar uma mensagem ao comprador quando o produto tiver sido
                  entregue
                </span>
              </label>
            </div>
            <TextareaAutosize
              name="product_delivered_text"
              value={autoMessages.product_delivered_text}
              onChange={handleChange}
              placeholder="Escreva sua resposta ..."
              rows="8"
            />
          </Field>
          <Button
            loading={loading}
            type="submit"
            onClick={() => salveAutoMessages()}
            disabled={loading}
          >
            Modificar apenas nesta conta
          </Button>
          <Button
            loading={loading}
            type="submit"
            onClick={() => salveAutoMessages(true)}
            disabled={loading}
          >
            Modificar em todas contas
          </Button>
        </Card>
      </Content>
    </Container>
  );
}
