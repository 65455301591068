import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { theme } from '../../utils/colors';
import { Container } from './styles';

const Chip = ({ icon, text, color, background, tooltip }) => {
  return (
    <Popup
      trigger={
        <Container color={color} background={background}>
          {icon}
          <p>{text}</p>
        </Container>
      }
      position="top center"
      on="hover"
      disabled={!tooltip}
    >
      {tooltip}
    </Popup>
  );
};

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node]),
  tooltip: PropTypes.oneOfType([PropTypes.node]),
  color: PropTypes.string,
  background: PropTypes.string,
};

Chip.defaultProps = {
  icon: null,
  tooltip: null,
  color: theme.text,
  background: theme.card,
};

export default Chip;
