import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fabricCardsData, fabricChartData, LoadData } from './hooks';
import {
  Card,
  CardInfo,
  CurveAbc,
  DateRangeWrapped,
  LineChart,
  ListDashboard,
  Select,
} from '../../componets';
import { formatMoney, timeRelativeToday } from '../../utils';
import {
  Container,
  Header,
  HeaderInfo,
  Content,
  ColumnLeft,
  ColumnRight,
} from './styles';

const orderOptions = [
  {
    value: 'paid',
    label: 'Pago',
  },
  {
    value: 'cancelled',
    label: 'Cancelado',
  },
  {
    value: 'mediations',
    label: 'Reclamação',
  },
];

export default function Dashboard() {
  const { account, profile } = useSelector(state => state.user);
  const dateDefault = {
    to: moment()
      .endOf('day')
      .toISOString(),
    from: moment()
      .startOf('day')
      .toISOString(),
  };
  const [params, setParams] = useState(dateDefault);
  const [items_id, setItems_id] = useState({});

  const [metricsData, loading] = LoadData('metrics', params);

  const [chartData, loadingChart] = LoadData('chart_data', params);
  const [curveAbcChart] = LoadData('chart_data/curve_abc', params);
  const chartDataFormated = fabricChartData(chartData);
  const metricsDataFormated = fabricCardsData(metricsData?.metrics);

  useEffect(() => {
    if (account) setParams(old => ({ ...old, seller_ids: account.id }));
  }, [account]);

  const handleChangeSelect = value => {
    const seller_ids =
      value?.reduce((acc, { value: id }) => `${acc},${id}`, '') ?? '';
    setParams({ ...params, seller_ids });
  };

  const handleChangeStatus = ({ value: status }) => {
    setParams({ ...params, status });
  };

  const handleChangeDate = event => {
    const { value } = event.target;
    const date = value
      ? {
          from: value.startDate,
          to: moment(value.endDate)
            .endOf('day')
            .toISOString(),
        }
      : dateDefault;

    setParams({ ...params, ...date });
  };

  const options = useMemo(
    () =>
      profile?.accounts?.map(acc => ({
        value: acc.id,
        label: acc.nickname,
      })),
    [profile]
  );

  return (
    <Container>
      <Header>
        <div />
        <Select
          closeMenuOnSelect={false}
          placeholder="Conta"
          isMulti
          onChange={handleChangeSelect}
          options={options}
          allowSelectAll
        />
        <DateRangeWrapped
          name="date"
          placeholder="Selecione uma data"
          onChange={handleChangeDate}
          months={1}
        />
        <Select
          name="status"
          onChange={handleChangeStatus}
          isLoading={loading}
          options={orderOptions}
          placeholder="Status"
        />
        <div />
        <HeaderInfo />
      </Header>
      <Content>
        <ColumnLeft>
          <Card
            title={timeRelativeToday(params.from, params.to)}
            content={chartDataFormated?.data}
            loading={loadingChart}
          />
          {chartData && <LineChart {...chartDataFormated} />}
        </ColumnLeft>
        <ColumnRight>
          {metricsDataFormated.map(metricData => (
            <CardInfo
              {...metricData}
              key={metricData.description}
              icon={metricData.icon}
              description={metricData.description}
              loading={loading}
            />
          ))}
        </ColumnRight>
        <CurveAbc
          {...curveAbcChart}
          selectData={a => {
            setItems_id({
              ...a,
              ...curveAbcChart?.data?.filter(
                c => c.id === (a?.serieId || a?.id)
              )[0],
            });
          }}
        />
        <ListDashboard
          params={params}
          curveAbcData={curveAbcChart}
          items_id={items_id}
        />
      </Content>
    </Container>
  );
}
