import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, CustomButton, AsyncSelect } from '../../../../componets';
import {
  ModalContainer,
  Product,
  ButtonContainer,
  InputsContainer,
} from './styles';
import { LoadData } from './hooks';

const renderProduct = ({ item }) => (
  <Product>
    <img alt={item.title} src={item.secure_thumbnail} />
    <div>
      <small>
        <strong>{item.title}</strong>
      </small>
      <small>{item.id}</small>
      <small>
        <strong>{item.account?.nickname}</strong>
      </small>
    </div>
  </Product>
);

const ModalUpdate = ({ onSubmit, fieldsStock }) => {
  const [formFields, setFormFields] = useState(fieldsStock);

  const [open, setOpen] = useState(false);

  const [promiseOptions] = LoadData();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChangeItems = items => {
    if (items)
      setFormFields({
        ...formFields,
        items_id: items.map(({ value }) => value),
      });
  };

  const handleChange = event => {
    const { value, name } = event.target;

    setFormFields({ ...formFields, [name]: value?.replace(/^0+(?=\d)/, '') });
  };
  const handleChangeValue = event => {
    const { value, name } = event.target;
    const b = value.replace(/[^0-9]/g, '').split('');
    b.splice(b.length - 2, 0, b.length > 2 ? '.' : '.0');

    const a = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(b.join(''));
    setFormFields({ ...formFields, [name]: a });
  };
  const removeMask = fields => {
    const help = s =>
      typeof s === 'string' ? s.replace(/[^0-9,]/g, '').replace(',', '.') : s;
    const cost_price = help(fields.cost_price);
    const custom_cost = help(fields.custom_cost);

    return { ...fields, cost_price, custom_cost };
  };

  const handleSubmit = e => {
    e.preventDefault();

    return onSubmit(removeMask(formFields)).then(() => handleCancel());
  };

  const form = () => (
    <form onSubmit={handleSubmit}>
      <InputsContainer>
        <div>
          <label htmlFor="name">Nome</label>
          <Input
            onChange={handleChange}
            type="text"
            required
            value={formFields.name}
            name="name"
            placeholder="Nome"
          />
        </div>
        <div>
          <label htmlFor="cost_price">Preço de custo</label>
          <Input
            onChange={handleChangeValue}
            name="cost_price"
            placeholder="Preço de custo"
            value={formFields.cost_price}
          />
        </div>
        <div>
          <label htmlFor="custom_cost">Custos extras</label>
          <Input
            onChange={handleChangeValue}
            value={formFields.custom_cost}
            name="custom_cost"
            placeholder="Custos extras"
          />
        </div>
        <div>
          <label htmlFor="initial_quantity">Quantidade inicial</label>
          <Input
            onChange={handleChange}
            type="number"
            min="0"
            name="initial_quantity"
            placeholder="Quantidade"
            value={formFields.initial_quantity}
          />
        </div>
        <div className="select">
          <label htmlFor="items_id">Anúncios</label>
          <AsyncSelect
            name="items_id"
            renderOption={renderProduct}
            loadOptions={promiseOptions}
            placeholder="Publicação/nome"
            onChange={handleChangeItems}
            defaultValue={formFields.items_id.map(id => ({
              label: id,
              value: id,
            }))}
          />
        </div>
        <div className="description">
          <label htmlFor="description">Descrição</label>
          <Input
            onChange={handleChange}
            type="text"
            name="description"
            placeholder="Descrição"
            value={formFields.description}
          />
        </div>
      </InputsContainer>
      <ButtonContainer>
        <CustomButton type="submit">Editar</CustomButton>
      </ButtonContainer>
    </form>
  );

  return (
    <CustomButton onClick={() => setOpen(true)}>
      Editar
      <Modal open={open} onClose={() => setOpen(false)} title="Editar produto">
        <ModalContainer>{form()}</ModalContainer>
      </Modal>
    </CustomButton>
  );
};

ModalUpdate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldsStock: PropTypes.shape({
    name: PropTypes.string,
    items_id: PropTypes.arrayOf(PropTypes.string),
    custom_cost: PropTypes.number,
    initial_quantity: PropTypes.number,
    cost_price: PropTypes.number,
    stock_id: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};
export default ModalUpdate;
