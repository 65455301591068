import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const ContainerRating = styled.section`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  width: 300px;
  @media (max-width: ${theme.mobile}) {
    width: 100%;
  }
`;

export const Text = styled.p`
  margin: 5px 0;
  font-size: 0.75em;
  color: ${({ color }) => color};
  text-transform: capitalize;
`;
