import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ background }) => background};
  border-radius: 5px;
  padding: 2px;
  color: ${({ color, background }) =>
    background === theme.card ? color : theme.card};
`;
