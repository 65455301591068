import styled, { keyframes } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { FaSpinner } from 'react-icons/fa';
import { theme } from '../../utils/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const TextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 20px;
  color: ${theme.text};
  font-size: 15px;
  border-radius: 5px;
  min-height: 150px;
  resize: none;
`;

export const FaSpinnerAnimated = styled(FaSpinner)`
  color: ${({ textcolor }) => textcolor};
  animation: ${rotate} 2s linear infinite;
`;

export const ItemStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(30px, auto) 1fr auto;
  gap: 5px;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }

  p {
    overflow: hidden;
    color: ${theme.text};
  }
  strong {
    color: ${theme.text};
    font-size: 12px;
  }
`;
