import styled from 'styled-components';
import { theme } from '../../../../utils';

export const BoxStyled = styled.span`
  width: 100%;
  border: 1px solid ${theme.lightgrey};
  background-color: ${theme.primary};
  padding: 0.5rem 1rem;
  margin-top: 1.5rem;
  text-align: center;
  border-radius: 5px;
  cursor: grab;
  color: ${theme.white};
  font-weight: bold;

  &:active {
    cursor: grabbing;
  }
`;
