import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { history } from '../../services';

import { CustomButtonContainer, FaSpinnerAnimated } from './styles';

const CustomButton = ({ children, to, loading, inverted, ...props }) => {
  const customButton = useRef(null);
  if (to) {
    props.onClick = () => {
      history.push(to);
    };
  }
  return (
    <CustomButtonContainer ref={customButton} inverted={inverted} {...props}>
      {loading ? (
        <FaSpinnerAnimated className={inverted ? 'inverted' : ''} size={14} />
      ) : (
        children
      )}
    </CustomButtonContainer>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isSignIn: PropTypes.bool,
  isTransparent: PropTypes.bool,
  inverted: PropTypes.bool,
  to: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  small: PropTypes.bool,
};

CustomButton.defaultProps = {
  isSignIn: false,
  small: false,
  isTransparent: false,
  inverted: false,
  loading: false,
  to: '',
  type: 'button',
};

export default CustomButton;
