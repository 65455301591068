import styled from 'styled-components';
import { theme } from '../../../../utils';

export const ModalContainer = styled.div`
  padding: 5px 10px;
  ul {
    overflow-y: scroll;
    max-height: 70vh;
  }

  form {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
  button {
    margin: 0 5px;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template:
    'a b' 1fr
    'a b' 1fr
    'description description' 1fr
    / 1fr 1fr;
  & > .description {
    grid-area: description;
  }
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-template-rows: repeat(2, 1fr); */

  justify-content: center;
  grid-gap: 20px 40px;
  margin: 40px 0;

  label {
    font-size: smaller;
    font-weight: 100;
  }
`;

export const ItemsSelected = styled.div`
  display: flex;
  flex-wrap: wrap;
  button {
    cursor: pointer;
    padding: 2px 5px;
    background: ${theme.white};
    opacity: 0.85;
    border-radius: 5px;
    color: ${theme.primary};
    border: 1px solid ${theme.primary};
    margin: 5px 5px 0 0;
  }
`;
