import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Item, List, ListHeader } from './styles';
import { InfoItem, ListScroll, RateIcon } from '..';
import {
  formatNumber,
  formatPercent,
  IdToNickname,
  useScroll,
} from '../../utils';
import { api } from '../../services';
import Hover from '../Hover';

const orderOptions = {
  paid: 'Pago',
  cancelled: 'Cancelado',
  mediations: 'Reclamação',
};

const colors = {
  a: '#66c2a5',
  b: '#fc8d62',
  c: '#8da0cb',
};
const ListDashboard = ({ params, loading, curveAbcData, items_id }) => {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  const [page, setPage] = useState(1);

  async function getData() {
    const slice = items_id.id > 'a' ? 1 : 0;
    const ids =
      items_id?.data
        ?.slice(slice)
        ?.reduce((acc, { id }) => `${acc},${id}`, '') ?? '';

    setPage(1);
    const { docs, ...rest } = await api.get('chart_data/curve_abc/data', {
      params: { ...params, ids },
    });
    setData(docs);
    setInfo(rest);
  }
  useEffect(() => {
    getData();
  }, [params, items_id]);

  const idToNickname = IdToNickname();

  const shouldBeUpdated = () =>
    info.hasNextPage && !loading && page !== info.nextPage && data?.length;

  const handleOnYReachEnd = async () => {
    if (shouldBeUpdated()) {
      setPage(info.nextPage);

      const slice = items_id.id > 'a' ? 1 : 0;
      const ids =
        items_id?.data
          ?.slice(slice)
          ?.reduce((acc, { id }) => `${acc},${id}`, '') ?? '';

      const { docs, ...rest } = await api.get('chart_data/curve_abc/data', {
        params: { ...params, ids, page: info.nextPage },
      });
      setData(d => [...d, ...docs]);
      setInfo(rest);
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  const mapItemIdAndCategory = createMapItemIdAndCategory(curveAbcData);
  return (
    data.length > 0 && (
      <Container>
        <ListScroll
          header={
            <ListHeader>
              <section>
                <p />
                <p style={{ color: items_id.color }}>{items_id?.id}</p>
                <p>% de participação</p>
                <p>Unidades vendidas</p>
                <p>Ticket médio</p>
                <p>Faturamento</p>
              </section>
            </ListHeader>
          }
          loading={loading}
        >
          {data?.map(sale => (
            <List key={sale._id}>
              <Item>
                <div
                  style={{
                    textTransform: 'uppercase',
                    color: colors[mapItemIdAndCategory[sale.id]],
                  }}
                >
                  <h4>{mapItemIdAndCategory[sale.id]}</h4>
                </div>
                <div>
                  <img
                    src={sale.secure_thumbnail?.replace(/(http)(?=:)/, '$1s')}
                    alt=""
                  />
                </div>
                <InfoItem
                  item={sale}
                  buttonsText={[
                    idToNickname[sale?.seller_id],
                    orderOptions[params?.status] ?? '',
                  ]}
                />
                <div>
                  <p>
                    <strong>% de participação</strong>
                    {formatPercent(sale.percent)}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Unidades vendidas</strong>
                    {sale.quantity}
                  </p>
                </div>

                {renderCol(sale, 'Ticket médio', 'unit_price_mean')}
                {renderCol(sale, 'Faturamento', 'total_amount')}
              </Item>
            </List>
          ))}
        </ListScroll>
      </Container>
    )
  );
};

ListDashboard.propTypes = {
  params: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  items_id: PropTypes.objectOf(PropTypes.any),
  curveAbcData: PropTypes.arrayOf(PropTypes.any),
};

ListDashboard.defaultProps = {
  loading: false,
  params: {},
  items_id: [],
  curveAbcData: [],
};

export default ListDashboard;
function createMapItemIdAndCategory(curveAbcData) {
  return (
    curveAbcData?.data?.reduce(
      (prevs, { data: dataABC, id }) =>
        dataABC?.reduce((prev, curr) => ({ [curr.id]: id, ...prev }), prevs),
      {}
    ) ?? {}
  );
}

function renderCol(sale, title, property, prefix = 'R$') {
  return (
    <div>
      <p>
        <strong>{title}</strong>
        {prefix} {formatNumber(sale[property])}
      </p>
      <Hover
        trigger={
          <spam>
            <RateIcon rate={sale.details[property]?.rate} size={12} />
          </spam>
        }
      >
        <small>{sale.details.description}</small>
        <p>
          {prefix} {formatNumber(sale.details[property]?.value)}
        </p>
      </Hover>
    </div>
  );
}
