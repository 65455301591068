import styled, { css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  background: ${theme.background};
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.primary};
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  h3 {
    color: ${theme.white};
    font-size: 16px;
  }
  small {
    font-weight: 100;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  span {
    color: ${theme.primary};
    font-weight: lighter;
  }
  p {
    padding: 5px;
    color: ${theme.text};
    font-weight: lighter;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-weight: lighter;
  }

  div:nth-child(2) {
    width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  svg {
    margin: 15px;
    color: ${theme.primary};
    transition: transform 0.4s;
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
`;

export const QuestionStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    color: ${theme.lightgrey};
    margin: 0 1px 0 6px;
  }

  p {
    font-weight: bold;
  }
  .time {
    color: ${theme.lightgrey};
    font-size: 10px;
  }
`;

export const UserStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${theme.lightgrey};
  }

  p {
    color: ${theme.primary};
    padding: 5px;
    font-size: 12px;
  }

  .time {
    color: ${theme.lightgrey};
    font-size: 10px;
  }
`;

export const MsgShip = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${theme.text};
  font-size: 15px;
  width: 100%;
  button {
    margin-right: 10px;
    max-width: 150px;
    min-width: 80px;
  }
`;

export const HasSales = styled.span`
  display: flex;
  color: ${theme.white};
  background: ${lighten(0.2, theme.primary)};
  font-size: 0.8em;
  /* font-weight: bold; */
  padding: 3px;
  border-radius: 5px;
`;

export const FastAsnwersList = styled(PerfectScrollbar)`
  h3 {
    color: ${lighten(0.6, theme.text)};
  }
  margin-left: 20px;
  position: sticky;
  top: 70px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  max-height: 90vh;
  width: 300px;
  height: auto;
  @media (max-width: ${theme.mobile}) {
    display: none;
  }
`;

export const DropDownList = styled.li`
  margin: 20px 0;
  border-bottom: 1px solid ${lighten(0.1, theme.lightgrey)};

  div:nth-child(2n) {
    justify-content: flex-end;

    svg {
      transform: rotatey(180deg);
    }
  }

  div {
    display: flex;
    align-items: center;

    svg {
      color: ${theme.lightgrey};
    }

    p {
      padding: 10px;
      color: ${theme.text};
    }

    .time {
      color: ${theme.lightgrey};
      font-size: 10px;
    }
  }
`;

export const SetupAnswers = styled.div``;
