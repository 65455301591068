import * as types from '../constants';

function makeUrl(params) {
  if (!params) return 'adverts/';

  if (params instanceof Object)
    return Object.keys(params)
      .reduce((prev, curr) => `${prev}${curr}=${params[curr]}&`, 'adverts?')
      .slice(0, -1);

  return `adverts/${params}`;
}

export function addToCopy(item) {
  return {
    type: types.ADD_TO_COPY,
    payload: item,
  };
}
export function removeToCopy(id) {
  return {
    type: types.REMOVE_TO_COPY,
    payload: id,
  };
}

export function editToCopy(items) {
  return {
    type: types.EDIT_TO_COPY,
    payload: items,
  };
}

export function requestToCopy(accounts_id) {
  const url = makeUrl('copy');
  return {
    type: types.REQUEST_TO_COPY,
    payload: { accounts_id, url },
  };
}

export function successToCopy() {
  return {
    type: types.SUCCESS_TO_COPY,
  };
}

export function failureToCopy() {
  return {
    type: types.FAILURE_TO_COPY,
  };
}

export function fetchItemByNickname(nickname, page = 1) {
  const url = makeUrl({ nickname, page });
  return {
    type: types.FETCH_ITEMS_REQUEST,
    payload: { url, page },
  };
}

export function fetchItemByKeywords(keywords, page = 1) {
  const url = makeUrl({ keywords, page });
  return {
    type: types.FETCH_ITEMS_REQUEST,
    payload: { url, page },
  };
}

export function fetchItemByUrl(link) {
  const id = link.match(/[A-Z]{3}(-?)\d{7,12}/g);

  const url = makeUrl(id && id[0].replace('-', ''));
  return {
    type: types.FETCH_ITEMS_REQUEST,
    payload: { url },
  };
}

export function fetchItemsSuccess(items, paging = {}) {
  if (!Array.isArray(items)) throw new Error(`Invalid data ${items}`);
  return {
    type: types.FETCH_ITEMS_SUCCESS,
    payload: { items, paging },
  };
}

export function fetchItemsFailure() {
  return {
    type: types.FETCH_ITEMS_FAILURE,
  };
}
