import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';

function updateIfRequired(setData, loadData) {
  setData([]);
  loadData();
}

export const LoadData = (url, params) => {
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadData = useMemo(
    () => async () => {
      try {
        setLoading(true);
        const { docs, ...info } = await api.get(url, { params });
        setData(d => [...d, ...docs]);
        setPagination(info);
      } catch (error) {
        toast.error(error.response?.data.error || error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setLoading(false);
      }
    },
    [params, url]
  );

  const decorador = callback => async formFields => {
    try {
      setLoading(true);
      await callback(formFields);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const submitStock = decorador(async formFields => {
    await api.post('stock', formFields);
    loadData();
    toast.success('Produto cadastrado', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  const importStockFromMl = decorador(async formFields => {
    const stocks = await api.post('stock?multi=true', formFields);
    if (stocks.length) updateIfRequired(setData, loadData);
    toast.success(
      ` Produtos cadastrados (${stocks.length}): ${stocks.reduce(
        (a, b) => b.items_id + a,
        ''
      )}`,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  });

  const updateStock = decorador(async formFields => {
    await api.put('stock', formFields);
    updateIfRequired(setData, loadData);
    toast.success('Produto atualizado ', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  const deleteStock = decorador(async id => {
    await api.delete('stock', { data: { stock_id: id } });
    updateIfRequired(setData, loadData);
    toast.success('Produto deletado', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  const submitInternalOrder = decorador(async formFields => {
    await api.post('internal-order', formFields);
    updateIfRequired(setData, loadData);
    toast.success('Ordem criada', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  const mergeStock = decorador(async formFields => {
    await api.put('stock/merge', formFields);
    updateIfRequired(setData, loadData);
    toast.success('Produtos unificados ', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [
    data,
    setData,
    pagination,
    loading,
    submitStock,
    importStockFromMl,
    updateStock,
    deleteStock,
    submitInternalOrder,
    mergeStock,
  ];
};
