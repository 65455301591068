import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

const Hover = ({ trigger, children }) => {
  return (
    <Popup position="top center" on="hover" trigger={trigger}>
      {children}
    </Popup>
  );
};

Hover.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Hover;
