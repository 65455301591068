import { transparentize } from 'polished';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const ListData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid ${transparentize(0.8, theme.lightgrey)};

  a {
    text-decoration: none;
    color: inherit;
  }

  small {
    color: ${theme.lightgrey};
    font-size: 12px;
  }

  div {
    display: flex;

    strong {
      display: inline;
    }
    button {
      margin-right: 5px;

      &[disabled] {
        cursor: default;
        opacity: 0.7;
      }
    }
  }
`;
