import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  DateRangeWrapped,
  Input,
  Select,
  TextIcon,
  ListScroll,
  SummaryModalSales,
  CustomButton,
  NumberInfo,
} from '../../componets';
import { formatNumber, useScroll } from '../../utils';
import { LoadData } from './hooks';
import {
  Container,
  Content,
  List,
  ListContainer,
  Item,
  ListHeader,
  SummaryContainer,
  SummarySeller,
} from './styles';

const orderOptions = [
  {
    value: 'paid',
    label: 'Pago',
  },
  {
    value: 'cancelled',
    label: 'Cancelado',
  },
];

export default function SalesSummary() {
  const { account, profile } = useSelector(state => state.user);
  const [query, setQuery] = useState({
    ids: account?.id,
  });

  const idToNickname = profile.accounts.reduce(
    (prev, current) => ({ ...prev, [current.id]: current.nickname }),
    {}
  );

  const [data, setData, info, loading] = LoadData(
    'order/summary',
    query,
    account
  );

  const handleChangeQuery = fieldValue => {
    setData([]);
    setQuery({
      ...query,
      ...fieldValue,
      page: 1,
    });
  };

  const options = useMemo(
    () =>
      profile?.accounts?.map(acc => ({
        value: acc.id,
        label: acc.nickname,
      })),
    [profile]
  );

  const handleChangeStatus = ({ value: status }) => {
    handleChangeQuery({ status });
  };

  const handleChangeAccount = value => {
    let ids = account?.id;
    if (value?.length)
      ids = value?.reduce((acc, { value: id }) => `${acc},${id}`, '') ?? '';
    handleChangeQuery({ ids });
  };

  const handleChangeDate = event => {
    const { value } = event.target;
    handleChangeQuery(value);
  };

  const handleChange = event => {
    const { value, name } = event.target;
    handleChangeQuery({ [name]: value });
  };

  const shouldBeUpdated = () =>
    info.hasNextPage &&
    !loading &&
    query.page !== info.nextPage &&
    data?.sales?.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });
  return (
    <>
      <Container>
        <Content>
          <div>
            <h4>Resumo</h4>
            <Select
              primary
              closeMenuOnSelect={false}
              placeholder="Conta"
              isMulti
              onChange={handleChangeAccount}
              options={options}
              allowSelectAll
            />
            <Select
              primary
              name="status"
              onChange={handleChangeStatus}
              isLoading={loading}
              options={orderOptions}
              placeholder="Status"
            />
            <DateRangeWrapped
              primary
              name="date"
              placeholder="Selecione uma data"
              onChange={handleChangeDate}
              months={1}
            />
          </div>
        </Content>
        <SummaryContainer>
          <SummarySeller>
            <section>
              <TextIcon icon="shoppingBagICon" text="Unidades vendidas" />
              <h4>{data.total?.quantity?.value || 0}</h4>
              <NumberInfo {...(data?.total?.quantity ?? {})} />
            </section>
            <section>
              <TextIcon icon="dollarTagIcon" text="Faturamento" />
              <h4>R$ {formatNumber(data.total?.total_amount?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.total_amount ?? {})} />
            </section>
            <section>
              <TextIcon icon="invoiceIcon" text="Ticket médio" />
              <h4>R$ {formatNumber(data.total?.mean?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.mean ?? {})} />
            </section>
            <section>
              <TextIcon icon="shoppingBagICon" text="Taxa Mercado Livre" />
              <h4>R$ {formatNumber(data.total?.sale_fee?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.sale_fee ?? {})} />
            </section>
            <section>
              <TextIcon icon="invoiceIcon" text="Taxa Mercado Envios" />
              <h4>R$ {formatNumber(data.total?.cost_shipping?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.cost_shipping ?? {})} />
            </section>
            <section>
              <TextIcon icon="shoppingBagICon" text="Custos" />
              <h4>R$ {formatNumber(data.total?.custom_cost?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.custom_cost ?? {})} />
            </section>
            <section>
              <TextIcon icon="shoppingBagICon" text="Valor peças" />
              <h4>R$ {formatNumber(data.total?.cost_price?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.cost_price ?? {})} />
            </section>
            <section>
              <TextIcon icon="dollarTagIcon" text="Lucro" />
              <h4>R$ {formatNumber(data.total?.profit?.value || 0)}</h4>
              <NumberInfo {...(data?.total?.quantity ?? {})} />
            </section>
          </SummarySeller>
        </SummaryContainer>

        <ListContainer>
          <ListScroll
            header={
              <ListHeader>
                <div>
                  <label htmlFor="text">Burcar em minhas vendas</label>
                  <Input
                    name="text"
                    onChange={handleChange}
                    value={query.text}
                    icon="searchIcon"
                    loading={loading}
                    minLength={0}
                    debounceTimeout={800}
                    placeholder="Publicação/Comprador..."
                  />
                </div>
                <section>
                  <p />
                  <p />
                  <p>Unidades vendidas</p>
                  <p>Valor médio de venda</p>
                  <p>Faturamento</p>
                  <p>Lucro</p>
                  <p />
                </section>
              </ListHeader>
            }
            loading={loading}
          >
            {data?.sales.map(sale => (
              <List key={sale.item_id}>
                <Item>
                  <img
                    src={sale.secure_thumbnail?.replace(/(http)(?=:)/, '$1s')}
                    alt=""
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={sale.permalink}
                  >
                    <p>{sale.title}</p>
                    <CustomButton small inverted readonly>
                      {idToNickname[sale?.seller_id]}
                    </CustomButton>
                  </a>

                  <p>
                    <strong>Unidades vendidas</strong>
                    {sale.quantity}
                  </p>
                  <p>
                    <strong>Valor médio de venda</strong>
                    R$ {formatNumber(sale.unit_price_mean)}
                  </p>
                  <p>
                    <strong>Faturamento</strong>
                    R$ {formatNumber(sale.total_amount)}
                  </p>
                  <p>
                    <strong>Lucro</strong>
                    R$ {formatNumber(sale.profit)}
                  </p>
                  <p>
                    <SummaryModalSales sale={sale} title="detalhes">
                      Detalhes
                    </SummaryModalSales>
                  </p>
                </Item>
              </List>
            ))}
          </ListScroll>
        </ListContainer>
      </Container>
    </>
  );
}
