import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';

const init_state = { total: {}, sales: [] };

export const LoadData = (url, params, account) => {
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(init_state);

  const reset_state = () => {
    setData(init_state);
  };

  const loadData = useMemo(
    () => async () => {
      try {
        setLoading(true);
        const {
          sales: { docs, ...info },
          total,
        } = await api.get(url, { params });
        setData(d => ({
          total,
          sales: [...d.sales, ...docs],
        }));
        setPagination(info);
      } catch (error) {
        toast.error(error.response?.data.error || error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setLoading(false);
      }
    },
    [params, url]
  );

  useEffect(() => {
    loadData();
  }, [loadData, account]);

  return [data, reset_state, pagination, loading];
};
