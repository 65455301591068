/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { TextIconContainer } from './styles';
import { IconWrapper } from '..';
import { theme } from '../../utils';

export default function TextIcon({ icon, text }) {
  return (
    <TextIconContainer>
      <span>
        <IconWrapper fill={theme.primary} size={15} name={icon} />
      </span>
      <small>{text}</small>
    </TextIconContainer>
  );
}

TextIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node]).isRequired,
  text: PropTypes.string,
};

TextIcon.defaultProps = {
  text: '',
};
