import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { helperIcon } from '../../utils';
import { TextTooltip } from './styles';

const IconWrapper = ({ name, size, textTooltip, position, ...props }) => {
  const Icon = helperIcon(name);
  return (
    <>
      {!textTooltip ? (
        <Icon {...props} height={size} width={size} />
      ) : (
        <Popup
          trigger={() => <Icon {...props} height={size} width={size} />}
          on={['hover', 'focus']}
          position={position}
        >
          <TextTooltip>{textTooltip}</TextTooltip>
        </Popup>
      )}
    </>
  );
};

IconWrapper.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  textTooltip: PropTypes.string,
  position: PropTypes.string,
};

IconWrapper.defaultProps = {
  name: '',
  size: 25,
  textTooltip: '',
  position: 'left bottom',
};

export default IconWrapper;
