import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import notification from './notification/reducer';
import { adverts, copy } from './adverts/reducer';

export default combineReducers({
  auth,
  user,
  notification,
  adverts,
  copy,
});
