import styled from 'styled-components';
import { theme } from '../../utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 630px;
  margin-top: 10px;
  @media (max-width: ${theme.mobile}) {
    flex-wrap: wrap;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  @media (max-width: ${theme.mobile}) {
    flex-wrap: wrap;
  }
`;
