import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  background: ${theme.background};
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Summary = styled.div`
  width: 100%;

  ul {
    width: 100%;
    li {
      box-shadow: 0 0 5px 3px ${theme.lightgrey};
      background: ${theme.white};
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      margin: 20px 0;
      border-bottom: 1px solid ${lighten(0.1, theme.lightgrey)};
      @media (max-width: ${theme.mobile}) {
        grid-template-columns: 1fr 1fr;
      }

      div:first-child {
        color: ${theme.text};
        padding: 5px 0;
        h3 {
          margin-bottom: 15px;
          font-weight: 600;
        }
      }
      div:nth-child(1n + 2) {
        align-self: center;
        justify-self: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 70px;
        font-weight: lighter;
        color: ${theme.primary};

        svg {
          margin-right: 5px;
        }
        p {
          font-size: 14px;
          color: ${lighten(0.1, theme.text)};
        }
      }
    }
  }
`;

export const ContainerAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* padding: 20px; */
  margin: 20px 0 5px;
`;
