import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Input,
  CustomButton,
  Select,
  IconWrapper,
} from '../../../../componets';
import { ModalContainer, ButtonContainer, InputsContainer } from './styles';
import { STOCK } from '../../../../enum';

const stockOptions = Object.entries(STOCK).map(([key, value]) => ({
  label: value,
  value: key,
}));

const ModalStock = ({ onSubmit, stock_id }) => {
  const [formFields, setFormFields] = useState({
    stock_id,
  });

  const [open, setOpen] = useState(false);

  const handleChangeType = ({ value }) => {
    setFormFields({ ...formFields, type: value });
  };

  const handleChangeQuantity = event => {
    const { value, name } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    return onSubmit(formFields).then(() => setOpen(false));
  };

  const form = () => (
    <form onSubmit={handleSubmit}>
      <InputsContainer>
        <div>
          <label htmlFor="type">Tipo</label>
          <Select
            name="type"
            onChange={handleChangeType}
            options={stockOptions}
            placeholder="Tipo"
          />
        </div>
        <div>
          <label htmlFor="quantity">Quantidade</label>
          <Input
            onChange={handleChangeQuantity}
            type="number"
            min="0"
            name="quantity"
            placeholder="Quantidade"
            value={formFields.quantity}
          />
        </div>
      </InputsContainer>
      <ButtonContainer>
        <CustomButton type="submit">Editar</CustomButton>
      </ButtonContainer>
    </form>
  );

  return (
    <>
      <IconWrapper
        name="settingsIcon"
        textTooltip="Adicionar estoque"
        onClick={() => setOpen(true)}
        position="top center"
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Adicionar estoque"
      >
        <ModalContainer>{form()}</ModalContainer>
      </Modal>
    </>
  );
};

ModalStock.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stock_id: PropTypes.string.isRequired,
};
export default ModalStock;
