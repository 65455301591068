import styled from 'styled-components';
import { theme } from '../../../../utils';

export const ModalContainer = styled.div`
  padding: 5px 10px;
  width: 80vw;
  max-width: 1400px;

  form {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 70vh;

    textarea {
      color: ${theme.text};
      padding: 10px 10px 10px 5px;
      width: 100%;
      min-height: 100px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
  button {
    margin: 0 5px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  grid-gap: 20px 40px;
  margin: 40px 0;

  label {
    font-size: smaller;
    font-weight: 600;
  }
`;
