import React from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import { theme } from '../../../../utils';

const Dustbin = ({ children, addInfoToField, id }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'BOX',
    drop: item => {
      addInfoToField(id, `${item.answer}`);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  let backgroundColor = theme.white;
  if (isActive) {
    backgroundColor = darken(0.1, theme.background);
  } else if (canDrop) {
    backgroundColor = theme.background;
  }
  return (
    <div ref={drop} style={{ width: '100%', padding: '1px', backgroundColor }}>
      {children}
    </div>
  );
};

Dustbin.propTypes = {
  children: PropTypes.element.isRequired,
  addInfoToField: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default Dustbin;
