import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme } from '../../utils';

export const MenuStyled = styled.nav`
  /* box-shadow: 0 0 5px 3px ${theme.lightgrey}; */
  ul {
    background: ${theme.white};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    @media (max-width: ${theme.mobile}) {
      font-size: 12px;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
`;
export const MenuItemStyled = styled(NavLink)`
  margin-left: 20px;
  min-height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  color: ${theme.text};
  text-decoration: none;
  opacity: 0.9;
  &:hover {
    cursor: pointer;
  }
  &.active {
    opacity: 1;
    font-weight: bold;
    border-bottom: 3px solid ${theme.primary};
    cursor: default;
  }
  @media (max-width: ${theme.mobile}) {
    font-size: 12px;
    margin-left: 0;
  }
`;
