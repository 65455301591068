import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { formatMoney, formatPercent } from '../../utils';

export const LoadData = (url, params) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await api.get(url, { params });
      setData(response);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.seller_ids || params?.id) loadData();
  }, [params]);

  return [data, loading];
};

export const fabricChartData = chartData => {
  const { amounts, sales, mean, reference, tooltip } = chartData ?? {};

  const data = [
    {
      ...amounts,
      icon: 'dollarTagIcon',
      description: 'Faturamento',
      formatMoney: true,
      value: formatMoney(amounts?.value),
      details: {
        value: formatMoney(amounts?.details?.value),
        rate: amounts?.details?.rate,
        description: amounts?.details?.description,
      },
    },
    {
      ...sales,
      icon: 'shoppingBagICon',
      description: 'Vendas',
      formatMoney: false,
      value: sales?.value,
      details: {
        value: sales?.details?.value,
        rate: sales?.details?.rate,
        description: sales?.details?.description,
      },
    },
    {
      ...mean,
      icon: 'invoiceIcon',
      description: 'Ticket médio',
      formatMoney: true,
      value: formatMoney(mean?.value),
      details: {
        value: formatMoney(mean?.details?.value),
        rate: mean?.details?.rate,
        description: mean?.details?.description,
      },
    },
  ];

  return { data, reference, tooltip };
};

export const fabricCardsData = cardsData => {
  const formatTextTooltip = text =>
    text ? `Nos últimos ${text}`.replace('days', 'dias') : '';

  return [
    {
      ...cardsData?.power_seller_status,
      icon: 'claimIcon',
      description: 'Reputação',
      value: cardsData?.power_seller_status?.value?.replace(/\D+/, ''),
      details: {
        tooltip: cardsData?.power_seller_status?.power_seller_status,
      },
    },
    {
      ...cardsData?.claims,
      icon: 'claimIcon',
      description: 'Reclamações',
      details: {
        value: formatPercent(cardsData?.claims?.rate),
        tooltip: formatTextTooltip(cardsData?.claims?.period),
      },
    },
    {
      ...cardsData?.delayed_handling_time,
      icon: 'deliveryIcon',
      description: 'Envios atrasados',
      details: {
        value: formatPercent(cardsData?.delayed_handling_time?.rate),
        tooltip: formatTextTooltip(cardsData?.delayed_handling_time?.period),
      },
    },
    {
      ...cardsData?.cancellations,
      icon: 'closeCalendarIcon',
      description: 'Cancelamentos',
      details: {
        value: formatPercent(cardsData?.cancellations?.rate),
        tooltip: formatTextTooltip(cardsData?.cancellations?.period),
      },
    },
    {
      ...cardsData?.sales,
      icon: 'mixerIcon',
      description: 'Operações',
      value: cardsData?.sales?.completed,
      details: {
        tooltip: formatTextTooltip(cardsData?.sales?.period),
      },
    },
    {
      ...cardsData?.mediations,
      icon: 'mixerIcon',
      description: 'Mediações',
      value: cardsData?.mediations?.value,
      details: {
        value: formatPercent(cardsData?.mediations?.rate),
        tooltip: cardsData?.mediations?.period,
      },
    },
  ];
};
