import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { theme } from '../../../../utils/colors';

export const Container = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  transition: width 0.3s ease-in-out;
  ${({ open }) => (open ? 'width:250px' : 'width: 56px;')};
  display: flex;
  @media (max-width: ${theme.mobile}) {
    position: absolute;
    ${({ open }) => (open ? 'width:250px' : 'width: 0px;')};
  }
`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 64px;
  height: auto;
  transform: ${({ open }) =>
    open ? 'translateX(0)' : 'translateX(calc(-100% + 56px))'};
  text-align: right;
  position: absolute;
  width: 250px;
  transition: transform 0.3s ease-in-out;
  background: ${theme.sidebar};
  @media (max-width: ${theme.mobile}) {
    transform: ${({ open }) =>
      open ? 'translateX(0)' : 'translateX(calc(-100%))'};
  }
  ul {
    li {
      a {
        display: flex;
        flex-direction: row-reverse;
        flex-direction: ${({ open }) => open && 'row;'};
        align-items: center;
        font-size: 0px;
        padding: 20px 12px;
        font-weight: 600;
        white-space: nowrap;
        color: ${theme.white};
        text-decoration: none;
        transition: font-size 0.4s ease-in, color 0.2s linear;
        ${({ open }) =>
          open &&
          css`
            font-size: 15px;
          `};

        @media (max-width: ${theme.mobile}) {
          text-align: center;
        }

        &:hover {
          color: ${darken(0.3, theme.white)};
        }

        &.active {
          font-weight: bold;
          border-right: 3px solid ${theme.primary};
          box-shadow: inset 0 0 20px 20px ${darken(0.04, theme.sidebar)};
        }

        p {
          padding-left: 24px;
        }
      }
    }
  }
`;
