import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content } from './styles';
import { Header, SideNav, Menu } from '../../../componets';

const routes = {
  questions: [
    { path: '/questions/pendings', title: 'PENDENTES' },
    { path: '/questions/settings', title: 'CONFIGURAÇÃO' },
    { path: '/questions/answers', title: 'RESPOSTAS' },
    { path: '/questions/history', title: 'HISTÓRICO' },
  ],
  concurrent: [
    { path: '/concurrent', title: 'CONCORRENTES' },
    { path: '/concurrent/summary', title: 'RESUMO' },
  ],
  sales: [
    { path: '/sales', title: 'VENDAS' },
    { path: '/sales/summary', title: 'RESUMO' },
  ],
  message: [
    { path: '/message', title: 'MENSAGENS' },
    { path: '/message/chat', title: 'CHAT' },
  ],

  settings: [
    { path: '/settings', title: 'PERFIL' },
    { path: '/settings/operator', title: 'OPERADORES' },
  ],
  blacklist: [
    { path: '/blacklist', title: 'BLOQUEADOS' },
    { path: '/blacklist/list', title: 'LISTA' },
  ],
  adverts: [
    { path: '/adverts/copy', title: 'REPLICADOR' },
    { path: '/adverts/copy/completed', title: 'COPIADOS' },
  ],
};

export default function DefaultLayout({ children, ...res }) {
  const path = res.match.path.match(/\w+/) && res.match.path.match(/\w+/)[0];

  return (
    <Wrapper>
      <SideNav />
      <Content>
        <Header {...res} />
        {routes[path] && <Menu routes={routes[path]} />}
        {children}
      </Content>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
