// /* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ToolTipContainer } from './styles';
import { Hover, RateIcon } from '..';

const NumberInfo = ({ value, details }) => {
  function DetailsToolTip() {
    return (
      <ToolTipContainer>
        <div>
          <p>{typeof value === 'number' ? Number(value).toFixed(2) : value}</p>
          <RateIcon rate={details?.rate} />
        </div>
        <div>
          <small>{details?.value}</small>
        </div>
        <div>
          <small>{details?.description}</small>
        </div>
      </ToolTipContainer>
    );
  }

  return (
    <Hover
      trigger={
        <div>
          <RateIcon rate={details?.rate} justify="start" />
        </div>
      }
    >
      <DetailsToolTip value={value} details={details} />
    </Hover>
  );
};

NumberInfo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

NumberInfo.defaultProps = {
  value: 0,
  details: {},
};

export default NumberInfo;
