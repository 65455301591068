import React from 'react';
import PropTypes from 'prop-types';
import { ContainerInput, ContainerSearch, ContainerResult } from './styles';
import { Input, Rating, TextIcon } from '../index';

const SearchUser = ({ children, loading, handleSearch, title, user }) => {
  const handleChange = event => {
    const { value } = event.target;
    handleSearch({ nickname: value });
  };

  return (
    <ContainerSearch>
      <ContainerInput>
        <h4>{title}</h4>
        <Input
          primary
          name="nickname"
          onChange={handleChange}
          icon="searchIcon"
          placeholder="Apelido"
          minLength={0}
          debounceTimeout={800}
          loading={loading}
        />
      </ContainerInput>
      {user && (
        <ContainerResult>
          <div id="user">
            <h4>{user.nickname}</h4>
            <Rating
              rate={parseInt(
                user.seller_reputation?.level_id?.match(/\d|[a-z]+/g)[0],
                10
              )}
              color={user.seller_reputation?.level_id?.match(/\d|[a-z]+/g)[1]}
              label="Reputação do vendedor"
              text={user.seller_reputation?.power_seller_status}
            />
          </div>
          <div id="info">
            <section>
              <TextIcon icon="mixerIcon" text="Operações" />
              <h4>{user?.seller_reputation?.transactions?.total}</h4>
            </section>
            <section>
              <TextIcon icon="mixerIcon" text="Publicações" />
              <h4>{user?.publications}</h4>
            </section>
            <section>
              <TextIcon icon="mixerIcon" text="Localização" />
              <h4>{`${user?.address?.city}-${user?.address?.state}`}</h4>
            </section>
            <section>
              <TextIcon icon="mixerIcon" text="Membro desde" />
              <h4>
                {user?.registration_date
                  ?.split('T')[0]
                  .split('-')
                  .reverse()
                  .join('/')}
              </h4>
            </section>
          </div>
          {children}
        </ContainerResult>
      )}
    </ContainerSearch>
  );
};

SearchUser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.object]),
  handleSearch: PropTypes.oneOfType([PropTypes.func]).isRequired,
};

SearchUser.defaultProps = {
  loading: false,
  title: '',
  children: '',
  user: null,
};

export default SearchUser;
