import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const TextIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr;
  grid-gap: 5px;
  margin-bottom: 10px;
  color: ${theme.primary};
`;
