import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 5px;

  & > div {
    display: grid;
    grid-template-columns: repeat(${({ num_elements }) => num_elements}, 1fr);
    grid-gap: 10px;
    align-items: center;

    h4 {
      font-weight: 600;
      color: ${theme.white};
    }

    @media (max-width: ${theme.mobile}) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`;
