import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { lighten } from 'polished';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { theme } from '../../utils/colors';

const animatedComponents = makeAnimated();

const primaryStyles = {
  control: provided => ({
    ...provided,
    background: lighten(0.1, theme.primary),
  }),
  loadingIndicator: provided => ({
    ...provided,
    color: theme.white,
  }),
  singleValue: provided => ({
    ...provided,
    color: theme.white,
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.white,
  }),
};

const SelectWrapper = ({
  primary,
  options,
  allOption,
  allowSelectAll,
  defaultValue,
  ...props
}) => {
  const [optionsState, setOptionsState] = useState(defaultValue);
  return (
    <>
      {allowSelectAll ? (
        <Select
          noOptionsMessage={() => ''}
          styles={primary && primaryStyles}
          components={animatedComponents}
          {...props}
          options={[...options, allOption]}
          value={optionsState}
          onChange={selected => {
            if (
              selected !== null &&
              selected.length > 0 &&
              selected[selected.length - 1].value === allOption.value
            ) {
              setOptionsState(options);
              return props.onChange(options);
            }
            setOptionsState(selected);
            return props.onChange(selected);
          }}
        />
      ) : (
        <Select
          noOptionsMessage={() => ''}
          styles={primary && primaryStyles}
          components={animatedComponents}
          {...props}
          options={options}
        />
      )}
    </>
  );
};

SelectWrapper.propTypes = {
  primary: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  defaultValue: PropTypes.arrayOf(PropTypes.any),
  allowSelectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

SelectWrapper.defaultProps = {
  options: [],
  defaultValue: [],
  primary: false,
  allowSelectAll: false,
  allOption: {
    label: 'Todas',
    value: '*',
  },
};

export default SelectWrapper;
