import { lighten } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { theme } from '../utils';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '@webscopeio/react-textarea-autocomplete/style.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'react-chat-elements/dist/main.css';

import 'reactjs-popup/dist/index.css';

export default createGlobalStyle`


  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }


  body, input, button {
    font-family: 'Open Sans', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

 
  .dropdownAutoComplete {

    @media (max-width: ${theme.mobile}) {
    left: 0 !important;
  }
  }

  .rta__entity--selected {
    background: ${lighten(0.2, theme.primary)};
  }

  
  .rce-dropdown {
    padding: 0;
  }

  .rce-container-citem {
    max-width: 300px;
  }
`;
