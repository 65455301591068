import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ background }) => background};
  border-radius: 5px;
  padding: 2px;
  color: ${({ color, background }) =>
    background === theme.card ? color : theme.card};
`;

export const DropDownList = styled.div`
  align-items: center;
  width: 100%;

  & > button {
    display: flex;
    align-items: center;
    background: none;
    border: 0;

    h3 {
      font-weight: 100;
      color: ${lighten(0.15, theme.text)};
    }

    svg {
      font-weight: 100;
      margin-right: 5px;
      color: ${lighten(0.15, theme.text)};
      transition: transform 0.2s linear;
      ${({ open }) =>
        open &&
        css`
          transform: rotate(90deg);
        `}
    }
  }

  & > ul {
    margin-top: 20px;
    overflow: auto;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease-in-out 0.6s, opacity 0.6s ease-in-out,
      max-height 0.6s ease-in-out;
    ${({ open }) =>
      open &&
      css`
        visibility: visible;
        opacity: 1;
        max-height: 630px;

        transition-delay: 0s;
      `}
  }
`;
