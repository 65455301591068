import React, { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount, updateAccount } from '../../store/modules/user/actions';
import { notificationQuestionsUpdate } from '../../store/modules/notification/actions';
import { api, history, socket } from '../../services';
import { useOnClickOutside } from '../../utils';
import { TitleComponent, IconWrapper } from '..';
import {
  Container,
  Badge,
  NotificationList,
  Scroll,
  Notification,
} from './styles';

const Notifications = () => {
  const dispatch = useDispatch();
  const { questions } = useSelector(state => state.notification);
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const node = useRef();
  useOnClickOutside(node, () => setVisible(false));

  const hasUnread = useMemo(() => notifications.length, [notifications]);

  useEffect(() => {
    async function loadNotifications() {
      const { questionsUnanswered, tokensExpired } = await api.get(
        '/notifications'
      );

      tokensExpired.forEach(accountNotification => {
        dispatch(updateAccount(accountNotification));
      });

      const questionsUnansweredFormated = questionsUnanswered.map(
        questionsNotification => ({
          ...questionsNotification,
          title: 'Nova pergunta recebida !!',
          timeDistance: moment(questionsNotification.date_created).format(
            'LLL'
          ),
          topic: '/questions/pendings',
          type: 'black',
        })
      );

      const tokensExpiredFormated = tokensExpired.map(accountNotification => ({
        ...accountNotification,
        account_id: accountNotification._id,
        title: `Clique aqui para renovar o token de ${accountNotification.nickname}`,
        timeDistance: moment().format('LLL'),
        topic: '/accounts',
        type: 'error',
      }));
      setNotifications([
        ...tokensExpiredFormated,
        ...questionsUnansweredFormated,
      ]);
    }

    loadNotifications();
  }, [questions]);

  useEffect(() => {
    socket().on('questions', data => {
      dispatch(notificationQuestionsUpdate(data));
    });
  }, []);

  useEffect(() => {
    api.get('/update');

    setInterval(async () => {
      if (process.env.NODE_ENV !== 'development') api.get('/update');
    }, 2000 * 60);
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  function handleSeeMore(notification) {
    const { topic, account_id } = notification;
    // api.post('notifications', {
    //   topic,
    //   user_id: seller_id,
    //   resource: `/${topic}/${id}`,
    // });
    dispatch(selectAccount(account_id));

    history.push(`${topic}`);
  }
  return (
    <Container ref={node}>
      <TitleComponent hasUnread={hasUnread} />
      <Badge onClick={handleToggleVisible} hasUnread={hasUnread}>
        {!!hasUnread && (
          <div>
            <span>{hasUnread}</span>
          </div>
        )}
        <IconWrapper size={20} name="bellIcon" />
      </Badge>

      <NotificationList visible={visible}>
        <Scroll>
          {notifications.map(notification => (
            <Notification
              onClick={() => handleSeeMore(notification)}
              key={notification._id}
              unread={!notification.read}
              type={notification.type}
            >
              <p>{notification.title}</p>
              <p>{notification.timeDistance}</p>
              <p> {notification.text}</p>
            </Notification>
          ))}
        </Scroll>
      </NotificationList>
    </Container>
  );
};

export default Notifications;
