import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const ToolTipContainer = styled.div`
  small {
    color: ${lighten(0.2, theme.text)};
  }
  div {
    display: flex;
    justify-content: space-between;
  }
`;
