import { transparentize } from 'polished';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const List = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-top: 1px solid ${transparentize(0.6, theme.lightgrey)};

  a {
    color: ${theme.primary};
  }

  @media (max-width: ${theme.mobile}) {
    flex-direction: column;
  }
`;

export const ListData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  a {
    text-decoration: none;
    color: inherit;
  }

  small {
    color: ${theme.lightgrey};
    font-size: 12px;
  }

  div {
    display: flex;

    strong {
      display: inline;
    }
    button {
      margin-left: 5px;

      &[disabled] {
        cursor: default;
        opacity: 0.7;
      }
    }
  }
`;
export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  div {
    padding: 5px;
    color: ${theme.text};
    margin-top: 10px;

    img {
      width: 100px;
      height: 100px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: ${theme.mobile}) {
    flex-direction: column;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        display: inline;
      }
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
`;
