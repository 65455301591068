import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 15px 5px;

  h4 {
    font-weight: 600;
    color: ${theme.white};
  }
`;

export const FormContainer = styled.div`
  margin: 20px auto;
  width: 100%;
  background: ${theme.white};
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  border-radius: 5px;
  padding: 10px 20px;

  form {
    display: grid;
    grid-template: repeat(2, minmax(60px, 100px)) / 1fr 1fr;
    grid-gap: 10px;

    @media (max-width: ${theme.mobile}) {
      display: flex;
      flex-direction: column;
    }

    section {
      grid-column: 2;
      justify-self: end;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5px;
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  grid-gap: 20px;
  label {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
    grid-column-gap: 5px;
  }
`;
