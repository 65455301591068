import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ModalStyled,
  StyledPopup,
  ButtonsContainer,
  InputsContainer,
} from './styles';
import { CustomButton, Input, HeaderModal } from '..';

const FormModal = ({ onClose, onSubmit, open, title, footer }) => {
  const [formFields, setFormFields] = useState({});

  const handleChange = event => {
    const { value, name } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    return onSubmit(formFields);
  };

  return (
    <StyledPopup open={open} onClose={onClose} modal>
      <ModalStyled>
        <HeaderModal title={title} onClose={onClose} />

        <form onSubmit={handleSubmit}>
          <InputsContainer>
            <div>
              <label htmlFor="quantity">Quantidade</label>
              <Input
                onChange={handleChange}
                type="number"
                min="1"
                value={formFields.quantity}
                name="quantity"
                placeholder="Quantidade"
              />
            </div>
            <div>
              <label htmlFor="zip_code">CEP</label>
              <Input
                onChange={handleChange}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                name="zip_code"
                placeholder="CEP"
                value={formFields.zip_code}
              />
            </div>
          </InputsContainer>
          <ButtonsContainer>
            <CustomButton inverted onClick={onClose} type="button">
              Cancelar
            </CustomButton>
            <CustomButton type="submit">Buscar</CustomButton>
          </ButtonsContainer>
        </form>
        {footer && footer()}
      </ModalStyled>
    </StyledPopup>
  );
};

FormModal.propTypes = {
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
};

FormModal.defaultProps = {
  footer: null,
  title: 'Inserir custo do Mercado Envios',
};

export default FormModal;
