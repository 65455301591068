import styled from 'styled-components';
import { theme } from '../../../../utils';

export const ModalContainer = styled.div`
  padding: 5px 10px;
  width: 100%;
  ul {
    overflow-y: scroll;
    max-height: 70vh;
  }

  form {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
  button {
    margin: 0 5px;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 500px;

  justify-content: center;
  grid-gap: 20px 40px;
  margin: 40px 0;

  label {
    font-size: smaller;
    font-weight: 100;
  }
  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;
