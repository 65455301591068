import styled from 'styled-components';
import Popup from 'reactjs-popup';

export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 5px;
    width: auto;
    padding: 0px;
  }
`;

export const ModalStyled = styled.div`
  form {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 10px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 250px));
  justify-content: center;
  grid-gap: 20px 40px;
  margin: 40px 0;

  label {
    font-size: smaller;
    font-weight: 100;
  }
`;
