import React, { useState } from 'react';
import {
  Input,
  ListScroll,
  CustomButton,
  ButtonModalYN,
} from '../../componets';
import { formatMoney, useScroll } from '../../utils';
import { Container, Content, ListHeader, Product, Table } from './styles';
import { LoadData } from './hooks';
import {
  ModalRegister,
  ModalUpdate,
  ModalStock,
  SelectStock,
  ModalImportMl,
} from './componentes';

export default function Stock() {
  const [query, setQuery] = useState({});

  const [
    data,
    setData,
    info,
    loading,
    submitStock,
    importStockFromMl,
    updateStock,
    deleteStock,
    submitInternalOrder,
    mergeStock,
  ] = LoadData('stock', query);

  const handleChangeQuery = fieldValue => {
    setData([]);
    setQuery({
      ...query,
      ...fieldValue,
      page: 1,
    });
  };

  const handleChange = event => {
    const { value, name } = event.target;
    handleChangeQuery({ [name]: value });
  };

  const shouldBeUpdated = () =>
    info.hasNextPage && !loading && query.page !== info.nextPage && data.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  const { merge, checkbox, isMerging } = SelectStock({
    mergeStock,
    deleteStock,
  });
  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <>
      <Container>
        <Content>
          <h4>{info.totalDocs} Produtos cadastrados</h4>
          <div />
        </Content>
        <ListScroll
          header={
            <ListHeader>
              <div>
                <label htmlFor="text">Buscar em minhas vendas</label>
                <Input
                  name="text"
                  onChange={handleChange}
                  value={query.text}
                  icon="searchIcon"
                  loading={loading}
                  minLength={0}
                  debounceTimeout={800}
                  placeholder="Publicação"
                />
              </div>
              {merge()}
              {!isMerging && (
                <>
                  <ModalImportMl onSubmit={importStockFromMl} />

                  <ModalRegister onSubmit={submitStock} />
                </>
              )}
            </ListHeader>
          }
          loading={loading}
        >
          <Table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Nome</th>
                <th>Preço de custo</th>
                <th>Custo extra</th>
                <th>Estoque</th>
              </tr>
            </thead>
            <tbody>
              {data.map(stock => {
                const ids = stock.items_id;
                return (
                  <tr key={stock._id}>
                    <td>{renderImgAndDetails(stock, ids)}</td>
                    <td>
                      <strong>Nome</strong>
                      <label title={stock.name}>
                        <p>{stock.name}</p>
                      </label>
                    </td>
                    <td>
                      <strong>Preço de custo</strong>
                      {formatMoney(stock.cost_price)}
                    </td>
                    <td>
                      <strong>Custo extra</strong>
                      {formatMoney(stock.custom_cost)}
                    </td>
                    <td className="stock">
                      <strong>Estoque</strong>
                      <ModalStock
                        onSubmit={submitInternalOrder}
                        stock_id={stock._id}
                      />
                      <span>{stock.quantity}</span>
                    </td>
                    <td className="action">
                      {isMerging ? (
                        checkbox(stock)
                      ) : (
                        <>
                          <ModalUpdate
                            onSubmit={updateStock}
                            fieldsStock={{
                              name: stock.name,
                              items_id: stock.items_id,
                              cost_price: stock.cost_price,
                              custom_cost: stock.custom_cost,
                              initial_quantity: stock.initial_quantity,
                              stock_id: stock._id,
                              description: stock.description,
                            }}
                          />
                          <CustomButton
                            inverted
                            to={`/stock/${stock._id}/orders`}
                          >
                            Logs
                          </CustomButton>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ListScroll>
      </Container>
    </>
  );
}
function renderImgAndDetails(stock, ids) {
  return (
    <Product>
      <img alt={stock.name} src={stock.items[0]?.secure_thumbnail} />
      <div>
        <small>
          <strong>{stock.items[0]?.title}</strong>
        </small>
        <small>{ids?.slice(0, 3)?.join(', ')}</small>

        <ButtonModalYN
          title="Itens"
          body={
            <small
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '10px',
              }}
            >
              {stock.items.map(item => (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.permalink}
                  >
                    {item.id}
                  </a>
                </p>
              ))}
            </small>
          }
          small
          button={false}
        >
          {`detalhes ${stock?.items?.length}`}
        </ButtonModalYN>
      </div>
    </Product>
  );
}
