import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Card } from './styles';
import { theme } from '../../utils/colors';
import { CustomButton, ModalYN } from '../../componets';
import {
  addAccountRequest,
  selectAccount,
  deleteAccountRequest,
} from '../../store/modules/user/actions';
import api from '../../services/api';

export default function Account({ location }) {
  const dispatch = useDispatch();
  const { profile, loading, account } = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    if (code) {
      dispatch(addAccountRequest(code));
    }
  }, []);

  const handleAddAccount = async () => {
    try {
      const { authURL } = await api.post('account');
      if (authURL) window.location.assign(authURL);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelectAccount = accountId => {
    dispatch(selectAccount(accountId));
  };

  const handleDeleteAccount = accountId => {
    dispatch(deleteAccountRequest(accountId));
  };

  const closeModal = id => {
    setOpenModal({ [id]: false });
  };

  return (
    <Container>
      <Card>
        {!profile.accounts.length ? (
          <>
            <h3>Bem-Vindo!</h3>
            <p>
              Agora só falta conectar a sua conta do Mercado Livre para começar
              a aproveitar todas as nossas ferramentas.
            </p>
            <CustomButton
              onClick={handleAddAccount}
              textColor={theme.text}
              color={theme.background}
              loading={loading}
            >
              Conectar conta do mercado livre
            </CustomButton>
          </>
        ) : (
          <>
            <h3>Contas associadas</h3>
            <ul>
              {profile.accounts.map(acc => (
                <li key={acc._id}>
                  <ModalYN
                    open={!!openModal[acc._id]}
                    onClose={() => closeModal(acc._id)}
                    onAccept={() => handleDeleteAccount(acc._id)}
                    title="Excluir conta"
                    body="Tem certeza que deseja excluir esta conta ?"
                  />
                  <p>{acc.email}</p>
                  <div>
                    <div>
                      <CustomButton
                        inverted
                        onClick={() => setOpenModal({ [acc._id]: true })}
                      >
                        Deletar
                      </CustomButton>
                    </div>
                    <div>
                      <CustomButton
                        disabled={account && account._id === acc._id}
                        inverted={account && account._id !== acc._id}
                        onClick={() => handleSelectAccount(acc._id)}
                      >
                        {account && account._id === acc._id
                          ? 'Selecionada'
                          : 'Selecionar'}
                      </CustomButton>
                    </div>

                    {acc.token_expired && (
                      <div>
                        <CustomButton
                          onClick={handleAddAccount}
                          color={theme.negative}
                        >
                          Token expirado
                        </CustomButton>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <CustomButton
              onClick={handleAddAccount}
              textColor={theme.text}
              color={theme.background}
              loading={loading}
            >
              Adicionar conta do mercado livre
            </CustomButton>
          </>
        )}
      </Card>
    </Container>
  );
}

Account.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
