import { call, put, all, select, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../constants';

import {
  fetchItemsFailure,
  fetchItemsSuccess,
  successToCopy,
  failureToCopy,
} from '../actions';
import { history, api } from '../../../../services';

export function* fetchItem({ payload }) {
  try {
    const response = yield call(api.get, payload.url);
    if (response?.docs)
      yield put(fetchItemsSuccess(response.docs, response.paging));
    else yield put(fetchItemsSuccess([response]));
  } catch (error) {
    toast.error('Erro ao buscar anúncios!');
    yield put(fetchItemsFailure());
  }
}

export function* copyItem({ payload }) {
  try {
    const items = yield select(state => state.copy.items);
    yield call(api.post, payload.url, {
      items,
      accounts_id: payload.accounts_id,
    });

    yield put(successToCopy());

    history.push('/adverts/copy/completed');
  } catch (error) {
    toast.error('Erro ao copiar anúncios!');
    yield put(failureToCopy());
  }
}

export default all([
  takeLeading(types.FETCH_ITEMS_REQUEST, fetchItem),
  takeLeading(types.REQUEST_TO_COPY, copyItem),
]);
