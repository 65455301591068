import { transparentize } from 'polished';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    color: ${theme.white};
    border-radius: 4px;
    padding: 4px;
    background-color: ${theme.primary};
    display: inline-block;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: ${transparentize(0.1, theme.primary)};
  }

  .inputfile + label {
    cursor: pointer;
  }

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;
