import styled from 'styled-components';
import { transparentize, darken } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  h4 {
    font-weight: 600;
    color: ${theme.white};
    margin: 10px 15px;
  }
  & > div {
    height: 50px;
    background: ${darken(0.04, theme.primary)};
    border-radius: 5px;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;

  thead {
    @media (max-width: ${theme.mobile}) {
      display: none;
    }
  }
  th {
    font-weight: 600;
  }
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr repeat(3, 1fr) 1fr;
    grid-column-gap: 5px;
    margin: 5px 0px;
    padding: 5px 0;
    align-items: center;

    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${transparentize(0.6, theme.lightgrey)};

    @media (max-width: ${theme.mobile}) {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }
  }
  & .action {
    display: grid;
    grid-row-gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${theme.mobile}) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  & .stock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    overflow: visible;
    svg {
      margin: 0 10px;
      transition: transform 0.4s;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
    @media (max-width: ${theme.mobile}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        display: inline;
      }
      span {
        width: 100%;
        text-align: right;
      }
    }
  }
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 300;

    strong {
      display: none;
    }
    @media (max-width: ${theme.mobile}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        display: inline;
      }
    }
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Product = styled.div`
  display: flex;
  small {
    display: block;
    font-weight: 100;
  }

  img {
    width: 50px;
    height: 50px;
  }
`;

export const ListContainer = styled.div``;

export const ListHeader = styled.div`
  label {
    color: ${theme.lightgrey};
    font-size: 10px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 20px;

  @media (max-width: ${theme.mobile}) {
    display: grid;

    button {
      width: auto;
      margin-top: 10px;
    }
  }
`;

export const List = styled.li`
  width: 100%;
  min-width: 50vw;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid ${transparentize(0.6, theme.lightgrey)};
`;
