import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Img = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const TextTooltip = styled.p`
  color: ${theme.primary};
`;
