import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, CustomButton } from '../../../../componets';
import {
  ModalContainer,
  ButtonContainer,
  InputsContainer,
  ItemsSelected,
} from './styles';

const ModalMerge = ({ onSubmit, stocks }) => {
  const [open, setOpen] = useState(false);
  const [formFields, setFormFields] = useState({
    name: '',
    cost_price: '0',
    initial_quantity: '0',
    custom_cost: '0',
    description: '',
  });

  const setDefault = () => {
    setOpen(false);
    setFormFields({
      name: '',
      cost_price: '0',
      initial_quantity: '0',
      custom_cost: '0',
      description: '',
    });
  };

  const handleChange = event => {
    const { value, name } = event.target;

    setFormFields({ ...formFields, [name]: value?.replace(/^0+(?=\d)/, '') });
  };
  const handleChangeValue = event => {
    const { value, name } = event.target;
    const b = value.replace(/[^0-9]/g, '').split('');
    b.splice(b.length - 2, 0, b.length > 2 ? '.' : '.0');

    const a = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(b.join(''));
    setFormFields({ ...formFields, [name]: a });
  };
  const removeMask = fields => {
    const help = s =>
      typeof s === 'string' ? s.replace(/[^0-9,]/g, '').replace(',', '.') : s;
    const cost_price = help(fields.cost_price);
    const custom_cost = help(fields.custom_cost);

    return { ...fields, cost_price, custom_cost, stocks: [] };
  };

  const handleSubmit = e => {
    e.preventDefault();

    return onSubmit(
      removeMask({ ...formFields, stock_ids: stocks?.map(({ _id }) => _id) })
    ).then(() => setDefault());
  };

  const form = () => (
    <form onSubmit={handleSubmit}>
      <InputsContainer>
        <div>
          <label htmlFor="name">Nome</label>
          <Input
            onChange={handleChange}
            type="text"
            required
            value={formFields.name}
            name="name"
            placeholder="Nome"
          />
        </div>
        <div>
          <label htmlFor="cost_price">Preço de custo</label>
          <Input
            onChange={handleChangeValue}
            name="cost_price"
            placeholder="Preço de custo"
            value={formFields.cost_price}
          />
        </div>
        <div>
          <label htmlFor="custom_cost">Custos extras</label>
          <Input
            onChange={handleChangeValue}
            value={formFields.custom_cost}
            name="custom_cost"
            placeholder="Custos extras"
          />
        </div>
        <div>
          <label htmlFor="initial_quantity">Quantidade inicial</label>
          <Input
            onChange={handleChange}
            type="number"
            min="0"
            name="initial_quantity"
            placeholder="Quantidade"
            value={formFields.initial_quantity}
          />
        </div>
        <div className="description">
          <label htmlFor="description">Descrição</label>
          <Input
            onChange={handleChange}
            type="text"
            name="description"
            placeholder="Descrição"
            value={formFields.description}
          />
        </div>
      </InputsContainer>
      <ButtonContainer>
        <CustomButton type="submit">Unificar</CustomButton>
      </ButtonContainer>
    </form>
  );

  return (
    <CustomButton disabled={!stocks?.length} onClick={() => setOpen(true)}>
      Unificar
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Unificar produtos"
      >
        <ModalContainer>
          {form()}
          <ItemsSelected>
            {stocks.map(i => (
              <button key={i._id} type="button">
                {i.items_id}
              </button>
            ))}
          </ItemsSelected>
        </ModalContainer>
      </Modal>
    </CustomButton>
  );
};

ModalMerge.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stocks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalMerge;
