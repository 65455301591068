/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import MaskedInput from 'react-text-mask';
import { InputStyled, FaSpinnerAnimated } from './styles';
import { IconWrapper } from '..';

const Input = ({
  icon,
  onClick,
  onChange,
  loading,
  minLength,
  debounceTimeout,
  mask,
  primary,
  ...props
}) => {
  props = { ...props, ...(primary ? { fill: 'white' } : {}) };
  return (
    <>
      <InputStyled primary={primary} {...props} onClick={onClick}>
        {!debounceTimeout ? (
          mask ? (
            <MaskedInput
              onChange={onChange}
              guide={false}
              {...props}
              mask={mask}
            />
          ) : (
            <input onChange={onChange} {...props} />
          )
        ) : (
          <DebounceInput
            minLength={minLength}
            debounceTimeout={debounceTimeout}
            onChange={onChange}
            {...props}
          />
        )}
        {icon && (
          <span>
            {!loading ? (
              <IconWrapper {...props} size={20} name={icon} />
            ) : (
              <FaSpinnerAnimated />
            )}
          </span>
        )}
        {props.maxlength && (
          <small>{`${props.value.length} / ${props.maxlength}`}</small>
        )}
      </InputStyled>
    </>
  );
};

Input.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  minLength: PropTypes.number,
  debounceTimeout: PropTypes.number,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  primary: PropTypes.bool,
  maxlength: PropTypes.string,
  value: PropTypes.oneOf(PropTypes.any),
};

Input.defaultProps = {
  icon: '',
  primary: false,
  onChange: () => {},
  onClick: () => {},
  loading: false,
  minLength: 0,
  debounceTimeout: 0,
  mask: null,
  maxlength: null,
  value: '',
};

export default Input;
