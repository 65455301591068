import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomButton, Checkbox } from '../../../../componets';
import { ModalDelete, ModalMerge } from '..';

const SelectStock = ({ mergeStock, deleteStock }) => {
  const [selected, setSelected] = useState([]);

  const [isMerging, setIsMerging] = useState(false);

  const setDefault = () => {
    setIsMerging(false);
    setSelected([]);
  };

  const handleMerge = () => {
    if (isMerging) setDefault();
    setIsMerging(!isMerging);
  };

  const handleSubmit = f => async (...params) => {
    await f(...params);
    setDefault();
  };

  const merge = () => (
    <>
      <CustomButton inverted={isMerging} type="button" onClick={handleMerge}>
        {isMerging ? 'Cancelar' : 'Selecionar'}
      </CustomButton>
      {isMerging && (
        <>
          <ModalDelete onSubmit={handleSubmit(deleteStock)} stocks={selected} />
          <ModalMerge onSubmit={handleSubmit(mergeStock)} stocks={selected} />
        </>
      )}
    </>
  );

  const removeOrAddInArray = (arr = [], val) => {
    return arr.includes(val) ? arr.filter(a => a !== val) : [...arr, val];
  };

  const checkbox = stock => (
    <label>
      <Checkbox
        checked={selected.includes(stock)}
        onChange={() => setSelected(removeOrAddInArray(selected, stock))}
      />
    </label>
  );

  return { merge, checkbox, isMerging };
};

SelectStock.propTypes = {
  deleteStock: PropTypes.func.isRequired,
  mergeStock: PropTypes.func.isRequired,
};

export default SelectStock;
