import React, { useState } from 'react';

import { Burger, Menu } from './components';
import { Container } from './styles';
import { sideBarRoutes } from '../../routes';

export default function SideNav() {
  const [open, setOpen] = useState(false);
  const [firstClick, setFirstClick] = useState(true);

  return (
    <Container
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => {
        setFirstClick(true);
        setOpen(false);
      }}
      onClick={() => {
        if (firstClick) {
          setFirstClick(false);
          return;
        }
        setOpen(!open);
      }}
    >
      <Burger open={open} />
      <Menu open={open} tabs={Object.values(sideBarRoutes)} />
    </Container>
  );
}
