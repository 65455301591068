import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const GetAccountOptions = () => {
  const { profile } = useSelector(state => state.user);

  const options = useMemo(
    () =>
      profile?.accounts?.map(acc => ({
        value: acc.id,
        label: acc.nickname,
      })),
    [profile]
  );

  return [options];
};
