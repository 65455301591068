import io from 'socket.io-client';
import { store } from '../store';

const Socket = () => {
  const { profile } = store.getState().user;
  return io('/', {
    query: { user: profile._id },
  });
};

export default Socket;
