import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';

export const LoadData = (url, params) => {
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadData = useMemo(
    () => async () => {
      try {
        setLoading(true);
        const { docs, ...info } = await api.get(url, { params });
        setData(d => [...d, ...docs]);
        setPagination(info);
      } catch (error) {
        toast.error(error.response?.data.error || error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setLoading(false);
      }
    },
    [params, url]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [data, setData, pagination, loading];
};
