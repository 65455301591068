import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import {
  Questions,
  SignIn,
  SignUp,
  Profile,
  Dashboard,
  Accounts,
  QuestionsSettings,
  QuestionsAnswers,
  QuestionsHistory,
  Blacklist,
  Concurrent,
  ConcurrentSummary,
  Message,
  Chat,
  ForgotPassword,
  Sales,
  Stock,
  SalesSummary,
  Operator,
  StockOrder,
  Adverts,
  AdvertsCopyConfirmation,
  AdvertsCopyCompleted,
  Claims,
  BlacklistList,
} from '../pages';

export const sideBarRoutes = {
  dashboard: {
    path: '/dashboard',
    icon: 'indicatorsSidebarIcon',
    text: 'Indicadores',
    main: Dashboard,
  },

  sales: {
    path: '/sales',
    icon: 'salesSideBarIcon',
    text: 'Vendas',
    main: Sales,
  },
  claims: {
    path: '/claims',
    icon: 'warning',
    text: 'Reclamações',
    main: Claims,
  },
  questions: {
    path: '/questions/pendings',
    icon: 'questionsSidebarIcon',
    text: 'Perguntas',
    main: Questions,
  },
  adverts: {
    path: '/adverts/copy',
    icon: 'copyIcon',
    text: 'Replicador',
    main: Adverts,
  },
  concurrent: {
    path: '/concurrent',
    icon: 'concurrentSidebarIcon',
    text: 'Concorrentes',
    main: Concurrent,
  },
  stock: {
    path: '/stock',
    icon: 'stockSidebarIcon',
    text: 'Estoque',
    main: Stock,
  },
  message: {
    path: '/message',
    icon: 'messageSidebarIcon',
    text: 'Mensagens ',
    main: Message,
  },
  accounts: {
    path: '/accounts',
    icon: 'userSidebarIcon',
    text: 'Contas',
    main: Accounts,
  },
  blacklist: {
    path: '/blacklist',
    icon: 'padlockSidebarIcon',
    text: 'Bloqueados',
    main: Blacklist,
  },
  settings: {
    path: '/settings',
    icon: 'settingsSidebarIcon',
    text: 'Configurações',
    main: Profile,
  },
};

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      {Object.values(sideBarRoutes).map(route => (
        <Route
          key={route.path}
          exact
          isPrivate
          path={route.path}
          component={route.main}
        />
      ))}
      <Route path="/stock/:id/orders" component={StockOrder} isPrivate />
      <Route path="/message/chat" component={Chat} isPrivate />
      <Route
        exact
        path="/questions/settings"
        component={QuestionsSettings}
        isPrivate
      />
      <Route
        exact
        path="/questions/answers"
        component={QuestionsAnswers}
        isPrivate
      />
      <Route
        exact
        path="/questions/history"
        component={QuestionsHistory}
        isPrivate
      />
      <Route exact path="/sales/summary" component={SalesSummary} isPrivate />
      <Route
        exact
        path="/concurrent/summary"
        component={ConcurrentSummary}
        isPrivate
      />
      <Route exact path="/settings/operator" component={Operator} isPrivate />
      <Route
        exact
        path="/adverts/copy/confirmation"
        component={AdvertsCopyConfirmation}
        isPrivate
      />
      <Route
        exact
        path="/adverts/copy/completed"
        component={AdvertsCopyCompleted}
        isPrivate
      />
      <Route exact path="/blacklist/list" component={BlacklistList} isPrivate />
    </Switch>
  );
}
