import styled, { keyframes, css } from 'styled-components';
import { darken, lighten } from 'polished';
import { theme } from '../../utils/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const isPrimary = css`
  background-color: ${lighten(0.1, theme.primary)};
  color: ${theme.white};
  border: 1px solid ${theme.white};

  input {
    background-color: ${lighten(0.1, theme.primary)};
    color: ${theme.white};
    &::placeholder {
      color: ${theme.white};
    }
  }
`;

const getInputStyles = props => {
  if (props.primary) {
    return isPrimary;
  }
  return '';
};

export const InputStyled = styled.div`
  border: 1px solid ${theme.lightgrey};
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${theme.white};
  position: relative;
  small {
    color: ${lighten(0.06, theme.text)};
    font-size: 0.7em;
    font-weight: lighter;
    position: absolute;
    bottom: -15px;
    right: 0;
  }

  span {
    border-radius: 0 5px 5px 0;
    padding: 5px;
    height: 100%;
    img {
      color: ${theme.lightgrey};
      &:hover {
        color: ${darken(0.06, theme.lightgrey)};
        transform: scale(1.1);
      }
    }
  }

  input {
    width: 100%;
    border-radius: 5px 0 0 5px;
    border: none;
    flex: 1;
    padding: 5px 10px;
  }

  ${getInputStyles}
`;

export const FaSpinnerAnimated = styled.div`
  color: ${({ textcolor }) => textcolor};
  animation: ${rotate} 2s linear infinite;
`;
