import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../services/api';

export const LoadMessages = () => {
  const [loading, setLoading] = useState(false);
  const [autoMessages, setAutoMessages] = useState({});
  const { account } = useSelector(state => state.user);

  const loadMessages = async () => {
    try {
      setLoading(true);
      const res = await api.get(`automessage`);
      setAutoMessages(res || {});
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = event => {
    const { value, name } = event.target;

    setAutoMessages({
      ...autoMessages,
      [name]: value,
    });
  };

  const salveAutoMessages = async all => {
    try {
      setLoading(true);
      if (all) {
        await api.put(`automessage?change=all`, autoMessages);
      } else {
        await api.put(`automessage`, autoMessages);
      }
      toast.success('Mudanças salvas com sucesso');
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (account) loadMessages();
  }, [account]);

  return [autoMessages, handleChange, salveAutoMessages, loading];
};
