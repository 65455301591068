/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';
import { ContainerRating, Text } from './styles';

const LIGHTEN = 0.3;

const RatingWrapped = ({ rate, label, text }) => {
  const attr = {
    marginRight: '5px',
    width: '100%',
    height: '8px',
  };

  const colorOfRate = theme.reputation[rate - 1];

  const fullSymbol = (indexForFull = null) =>
    Array(5)
      .fill(0)
      .map((_, index) => (
        <span
          key={index}
          style={{
            ...attr,
            background:
              index + 1 === indexForFull
                ? colorOfRate
                : lighten(LIGHTEN, theme.reputation[index]),
          }}
        />
      ));

  return (
    <label title={label}>
      <ContainerRating>{fullSymbol(rate)}</ContainerRating>
      {text && <Text color={colorOfRate}>MercadoLíder {text}</Text>}
    </label>
  );
};

RatingWrapped.propTypes = {
  rate: PropTypes.number.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
};

RatingWrapped.defaultProps = {
  label: PropTypes.string,
  text: '',
};

export default RatingWrapped;
