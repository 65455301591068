import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormInput, CustomButton } from '../../componets';
import { signOut } from '../../store/modules/auth/actions';
import { updateProfileRequest } from '../../store/modules/user/actions';
import { theme } from '../../utils/colors';

import { Container, Content, FormContainer } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector(state => state.user);

  function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Content>
        <div>
          <h4>Perfil</h4>
        </div>
      </Content>
      <FormContainer>
        <Formik
          initialValues={{
            ...profile,
            oldPassword: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object({
            first_name: Yup.string(),
            last_name: Yup.string(),
            email: Yup.string().email(),
            oldPassword: Yup.string().min(6),
            password: Yup.string()
              .min(6)
              .when('oldPassword', (oldPassword, field) =>
                oldPassword ? field.required() : field
              ),
            confirmPassword: Yup.string().when('password', (password, field) =>
              password
                ? field
                    .required()
                    .oneOf([Yup.ref('password')], 'senha diferente')
                : field
            ),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormInput inverted name="first_name" label="Nome" />
            <FormInput inverted name="last_name" label="Sobrenome" />
            <FormInput
              inverted
              name="email"
              type="email"
              label="Seu endereço de e-mail"
            />

            <FormInput
              inverted
              type="password"
              name="oldPassword"
              label="Sua senha atual"
            />
            <FormInput
              inverted
              type="password"
              name="password"
              label="Nova senha"
            />
            <FormInput
              inverted
              type="password"
              name="confirmPassword"
              label="Confirmação de senha"
            />
            <section>
              <CustomButton inverted loading={loading} type="submit">
                Atualizar perfil
              </CustomButton>
              <CustomButton
                color={theme.negative}
                type="button"
                onClick={handleSignOut}
                isSignIn
              >
                Sair do App
              </CustomButton>
            </section>
          </Form>
        </Formik>
      </FormContainer>
    </Container>
  );
}
