/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Container, Content, Info } from './styles';
import { Button } from '../../componets';

export default function QuestionsSettings() {
  const [formFields, setFormFields] = useState({
    greeting: '',
    signature: '',
  });

  useEffect(() => {
    let ignore = false;
    api.get('setup_answers').then(async res => {
      if (!ignore) {
        if (res) setFormFields(res);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const handleSubmit = () => {
    const { _id } = formFields;

    if (_id) {
      api
        .put(`setup_answers/${_id}`, formFields)
        .then(res => {
          setFormFields(res);
          toast.success('Opoeração realizada com sucesso');
        })
        .catch(() => {
          toast.error('Preencha todos os campos');
        });
    } else {
      api
        .post('setup_answers', formFields)
        .then(res => {
          setFormFields(res);
          toast.success('Opoeração realizada com sucesso');
        })
        .catch(() => {
          toast.error('Preencha todos os campos');
        });
    }
  };

  const handleChange = event => {
    const { value, name } = event.target;

    setFormFields({
      ...formFields,
      [name]: value === null ? [] : value,
    });
  };

  return (
    <>
      <Container>
        <Content>
          <div className="form" onSubmit={e => e.preventDefault()}>
            <h2>Configuração</h2>
            <div>
              <p>Saudação</p>
              <input
                value={formFields.greeting}
                onChange={handleChange}
                type="text"
                name="greeting"
              />
            </div>
            <div>
              <p>Assinatura</p>
              <TextareaAutosize
                value={formFields.signature}
                onChange={handleChange}
                type="text"
                name="signature"
              />
            </div>

            <Button onClick={handleSubmit} type="button">
              Salvar
            </Button>
          </div>
          <Info>
            <h4>Dicas</h4>
            <p>
              Quando estiver respondendo uma pergunta, digite @ para abrir uma
              lista com suas respostas rápidas.
            </p>
            <p>
              Quer inserir um link para outra de suas publicações nas repostas?
              Digite # para buscá-la e insira sua direção.
            </p>
            <p>
              Você pode escrever @COMPRADOR na sua saudação e completar
              automaticamente com o apelido de quem realizou a pergunta. Ex. Olá
              @COMPRADOR, muito obrigado por entrar em contato.
            </p>
            <p>Responda mais rápido suas perguntas usando Ctrl + Enter.</p>
          </Info>
        </Content>
      </Container>
    </>
  );
}
