import React, { useState } from 'react';

import {
  CustomButton,
  ListScroll,
  SellerNickname,
  SubHeader,
} from '../../componets';
import { formatMoney, makePlural, theme, useScroll } from '../../utils';
import { LoadData } from './hooks';
import { Container, Item, List, ListData } from './styles';

const Chip = (text, color) => (
  <spam style={{ color, textTransform: 'uppercase' }}>{text}</spam>
);

const Status = {
  completed: Chip('Copiado', theme.success),
  pending: Chip('Pendente', theme.notification),
  failed: Chip('Cancelado', theme.negative),
};

export default function AdvertsCopyCompleted() {
  const [query, setQuery] = useState({});
  const [data, setData, info, loading] = LoadData('/adverts/tasks', query);

  const shouldBeUpdated = () =>
    info.hasNext && !loading && query.page !== info.nextPage && data.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <Container>
      <SubHeader text={`Anúncios copiados ${''}`}>
        <span />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton to="/adverts/copy" inverted>
            Voltar
          </CustomButton>
        </div>
      </SubHeader>

      <ListScroll>
        {data?.map(({ status, item }) => (
          <List key={item.link}>
            <Item key={item.id}>
              <ListData>
                <a target="_blank" rel="noopener noreferrer" href={item?.link}>
                  <h4>{item.title}</h4>
                </a>
                <div>
                  <small>
                    {`${item.available_quantity} ${makePlural(
                      item.available_quantity,
                      'disponível'
                    )} |`}
                    <strong>{formatMoney(item.price)}</strong>
                  </small>
                </div>

                <div>
                  <small>Publicação {item.id}</small>
                  <SellerNickname seller_id={item.seller_id} />
                </div>
              </ListData>
            </Item>
            {Status[status]}
          </List>
        ))}
      </ListScroll>
    </Container>
  );
}
