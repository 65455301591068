/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, CustomButton, Select } from '../../../../componets';
import { ModalContainer, ButtonContainer, InputsContainer } from './styles';
import { GetAccountOptions } from './hooks';

const itemsOptions = [
  {
    value: 'active',
    label: 'Ativo',
  },
  {
    value: 'paused',
    label: 'Pausado',
  },
  {
    value: 'under_review',
    label: 'Em revisão',
  },
  {
    value: '',
    label: 'Todos',
  },
];

const ModalRegister = ({ onSubmit }) => {
  const [formFields, setFormFields] = useState({
    seller_ids: [],
    status: itemsOptions[0].value,
  });

  const [open, setOpen] = useState(false);

  const [accountOptions] = GetAccountOptions();

  const handleChangeSelect = value => {
    const seller_ids = value?.map(({ value: id }) => id) ?? [];
    setFormFields({ ...formFields, seller_ids });
  };

  const handleChangeStatus = ({ value: status }) => {
    setFormFields({ ...formFields, status });
  };

  const handleSubmit = e => {
    e.preventDefault();
    return onSubmit(formFields).then(() => setOpen(false));
  };

  const form = () => (
    <form onSubmit={handleSubmit}>
      <InputsContainer>
        <div>
          <label htmlFor="accounts">Importar</label>
          <Select
            closeMenuOnSelect={false}
            placeholder="Contas"
            isMulti
            onChange={handleChangeSelect}
            defaultValue={accountOptions[0]}
            options={accountOptions}
            allowSelectAll
          />
        </div>
        <div>
          <label htmlFor="status">Status</label>
          <Select
            name="status"
            onChange={handleChangeStatus}
            options={itemsOptions}
            placeholder="Status"
            defaultValue={itemsOptions[0]}
          />
        </div>
      </InputsContainer>
      <ButtonContainer>
        <CustomButton type="submit">Cadastrar</CustomButton>
      </ButtonContainer>
    </form>
  );

  return (
    <CustomButton onClick={() => setOpen(true)}>
      Importar do Mercado Livre
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => {}}
        title="Importar do Mercado Livre"
      >
        <ModalContainer>{form()}</ModalContainer>
      </Modal>
    </CustomButton>
  );
};

ModalRegister.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default ModalRegister;
