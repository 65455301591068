import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';

export const LoadData = () => {
  const [authRoutes, setAuthRoutes] = useState([]);
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      const response = await api.get('operators/auth-routes');
      setAuthRoutes(response);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, []);

  const getOperators = useCallback(async () => {
    try {
      const response = await api.get('operators');
      setOperators(response);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, []);

  const decorador = callback => async (...params) => {
    try {
      setLoading(true);
      await callback(...params);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = decorador(async (method, data, params = '') => {
    await api[method](`operators/${params}`, data);
    getOperators();
    toast.success('Operação realizada com sucesso', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  useEffect(() => {
    loadData();
    getOperators();
  }, [getOperators, loadData]);

  return [authRoutes, operators, loading, handleSubmit];
};
