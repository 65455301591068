import styled from 'styled-components';
import { darken } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .close {
    position: absolute;
    right: 30px;
    color: ${theme.negative};

    &:hover {
      color: ${darken(0.06, theme.negative)};
      transform: scale(1.1);
      cursor: pointer;
    }
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 45px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px black;
  z-index: 10;
  background-color: white;
  border-radius: 5px;
  min-width: 200px;

  ul {
    display: grid;

    li {
      cursor: pointer;
      padding: 0.4rem;
      font-size: large;
      color: ${theme.text};
      transition: font-size 0.4s ease-in, background-color 0.2s linear;
      &:hover {
        background-color: ${darken(0.1, theme.white)};
      }
    }
  }

  @media (max-width: ${theme.mobile}) {
    display: flex;
    flex-direction: column;
    right: auto;
  }

  .picker {
    @media (max-width: ${theme.mobile}) {
      display: flex;
      flex-direction: column;
      max-width: 90vw;
    }
  }
`;
