import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../store/modules/adverts/actions';
import {
  Checkbox,
  CustomButton,
  Input,
  ListScroll,
  Select,
  SellerNickname,
  SubHeader,
} from '../../componets';
import { Container, ListData, Item } from './styles';
import { formatMoney, makePlural, useScroll } from '../../utils';

export const advertsOptions = [
  {
    value: 'keywords',
    label: 'Palavra chave',
  },
  {
    value: 'nickname',
    label: 'Apelido',
  },
  {
    value: 'url',
    label: 'URL',
  },
];

export default function Adverts() {
  const { adverts, copy } = useSelector(state => state);
  const dispatch = useDispatch();

  const [option, setOption] = useState(advertsOptions[0]);
  const [text, setText] = useState('');

  const handleAddItem = item => {
    dispatch(actions.addToCopy(item));
  };

  const handleRemoveItem = id => {
    dispatch(actions.removeToCopy(id));
  };

  const handleSearch = (page = 1) => {
    if (option.value === 'url') {
      dispatch(actions.fetchItemByUrl(text));
    }
    if (option.value === 'nickname') {
      dispatch(actions.fetchItemByNickname(text, page));
    }
    if (option.value === 'keywords') {
      dispatch(actions.fetchItemByKeywords(text, page));
    }
  };

  const shouldBeUpdated = () =>
    text &&
    adverts.items.paging.hasNext &&
    !adverts.loading &&
    adverts.items.paging.page !== adverts.items.paging.nextPage &&
    adverts.items.data.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      handleSearch(adverts.items.paging.nextPage);
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  const isInCopyState = id => copy.items.some(item => item.id === id);

  return (
    <Container>
      <SubHeader text="Replicador">
        <Select
          primary
          closeMenuOnSelect
          placeholder={option.label}
          onChange={setOption}
          options={advertsOptions}
        />
        <Input
          primary
          name="text"
          fill="white"
          onChange={e => setText(e.target.value)}
          icon="searchIcon"
          placeholder={`${option.label}`}
          value={text}
          onKeyPress={e => {
            if (e.key === 'Enter') handleSearch();
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton
            loading={adverts.loading}
            onClick={() => handleSearch()}
            inverted
          >
            Buscar
          </CustomButton>
          <CustomButton
            to="/adverts/copy/confirmation"
            loading={adverts.loading}
            inverted
          >
            Selecionados {copy.items.length}
          </CustomButton>
        </div>
      </SubHeader>

      <ListScroll loading={adverts.loading}>
        {adverts.items.data?.map(item => (
          <Item key={item.id}>
            <label>
              <Checkbox
                checked={isInCopyState(item.id)}
                onChange={e => {
                  if (e.target.checked) handleAddItem(item);
                  else handleRemoveItem(item.id);
                }}
              />
            </label>
            <div>
              <img
                src={item?.secure_thumbnail?.replace(/(http)(?=:)/, '$1s')}
                alt=""
              />
            </div>
            <ListData>
              <a target="_blank" rel="noopener noreferrer" href={item?.link}>
                <h4>{item.title}</h4>
              </a>
              <div>
                <small>
                  {`${item.available_quantity} ${makePlural(
                    item.available_quantity,
                    'disponível'
                  )} | `}

                  <strong>{formatMoney(item.price)}</strong>
                </small>
              </div>

              <div>
                <small>Publicação {item.id}</small>
                <SellerNickname seller_id={item.seller_id} />
              </div>
            </ListData>
          </Item>
        ))}
      </ListScroll>
    </Container>
  );
}
