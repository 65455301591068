import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { BoxStyled } from './styles';

const Box = ({ title, answer }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { title, answer, type: 'BOX' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <BoxStyled ref={drag} style={{ opacity }}>
      {title}
    </BoxStyled>
  );
};

Box.propTypes = {
  title: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default Box;
