import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const SubHeader = ({ children, text }) => {
  const num_elements = (children?.length ?? 0) + 1;
  return (
    <Container num_elements={num_elements}>
      <div>
        <h4>{text}</h4>
        {children}
      </div>
    </Container>
  );
};

SubHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SubHeader.defaultProps = {
  text: '',
  children: '',
};

export default SubHeader;
