/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DateRangeWrapped,
  Input,
  Select,
  ListScroll,
  InfoItem,
} from '../../componets';
import { formatLong, useScroll } from '../../utils';
import {
  Container,
  Content,
  List,
  Order,
  ListContainer,
  ListHeader,
  Thumb,
  UserData,
} from './styles';
import { LoadData } from './hooks';
import { ORDER_STATUS } from '../../enum';

export const orderOptions = [
  {
    value: 'paid',
    label: 'Pago',
  },
  {
    value: 'cancelled',
    label: 'Cancelado',
  },
  {
    value: 'confirmed',
    label: 'Confirmado',
  },
];

export default function Sales({ location }) {
  const { account, profile } = useSelector(state => state.user);
  const [query, setQuery] = useState({
    ids: account?.id,
    text: getNickname(),
  });
  const [data, setData, info, loading] = LoadData('order', query);

  const handleChangeQuery = fieldValue => {
    setData([]);
    setQuery({
      ...query,
      ...fieldValue,
      page: 1,
    });
  };

  const options = useMemo(
    () =>
      profile?.accounts?.map(acc => ({
        value: acc.id,
        label: acc.nickname,
      })),
    [profile]
  );

  const handleChangeStatus = ({ value: status }) => {
    handleChangeQuery({ status });
  };

  const handleChangeAccount = value => {
    const ids = value?.reduce((acc, { value: id }) => `${acc},${id}`, '') ?? '';
    handleChangeQuery({ ids });
  };

  const handleChangeDate = event => {
    const { value } = event.target;
    handleChangeQuery(value);
  };

  const handleChange = event => {
    const { value, name } = event.target;
    handleChangeQuery({ [name]: value });
  };

  const shouldBeUpdated = () =>
    info.hasNextPage && !loading && query.page !== info.nextPage && data.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  function getNickname() {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('nickname');
  }

  useScroll({ onYReachEnd: handleOnYReachEnd });

  const getFullName = buyer =>
    buyer.first_name ? `${buyer.first_name} ${buyer.last_name} ` : ' ';

  return (
    <>
      <Container>
        <Content>
          <div>
            <h4>Vendas</h4>
            <Select
              primary
              closeMenuOnSelect={false}
              placeholder="Conta"
              isMulti
              onChange={handleChangeAccount}
              options={options}
              allowSelectAll
            />
            <Select
              primary
              name="status"
              onChange={handleChangeStatus}
              isLoading={loading}
              options={orderOptions}
              placeholder="Status"
            />

            <DateRangeWrapped
              primary
              name="date"
              placeholder="Selecione uma data"
              onChange={handleChangeDate}
              months={1}
            />
          </div>
        </Content>
        <ListContainer>
          <ListScroll
            header={
              <ListHeader>
                <label htmlFor="text">Buscar em minhas vendas</label>
                <Input
                  name="text"
                  onChange={handleChange}
                  value={query.text}
                  icon="searchIcon"
                  loading={loading}
                  minLength={0}
                  debounceTimeout={800}
                  placeholder="Publicação"
                />
              </ListHeader>
            }
            loading={loading}
          >
            {data.map(d => (
              <List key={d.id}>
                <Order>
                  <Thumb>
                    {d?.order_items.map(item => (
                      <div key={item.id}>
                        <img src={item?.details?.secure_thumbnail} alt="" />
                      </div>
                    ))}
                  </Thumb>
                  {d?.order_items.map(item => (
                    <InfoItem
                      key={item.id}
                      item={{ ...item, ...item?.details }}
                      buttonsText={[d?.status, d?.shipping?.status].map(
                        text => ORDER_STATUS[text]
                      )}
                    />
                  ))}
                  <UserData>
                    <div>
                      <small>
                        Venda realizada em
                        <strong> {formatLong(d.date_created, 'Pp')}</strong>
                      </small>
                    </div>
                    <div>
                      <small>Comprador</small>
                      <p>{d.buyer.nickname}</p>
                      <span>{getFullName(d.buyer)}</span>
                    </div>
                  </UserData>
                </Order>
              </List>
            ))}
          </ListScroll>
        </ListContainer>
      </Container>
    </>
  );
}

Sales.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
