/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaHistory } from 'react-icons/fa';
import api from '../../services/api';
import { theme, timeAgo } from '../../utils';
import {
  ModalYN,
  AutoCompleteInput,
  Checkbox,
  LottieWraper,
  Loader,
  CustomButton,
  FormModal,
  IconWrapper,
  ToogleWrapper,
} from '../../componets';
import {
  Container,
  Content,
  Summary,
  Card,
  Item,
  QuestionStyled,
  MsgShip,
  FastAsnwersList,
  SetupAnswers,
  DropDownList,
  UserStyled,
  HasSales,
} from './styles';
import { Box, Dustbin } from './components';

const formatShippingoptions = (options, zip_code, quantity = 1) =>
  options &&
  options
    .reduce(
      (acc, cur) =>
        `${acc} por envio ${cur.name} é ${
          cur.cost ? `de R$${cur.cost}` : 'grátis'
        } receba ${moment(cur.delivery_time.date).format('ddd, D/MMM')} (${
          cur.delivery_time.days === 1
            ? `${cur.delivery_time.days} dia útil);`
            : `${cur.delivery_time.days} dias úteis);`
        }`,
      `O custo de envio ao CEP ${options[0].zip_code.replace(
        /(\d{5})(\d{3})/,
        '$1-$2'
      )} (${quantity} un)`
    )
    .replace(/;$/, '.');

const getShipOptions = async (zip_code, item_id, quantity = 1) => {
  const options = await api.get('shipping_options', {
    params: {
      quantity,
      item_id,
      zip_code,
    },
  });

  return formatShippingoptions(options, zip_code, quantity);
};

export default function Questions() {
  const { account } = useSelector(state => state.user);
  const { questions: notification } = useSelector(state => state.notification);
  const [questions, setQuestions] = useState([]);
  const [shippingInfo, setShippingInfo] = useState({
    msg: '',
    questionId: null,
  });
  const [formFields, setFormFields] = useState({});
  const [fastAnswers, setFastAnswers] = useState([]);
  const [setupAnswers, setSetupAnswers] = useState({});
  const [setupAnswersChecked, setSetupAnswersChecked] = useState({
    greeting: true,
    signature: true,
  });
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState({
    question: false,
    user: false,
    ship: false,
  });
  const [dataModal, setDataModal] = useState();

  const loadQuestions = async ignore => {
    try {
      setLoadingQuestions(true);
      const { results } = await api.get('questions');
      if (!ignore) {
        setQuestions(results);
      }
    } catch (error) {
      setQuestions([]);
    } finally {
      setLoadingQuestions(false);
    }
  };

  useEffect(() => {
    let ignore = false;
    loadQuestions(ignore);

    return () => {
      ignore = true;
    };
  }, [account, notification]);

  useEffect(() => {
    let ignore = false;
    api.get('fast_answers').then(async res => {
      if (!ignore) {
        setFastAnswers(res.fast_answers);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;
    api.get('setup_answers').then(async res => {
      if (!ignore) {
        setSetupAnswers(res);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const handleChange = event => {
    const { value, name } = event.target;

    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleSubmit = async id => {
    try {
      setLoading({ ...loading, [id]: true });
      const re = new RegExp('@COMPRADOR');

      let text = formFields[id];
      const { greeting, signature } = setupAnswersChecked;
      if (greeting && setupAnswers.greeting) {
        text = `${setupAnswers?.greeting?.replace(
          re,
          questions.find(q => q.id === id).from.nickname
        )} ${text}`;
      }
      if (signature && setupAnswers.signature) {
        text += ` ${setupAnswers?.signature}`;
      }

      const response = await api.put('questions', {
        question_id: id,
        text,
      });
      setQuestions(
        questions.filter(q => parseInt(q.id, 10) !== parseInt(response.id, 10))
      );
      setFormFields({ ...formFields, [id]: '' });
    } catch (err) {
      toast.error(err.response?.data?.error);
      toast.error(err.message);
    } finally {
      setLoading({ ...loading, [id]: false });
    }
  };

  const openModal = async (data, field) => {
    setOpen({ ...open, [field]: true });
    setDataModal(data);
  };

  const closeModal = () => {
    setOpen({ question: false, user: false, ship: false });
    setShippingInfo({
      msg: '',
      questionId: null,
    });
  };

  const handleDelete = async () => {
    try {
      await api.delete(`questions/${dataModal}`);
      setQuestions(questions.filter(q => q.id !== dataModal));
    } catch (err) {
      toast.error(err.response?.data?.error);
    } finally {
      closeModal();
    }
  };

  const handleBlockUser = async () => {
    try {
      await api.post(`blacklist`, {
        users: [
          {
            id: dataModal,
            permissions: [
              {
                accountId: account.id,
                blockedQuestion: true,
                blockedBuy: false,
              },
            ],
          },
        ],
      });
    } catch (err) {
      toast.error(err.response?.data?.error);
    } finally {
      closeModal();
    }
  };

  const handleShipOptions = async data => {
    const { zip_code, quantity } = data;
    const { item_id, questionId } = dataModal;
    try {
      const msg = await getShipOptions(zip_code, item_id, quantity);
      setShippingInfo({ msg, questionId });
    } catch (err) {
      setShippingInfo({
        msg: '',
        questionId: null,
      });
      toast.error(err.response?.data?.error);
    } finally {
      // toast.error(response.data);
    }
  };

  const addInfoToField = (questionId, info) => {
    setFormFields({
      ...formFields,
      [questionId]: formFields[questionId]
        ? formFields[questionId] + info
        : info,
    });
    closeModal();
  };

  const renderShippingInfo = () =>
    shippingInfo.msg && (
      <div style={{ maxWidth: 'fit-content', padding: '15px' }}>
        <p>{shippingInfo.msg}</p>
        <CustomButton
          onClick={() =>
            addInfoToField(shippingInfo.questionId, shippingInfo.msg)
          }
          type="button"
        >
          Inserir
        </CustomButton>
      </div>
    );

  const renderModals = () => (
    <>
      <ModalYN
        open={open.question}
        onClose={closeModal}
        onAccept={() => handleDelete()}
        title="Excluir pergunta"
        body="Tem certeza que deseja excluir esta pergunta ?"
      />
      <ModalYN
        open={open.user}
        onClose={closeModal}
        onAccept={() => handleBlockUser()}
        title="Bloquear usuário"
        body="Tem certeza que deseja bloquear este usuário ?"
      />

      <FormModal
        onClose={closeModal}
        open={open.ship}
        onSubmit={handleShipOptions}
        footer={renderShippingInfo}
      />
    </>
  );

  return (
    <Container>
      <Content>
        <Summary>
          <h3>
            {questions.length} <small>perguntas pendentes</small>
          </h3>
        </Summary>
        {!loadingQuestions && !questions.length && <LottieWraper />}
        {renderModals()}
        <Loader loading={loadingQuestions} full color={theme.primary} />
        {questions.map(q => (
          <Card key={q.id}>
            <Item>
              <img src={q.item.secure_thumbnail} alt="" />
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={q.item.permalink}
                >
                  <h3>{q.item.title}</h3>
                </a>
                <p>
                  <span>R$ {q.item.price} </span>x {q.item.available_quantity}{' '}
                  disponível
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={q.item.permalink}
                  >
                    {q.item.id}
                  </a>
                </p>
              </div>
              <div>
                <IconWrapper
                  textTooltip="Excluir pergunta"
                  name="trashIcon"
                  onClick={() => openModal(q.id, 'question')}
                />
                <IconWrapper
                  name="lockIcon"
                  textTooltip="Bloquear usuário"
                  onClick={() => openModal(q.from.id, 'user')}
                />
                <IconWrapper
                  textTooltip="Calcular frete"
                  name="truckIcon"
                  onClick={() =>
                    openModal({ item_id: q.item_id, questionId: q.id }, 'ship')
                  }
                />
              </div>
            </Item>
            <UserStyled>
              <IconWrapper name="chatIcon" fill={theme.primary} size={12} />
              <p>
                Pergunta de <strong>{q.from.nickname}</strong>
              </p>
              <p className="time">
                Membro desde{' '}
                {moment(q.from.registration_date).format('DD/MM/YYYY')}
              </p>
            </UserStyled>

            {q?.sales?.length > 0 && (
              <HasSales>
                <p>
                  Já comprou com você{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/sales?nickname=${q.from.nickname}`}
                  >
                    [ver]
                  </a>
                </p>
              </HasSales>
            )}
            <QuestionStyled>
              <p>{q.text}</p>
              <FaHistory size={10} />
              <p className="time">{timeAgo(q.date_created)}</p>
            </QuestionStyled>
            {setupAnswers.greeting && (
              <SetupAnswers>
                <label>
                  <Checkbox
                    checked={setupAnswersChecked.greeting}
                    onChange={e =>
                      setSetupAnswersChecked({
                        ...setupAnswersChecked,
                        greeting: e.target.checked,
                      })
                    }
                  />
                  <span style={{ marginLeft: 8 }}>{setupAnswers.greeting}</span>
                </label>
              </SetupAnswers>
            )}
            <Dustbin addInfoToField={addInfoToField} id={q.id}>
              <AutoCompleteInput
                name={q.id}
                value={formFields[q.id]}
                onChange={handleChange}
                data={fastAnswers}
                handlesubmit={handleSubmit}
                placeholder="Escreva sua resposta ..."
                rows="8"
              />
            </Dustbin>
            {setupAnswers.signature && (
              <SetupAnswers>
                <label>
                  <Checkbox
                    checked={setupAnswersChecked.signature}
                    onChange={e =>
                      setSetupAnswersChecked({
                        ...setupAnswersChecked,
                        signature: e.target.checked,
                      })
                    }
                  />
                  <span style={{ marginLeft: 8 }}>
                    {setupAnswers.signature}
                  </span>
                </label>
              </SetupAnswers>
            )}

            <MsgShip>
              {q.zip_code && (
                <CustomButton
                  onClick={() =>
                    addInfoToField(q.id, formatShippingoptions(q.zip_code))
                  }
                  type="button"
                >
                  <p>Frete encontrado</p>
                </CustomButton>
              )}
              {q.keywords.map(id => {
                const { answer, title } =
                  fastAnswers.find(({ _id }) => _id === id) || {};
                return (
                  <div style={{ margin: '10px 0' }}>
                    <CustomButton
                      alert
                      key={id}
                      onClick={() => addInfoToField(q.id, ` ${answer}`)}
                      type="button"
                    >
                      <p>{title}</p>
                    </CustomButton>
                  </div>
                );
              })}
            </MsgShip>
            <CustomButton
              style={{ width: '100%' }}
              full={false}
              loading={loading[q.id]}
              type="submit"
              onClick={() => handleSubmit(q.id)}
              disabled={!formFields[q.id]}
              disableda
            >
              Responder
            </CustomButton>
            <br />
            {q.history.length ? (
              <ToogleWrapper
                q={q}
                title={`Perguntas anteriores de ${q.from.nickname}`}
              >
                {q.history
                  .slice(0, 6)
                  .map(({ text, answer, id, date_created }) => (
                    <DropDownList key={id}>
                      <div>
                        <IconWrapper
                          name="chatIcon"
                          fill={theme.primary}
                          size={16}
                        />
                        <p>{text}</p>
                        <p className="time">{timeAgo(date_created)}</p>
                        <FaHistory size={12} />
                      </div>
                      <div>
                        <FaHistory size={12} />
                        <p className="time">{timeAgo(answer.date_created)}</p>
                        <p>{answer.text}</p>
                        <IconWrapper
                          name="chatIcon"
                          fill={theme.primary}
                          size={16}
                        />
                      </div>
                    </DropDownList>
                  ))}
              </ToogleWrapper>
            ) : null}
          </Card>
        ))}
      </Content>
      <FastAsnwersList>
        <h3>Respostas rápidas</h3>
        {fastAnswers.map(fa => (
          <Box key={fa.title} title={fa.title} answer={fa.answer} />
        ))}
      </FastAsnwersList>
    </Container>
  );
}
