export const makePlural = (num = 0, word) => {
  if (typeof word !== 'string') return '';

  if (num <= 1) return word;

  if (word.endsWith('l')) return `${word.slice(0, -1)}is`;

  if (word.endsWith('r')) return `${word}es`;

  return `${word}s`;
};
