/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { DateRangeWrapped, Select, ListScroll } from '../../componets';
import { formatLong, useScroll } from '../../utils';
import { LoadData } from './hooks';
import { Container, Content, List, ListContainer, Order } from './styles';

export const orderOptions = [
  {
    value: '',
    label: 'all',
  },
  {
    value: 'in',
    label: 'Entrada',
  },
  {
    value: 'out',
    label: 'Saida',
  },
  {
    value: 'balance',
    label: 'Balanço',
  },
];

const type = {
  in: 'Entrada',

  out: 'Saida',

  balance: 'Balanço',
};

export default function StockOrder({ match }) {
  const [query, setQuery] = useState({
    id: match.params.id,
  });

  const [data, setData, info, loading] = LoadData(
    `internal-order?stock_id=${match?.params?.id}`,
    query
  );

  const handleChangeQuery = fieldValue => {
    setData([]);
    setQuery({
      ...query,
      ...fieldValue,
      page: 1,
    });
  };

  const handleChangeType = ({ value }) => {
    handleChangeQuery({ type: value });
  };

  const handleChangeDate = event => {
    const { value } = event.target;
    handleChangeQuery(value);
  };

  const shouldBeUpdated = () =>
    info.hasNextPage && !loading && query.page !== info.nextPage && data.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <>
      <Container>
        <Content>
          <div>
            <h4>Logs</h4>

            <Select
              primary
              name="type"
              onChange={handleChangeType}
              isLoading={loading}
              options={orderOptions}
              placeholder="Tipo"
            />

            <DateRangeWrapped
              primary
              name="date"
              placeholder="Selecione uma data"
              onChange={handleChangeDate}
              months={1}
            />
          </div>
        </Content>
        <ListContainer>
          <ListScroll loading={loading}>
            {data?.map(d => (
              <List key={d.id}>
                <Order>
                  <div>
                    Quantidade
                    <p>{d.quantity}</p>
                  </div>
                  <div>
                    Tipo
                    <p>{type[d.type]}</p>
                  </div>
                  <div>
                    Data
                    <p>{formatLong(d.createdAt)}</p>
                  </div>
                </Order>
              </List>
            ))}
          </ListScroll>
        </ListContainer>
      </Container>
    </>
  );
}
