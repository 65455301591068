import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  FormInput,
  CustomButton,
  ToogleWrapper,
  ButtonModalYN,
} from '../../componets';

import { Container, Content, FormContainer, CheckBoxContainer } from './styles';

import { LoadData } from './hooks';
import { removeEmptyFields } from '../../utils';

export default function Operator() {
  const [authRoutes, operators, loading, handleSubmit] = LoadData();

  return (
    <Container>
      <Content>
        <div>
          <h4>Operadores</h4>
        </div>
      </Content>
      <FormContainer>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            auth_routes: [],
          }}
          validationSchema={Yup.object({
            first_name: Yup.string().required(),
            last_name: Yup.string(),
            email: Yup.string()
              .email()
              .required(),
            password: Yup.string()
              .min(6)
              .required(),
            auth_routes: Yup.array(),
          })}
          onSubmit={data => handleSubmit('post', data)}
        >
          <Form>
            <FormInput inverted name="first_name" label="Nome" />
            <FormInput inverted name="last_name" label="Sobrenome" />
            <FormInput
              inverted
              name="email"
              type="email"
              label="Seu endereço de e-mail"
            />

            <FormInput
              inverted
              type="password"
              name="password"
              label="Sua senha"
            />
            <CheckBoxContainer>
              {Object.values(authRoutes).map(route => (
                <label key={route.routerId}>
                  <Field
                    type="checkbox"
                    name="auth_routes"
                    value={route.routerId}
                  />
                  {route.name}
                </label>
              ))}
            </CheckBoxContainer>

            <section>
              <div />
              <CustomButton loading={loading} type="submit">
                Criar
              </CustomButton>
            </section>
          </Form>
        </Formik>
        {operators.map(operator => (
          <ToogleWrapper key={operator._id} title={operator.first_name}>
            <Formik
              initialValues={{
                first_name: operator.first_name,
                last_name: operator.last_name,
                email: operator.email,
                auth_routes: operator.auth_routes,
                password: '',
              }}
              validationSchema={Yup.object({
                first_name: Yup.string(),
                last_name: Yup.string(),
                email: Yup.string().email(),
                password: Yup.string().min(6),
                auth_routes: Yup.array(),
              })}
              onSubmit={data =>
                handleSubmit('put', removeEmptyFields(data), operator._id)
              }
            >
              <Form>
                <FormInput inverted name="first_name" label="Nome" />
                <FormInput inverted name="last_name" label="Sobrenome" />
                <FormInput
                  inverted
                  name="email"
                  type="email"
                  label="Seu endereço de e-mail"
                />

                <FormInput
                  inverted
                  type="password"
                  name="password"
                  label="Sua senha"
                />
                <CheckBoxContainer>
                  {Object.values(authRoutes).map(route => (
                    <label key={route.routerId}>
                      <Field
                        type="checkbox"
                        name="auth_routes"
                        value={route.routerId}
                      />
                      {route.name}
                    </label>
                  ))}
                </CheckBoxContainer>

                <section>
                  <ButtonModalYN
                    title="Operador"
                    body="Excluir operador?"
                    onAccept={() => handleSubmit('delete', '', operator._id)}
                  >
                    Deletar
                  </ButtonModalYN>

                  <CustomButton loading={loading} type="submit">
                    Atualizar
                  </CustomButton>
                </section>
              </Form>
            </Formik>
          </ToogleWrapper>
        ))}
      </FormContainer>
    </Container>
  );
}
