/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Notifications from '../Notifications';
import { selectAccount } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';
import { Container, Content, Profile, Menu } from './styles';
import { sideBarRoutes } from '../../routes';
import { IconWrapper } from '..';

export default function Header({ match }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { profile, account } = useSelector(state => state.user);

  const path = sideBarRoutes[match.url.split('/')[1]];

  const nickname = account?.nickname ?? '';

  const handleSelectAccount = accountId => {
    dispatch(selectAccount(accountId));
    setOpen(false);
  };

  const handleQuit = () => {
    dispatch(signOut());
    setOpen(false);
  };

  return (
    <Container>
      <Content>
        <nav>
          <p to="/dashboard">{path?.text}</p>
        </nav>

        <aside>
          <Notifications />
          <Profile>
            <div onClick={() => setOpen(!open)}>
              <strong>{profile && profile.first_name}</strong>
              <small>{nickname}</small>
              <button type="button">
                <IconWrapper size={15} name="chevronDownIcon" />
              </button>
            </div>

            <Popup
              open={open}
              onClose={() => setOpen(false)}
              trigger={<span />}
              on="click"
              position="bottom right"
              contentStyle={{ padding: '0px', border: 'none' }}
              arrow={false}
              offsetY={10}
            >
              <Menu>
                {profile?.accounts?.map(acc => (
                  <button
                    onClick={() => handleSelectAccount(acc._id)}
                    type="button"
                    key={acc._id}
                  >
                    {acc.nickname}
                  </button>
                ))}
                <button onClick={() => handleQuit()} type="button">
                  Sair
                </button>
              </Menu>
            </Popup>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

Header.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
