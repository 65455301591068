import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  width: fit-content;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
`;

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;

  & > p {
    color: ${theme.text};
    text-align: center;
    line-height: 20px;
    padding: 50px;
  }

  ul {
    width: 100%;
    margin-bottom: 30px;

    li {
      margin-top: 30px;
      display: flex;
      align-items: center;
      @media (max-width: ${theme.mobile}) {
        flex-direction: column;
      }
      p {
        flex: 2;
      }
      div {
        flex: 1.2;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-left: 15px;

        div {
          width: 50%;
          margin: 0 5px;
        }
      }
    }
  }
`;
