import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 5px;
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    align-items: center;

    h4 {
      font-weight: 600;
      color: ${theme.white};
    }

    @media (max-width: ${theme.mobile}) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`;

export const List = styled.li`
  width: 100%;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid ${transparentize(0.6, theme.lightgrey)};
`;

export const ListContainer = styled.div``;

export const ListHeader = styled.div`
  label {
    color: ${theme.lightgrey};
    font-size: 10px;
  }
`;

export const Order = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;

  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const Thumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: 110px;
  }
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;

  small {
    font-size: 0.7em;
    strong {
      color: ${theme.primary};
    }
  }

  p {
    color: ${theme.primary};
  }
  span {
    color: ${theme.lightgrey};
    font-size: 12px;
  }
`;
