import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  max-width: ${theme.desktop};
  padding: 20px;
  width: 90%;
  @media (max-width: ${theme.mobile}) {
    justify-content: center;
    width: 100%;
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin: 20px;
  p {
    font-weight: lighter;
    text-align: justify;
    padding: 10px 5px;
    max-width: 600px;
  }

  @media (max-width: ${theme.mobile}) {
    img {
      display: none;
      height: 350px;
    }
  }
`;

export const AuthContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  border-bottom: 50px black solid;
  border-radius: 8px;
  position: relative;
  color: ${theme.white};
  background: ${theme.gradient};
  box-shadow: 20px 20px 5px 0px ${lighten(0.15, theme.lightgrey)};

  h1 {
    margin: 20px 0;
  }

  small {
    font-weight: lighter;
  }
`;

export const ButtonsBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  a {
    color: ${theme.white};
    font-size: 14px;
    opacity: 0.8;
    margin-right: 20px;

    &:hover {
      opacity: 1;
    }
  }
`;
