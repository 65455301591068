import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyled, FaSpinnerAnimated } from './styles';
import { theme } from '../../utils/colors';

const Button = ({ children, loading, textColor, ...rest }) => {
  return (
    <>
      <ButtonStyled disabled={loading} textcolor={textColor} {...rest}>
        {loading ? (
          <FaSpinnerAnimated textcolor={textColor} size={14} />
        ) : (
          <span>{children}</span>
        )}
      </ButtonStyled>
    </>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

Button.defaultProps = {
  loading: false,
  type: 'submit',
  color: theme.primary,
  textColor: theme.white,
};

export default Button;
