/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Loader, IconWrapper } from '..';
import { theme } from '../../utils';
import NumberInfo from '../NumberInfo';

function Trigger(info) {
  return (
    <div>
      <div>
        <span>
          <IconWrapper size={15} name={info.icon} />
        </span>
        <small>{info.description}</small>
      </div>
      <p>{info.value}</p>
      <NumberInfo details={info.details} value={info.value} />
    </div>
  );
}

const Card = ({ title, content, loading }) => {
  return (
    <Container>
      {!loading ? (
        <Content>
          <h3>{title}</h3>

          {content?.map(info => (
            <Trigger key={info.description} {...info} />
          ))}
        </Content>
      ) : (
        <Loader loading={loading} color={theme.white} />
      )}
    </Container>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

Card.defaultProps = {
  loading: false,
};

export default Card;
