import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
`;

export const Content = styled.section`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 5px;
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    align-items: center;

    h4 {
      font-weight: 600;
      color: ${theme.white};
    }

    @media (max-width: ${theme.mobile}) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`;

export const SummaryContainer = styled.div`
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  padding: 10px;

  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const SummarySeller = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 50px 20px;
  margin: 20px 0;
  min-width: 50%;

  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

export const ListContainer = styled.div``;

export const ListHeader = styled.div`
  width: 100%;
  div {
    width: fit-content;
    label {
      color: ${theme.lightgrey};
      font-size: 10px;
    }
  }

  section {
    display: grid;
    grid-template-columns: 1fr 2fr repeat(3, minmax(50px, 1fr)) 0.5fr;
    width: 100%;
    align-items: center;
    p {
      text-align: center;
      color: ${theme.text};
      font-size: 16px;
    }

    @media (max-width: ${theme.mobile}) {
      display: none;
    }
  }
`;

export const List = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  padding: 15px 0;
  border-bottom: 1px solid ${transparentize(0.8, theme.black)};
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr repeat(3, minmax(50px, 1fr)) 0.5fr;
  width: 100%;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
  }

  p {
    padding: 5px;
    color: ${theme.text};
    margin-top: 10px;

    strong {
      display: none;
    }
  }

  p:nth-child(1n + 2) {
    text-align: center;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        display: inline;
      }
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
`;
