/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container, BlockingResult, Table, ActionsContainer } from './styles';
import {
  CustomButton,
  Checkbox,
  SearchUser,
  Select,
  ExportCSV,
  Heatmap,
  Loader,
} from '../../componets';

import api from '../../services/api';
import { AccountOptions, IdToNickname, useScroll } from '../../utils';

export default function Blacklist() {
  const { account } = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [blacklist, setBlacklist] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [dataMap, setDataMap] = useState([]);
  const [user, setUser] = useState();
  const [info, setInfo] = useState({});
  const [blocked, setBlocked] = useState({
    questions: false,
    order: false,
  });

  const [query, setQuery] = useState({
    ids: account?.id,
  });

  const idToNickname = IdToNickname();
  const options = AccountOptions();

  async function loadBlacklist() {
    setLoading(true);
    const res = await api.get('blacklist', { params: query });
    const { docs, total, ...rest } = res;
    setBlacklist(d => [...d, ...docs]);
    setDataMap(total);
    setInfo(rest);
    setLoading(false);
  }

  async function loadBlacklistDownload() {
    const res = await api.get('blacklist/download', { params: query });
    return res.length ? res : [];
  }

  useEffect(() => {
    loadBlacklist();
  }, [account, query]);

  const handleBlocked = event => {
    const { name, checked } = event.target;
    setBlocked({
      ...blocked,
      [name]: checked,
    });
  };

  const handleAccounts = e => {
    setAccounts(e?.map(({ value }) => value) ?? []);
  };

  const handleSearch = async ({ nickname }) => {
    try {
      setLoading(true);
      const seller = await api.get(`blacklist/${nickname}`);
      setUser(seller);
      setLoading(false);
    } catch (e) {
      setUser(null);
      setLoading(false);
      toast.error('Usuário não encontrado', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleChangeQuery = fieldValue => {
    setBlacklist([]);
    setQuery({
      ...query,
      ...fieldValue,
      page: 1,
    });
  };

  const handleChangeAccount = value => {
    let ids = account?.id;
    if (value?.length)
      ids = value?.reduce((acc, { value: id }) => `${acc},${id}`, '') ?? '';
    handleChangeQuery({ ids });
  };

  const handleSubmit = async () => {
    if (accounts.length === 0) {
      toast.info('Selecione pelo menos uma conta', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const users = accounts.map(accountId => ({
      accountId,
      blockedQuestion: blocked.questions,
      blockedBuy: blocked.order,
      id: user?.id,
    }));

    try {
      setLoading(true);
      await api.post(`blacklist`, { users });

      setUser(null);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setTimeout(loadBlacklist, 2000);
    }
  };

  const handleDelete = async ({ id, accountId }) => {
    try {
      const users = [
        {
          accountId,
          id,
          blockedQuestion: false,
          blockedBuy: false,
        },
      ];
      setLoading(true);
      await api.post(`blacklist`, { users });
      setBlacklist([]);
    } catch (e) {
      toast.error('Erro ao desbloquear usuário', {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      loadBlacklist();
    }
  };

  const shouldBeUpdated = () =>
    info.hasNextPage &&
    !loading &&
    query.page !== info.nextPage &&
    blacklist.length;

  const handleOnYReachEnd = () => {
    if (shouldBeUpdated()) {
      setQuery({
        ...query,
        page: info.nextPage,
      });
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <Container>
      <SearchUser
        loading={loading}
        handleSearch={handleSearch}
        title="Bloquear"
        user={user}
      >
        {user && (
          <BlockingResult>
            <label>
              <Select
                closeMenuOnSelect={false}
                placeholder="Contas"
                isMulti
                onChange={handleAccounts}
                options={options}
                allowSelectAll
              />
            </label>
            <label>
              <Checkbox
                name="questions"
                checked={blocked?.questions}
                onChange={handleBlocked}
              />
              <span style={{ marginLeft: 8 }}>Bloquear para perguntas</span>
            </label>
            <label>
              <Checkbox
                name="order"
                checked={blocked.order}
                onChange={handleBlocked}
              />
              <span style={{ marginLeft: 8 }}>Bloquear para compras</span>
            </label>
            <CustomButton loading={loading} onClick={handleSubmit}>
              Bloquear
            </CustomButton>
          </BlockingResult>
        )}
      </SearchUser>
      <ActionsContainer>
        <Select
          closeMenuOnSelect={false}
          placeholder="Conta"
          isMulti
          onChange={handleChangeAccount}
          options={options}
          allowSelectAll
        />

        <section>
          <CustomButton small inverted onClick={() => setShowMap(!showMap)}>
            Mapa
          </CustomButton>
          <ExportCSV data={loadBlacklistDownload} filename="blacklist" />
        </section>
      </ActionsContainer>
      {showMap && <Heatmap data={dataMap} />}

      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nickname</th>
            <th>Contas</th>
            <th>Bloqueados {info?.totalDocs} </th>
          </tr>
        </thead>
        <tbody>
          {<Loader full loading={loading} />}
          {blacklist?.map(blockeds => (
            <tr key={blockeds._id}>
              <td>
                <strong>ID</strong>
                {blockeds.id}
              </td>
              <td>
                <strong>Nickname</strong>
                {blockeds.nickname}
              </td>
              <td>
                <strong>Contas</strong>
                <CustomButton small inverted readonly>
                  {idToNickname[blockeds?.accountId]}
                </CustomButton>
              </td>
              <td>
                <CustomButton
                  disabled={loading}
                  loading={loading}
                  onClick={() => handleDelete({ ...blockeds })}
                >
                  Desbloquear
                </CustomButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
