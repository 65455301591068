import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const TitleComponent = ({ hasUnread }) => {
  return (
    <Helmet>
      <title>{`${hasUnread > 0 ? `(${hasUnread})` : ''} OZIX`}</title>
    </Helmet>
  );
};

TitleComponent.propTypes = {
  hasUnread: PropTypes.number,
};

TitleComponent.defaultProps = {
  hasUnread: 0,
};

export default React.memo(TitleComponent);
