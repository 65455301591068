import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  loading: false,
  account: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }
      case '@user/UPDATE_PROFILE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.profile = action.payload.profile;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.account = null;
        break;
      }
      case '@user/UPDATE_PROFILE_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@user/ADD_ACCOUNT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/ADD_ACCOUNT_SUCCESS': {
        const { account } = action.payload;
        draft.loading = false;
        draft.account = account;
        draft.profile.accounts.push(account);
        break;
      }
      case '@user/ADD_ACCOUNT_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_ACCOUNT': {
        const { account } = action.payload;
        const index = draft.profile.accounts.findIndex(
          acc => account._id === acc._id
        );
        draft.loading = false;
        if (index >= 0) {
          draft.profile.accounts.splice(index, 1, account);
        }
        break;
      }
      case '@user/SELECT_ACCOUNT': {
        draft.loading = false;
        draft.account = draft.profile.accounts.find(
          acc => acc._id === action.payload.accountId && !acc.token_expired
        );
        break;
      }
      case '@user/DELETE_ACCOUNT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/DELETE_ACCOUNT_SUCCESS': {
        const { accountId } = action.payload;
        draft.loading = false;
        draft.account = draft.account._id === accountId ? null : draft.account;
        draft.profile.accounts = draft.profile.accounts.filter(
          acc => acc._id !== accountId
        );
        break;
      }
      case '@user/DELETE_ACCOUNT_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
