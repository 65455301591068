import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { Container } from './styles';
import { formatMoney, formatMoneyWithSuffix } from '../../utils';

const CurveAbc = ({ data, info, selectData }) => {
  const step = Math.ceil(info.count / 8);
  const tickValues = Array(Math.min(8, info.count ?? 0))
    .fill(0)
    .map((_, i) => step * i);

  return (
    <>
      {!!data?.length && (
        <Container>
          <ResponsiveLine
            data={[
              ...data?.map(d => {
                return {
                  ...d,
                  data:
                    d.id === 'a'
                      ? [{ id: '0', x: 0, y: 0 }, ...d.data]
                      : d.data,
                };
              }),
            ]}
            enablePoints={false}
            margin={{ top: 50, right: 5, bottom: 50, left: 60 }}
            colors={{ scheme: 'set2' }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto' }}
            curve="monotoneX"
            enableArea
            yFormat={e => formatMoney(e)}
            enableGridX={false}
            axisBottom={{
              tickValues,
              legendOffset: 36,
              legendPosition: 'middle',
              legend: 'n° items',
              orient: 'bottom',
            }}
            axisLeft={{
              orient: 'left',
              format: value => formatMoneyWithSuffix(value),
            }}
            onClick={selectData}
            areaOpacity={0.75}
            useMesh
            legends={[
              {
                onClick: selectData,
                itemTextColor: 'black',
                anchor: 'top-left',
                direction: 'row',
                translateX: 10,
                translateY: 10,
                itemWidth: 50,
                itemHeight: 20,
                itemOpacity: 0.8,
                symbolSize: 16,
                itemDirection: 'left-to-right',
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Container>
      )}
    </>
  );
};

CurveAbc.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  selectData: PropTypes.func,
  info: PropTypes.shape({
    count: PropTypes.number,
    total: PropTypes.number,
  }),
};

CurveAbc.defaultProps = {
  data: [],
  info: {},
  selectData: () => {},
};

export default CurveAbc;
