import styled, { css, keyframes } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten, transparentize } from 'polished';
import { theme } from '../../utils/colors';

const pulse = keyframes`
   0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;

  div {
    position: absolute;

    span {
      position: relative;
      text-align: center;
      right: -12px;
      top: -6px;
      color: ${lighten(0.05, theme.white)};
      padding: 3px 6px;
      font-size: 10px;
      background: ${lighten(0.05, theme.primary)};
      border-radius: 50%;
      ${props =>
        props.hasUnread &&
        css`
          &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            content: '';
            border-radius: 50%;
            animation: ${pulse} 1s ease infinite;
            border: 4px double ${lighten(0.05, theme.primary)};
          }
        `}
    }
  }
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 260px;
  left: calc(50% - 130px);
  top: calc(100% + 30px);
  background: ${transparentize(0.4, theme.black)};
  border-radius: 4px;
  padding: 15px 5px;
  display: ${props => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${transparentize(0.4, theme.black)};
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 260px;
`;

export const Notification = styled.button`
  color: ${theme.white};
  background: ${({ type }) => transparentize(0.9, theme[type])} ;
  border: none;
  width: 100%;
  text-align: start;
  padding: 5px 10px;
  margin: 1px 0;

  &:hover {
    background: ${({ type }) => transparentize(0.8, theme[type])};
  }};

  & + div {
    margin-top: 15px;
    padding-top: 15px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
  }

  p:nth-child(3) {
    color: ${transparentize(0.4, theme.white)};
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  time {
    display: block;
    font-size: 12px;
    opacity: 0.6;
    margin-bottom: 5px;
  }

  button {
    font-size: 12px;
    border: 0;
    background: none;
    color: ${lighten(0.05, theme.primary)};
  }
`;
