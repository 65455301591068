import styled, { css, keyframes } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

const pulse = keyframes`
   0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
`;

export const DropDownContainer = styled.div`
  bottom: 20px;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  flex: 1;
  align-items: flex-end;
  align-content: flex-end;
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 400px;
`;

export const Menu = styled.section`
  min-width: 320px;
  display: flex;
  padding: 5px;
  flex-direction: column;
  background: ${theme.background};
`;

export const Button = styled.button`
  position: relative;
  cursor: pointer;
  padding: 5px;
  border: none;
  background: none;
  z-index: 9999999;

  div {
    position: absolute;

    span {
      position: relative;
      text-align: center;
      right: -12px;
      top: -6px;
      color: ${lighten(0.05, theme.text)};
      padding: 3px 6px;
      font-size: 10px;
      background: ${lighten(0.05, theme.notification)};
      border-radius: 50%;
      ${props =>
        props.hasUnread &&
        css`
          &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            content: '';
            border-radius: 50%;
            animation: ${pulse} 1s ease infinite;
            border: 4px double ${lighten(0.05, theme.notification)};
          }
        `}
    }
  }
`;
