import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  width: 100%;
  height: 175px;
  padding: 10px;
  color: ${theme.text};
  border-radius: 5px;

  small {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin: 5px 0 0 5px;
    }
  }
  h3 {
    flex: 1;
    display: flex;
    align-items: center;
  }

  div {
    width: 100%;
    color: ${theme.primary};
    display: grid;
    grid-template-columns: 15px 1fr;
    align-items: center;
    grid-gap: 5px;
  }

  @media (max-width: ${theme.mobile}) {
    max-width: 100%;
  }
`;
