import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;

  .form {
    display: flex;
    flex-direction: column;
    width: 45%;
    @media (max-width: ${theme.mobile}) {
      width: 100%;
    }

    & > div:last-child {
      display: flex;
      justify-content: space-between;

      button {
        width: 45%;
      }
    }

    & > div {
      p {
        color: ${theme.text};
        font-weight: bold;
        margin-bottom: 5px;
      }
      margin-top: 20px;
      input {
        color: ${theme.text};
        padding: 10px 10px 10px 5px;
        font-weight: bold;
        width: 100%;
      }
      textarea {
        color: ${theme.text};
        padding: 10px 10px 10px 5px;
        width: 100%;
        min-height: 100px;
      }
    }
  }
`;

export const AnswersStyled = styled.table`
  width: 100%;

  tr {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-gap: 10px;

    td,
    th {
      align-items: center;
      text-align: left;
      padding: 8px;
      overflow: auto;
      svg {
        color: ${theme.primary};
        transition: transform 0.4s;
        margin: 0 5px;
        &:hover {
          cursor: pointer;
          transform: scale(1.2);
        }
      }
    }
  }

  thead {
    tr {
      margin-top: 50px;
      border-bottom: 1px solid ${theme.lightgrey};
    }
  }
  tr:nth-child(even) {
    background-color: ${theme.background};
  }
`;
