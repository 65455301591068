import {
  formatDistance,
  formatRelative,
  isSameDay,
  isToday,
  isYesterday,
  format,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const timeAgo = (date, relativeDate) => {
  const result = formatDistance(
    new Date(date),
    relativeDate ? new Date(relativeDate) : new Date(),
    { locale: ptBR, addSuffix: true }
  );

  return result.replace(/\w/, result[0].toUpperCase());
};

const removeHourAndCapitalize = result => {
  return result
    .split('às')[0]
    .replace(/(.)/, result[0].toUpperCase())
    .trimStart();
};
const formatRelativeIfisTodayOrYesterday = date =>
  isToday(date, new Date()) || isYesterday(date, new Date())
    ? removeHourAndCapitalize(
        formatRelative(date, new Date(), {
          locale: ptBR,
          addSuffix: true,
        })
      )
    : format(date, 'dd/LL/yyyy');

export const timeRelativeToday = (date, relativeDate) => {
  if (!date || !relativeDate) return '';

  const DatesObject = [new Date(date), new Date(relativeDate)];

  if (isSameDay(...DatesObject)) {
    return formatRelativeIfisTodayOrYesterday(DatesObject[0]);
  }

  return DatesObject.map(formatRelativeIfisTodayOrYesterday).join(' a ');
};

export const formatLong = (date, ft = 'PPPp') => {
  return date && format(new Date(date), ft, { locale: ptBR });
};
