import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  flex-wrap: wrap;
  padding: 20px;

  .form {
    display: flex;
    flex-direction: column;
    width: 45%;
    @media (max-width: ${theme.mobile}) {
      width: 100%;
    }

    & > div {
      p {
        color: ${theme.text};
        font-weight: bold;
        margin-bottom: 5px;
      }
      margin-top: 20px;
      input {
        color: ${theme.text};
        padding: 10px 10px 10px 5px;
        width: 100%;
      }
      textarea {
        color: ${theme.text};
        padding: 10px 10px 10px 5px;
        width: 100%;
        min-height: 100px;
      }
    }
  }
`;

export const Info = styled.div`
  width: 45%;
  padding: 50px;
  @media (max-width: ${theme.mobile}) {
    width: 100%;
    padding: 20px;
  }

  p {
    margin: 15px 0;
    color: ${theme.text};
  }
`;
