import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { theme } from '../../utils';
import { Container } from './styles';
import { IconWrapper } from '..';

const CardInfo = ({ icon, value, description, details }) => {
  return (
    <Container>
      <div>
        <IconWrapper fill={theme.primary} name={icon} size={15} />
        <p>{description}</p>
      </div>
      <h3>{value}</h3>
      <small>
        <strong>{details.value}</strong>
        <Popup
          position="top center"
          on="hover"
          trigger={
            <span>
              <IconWrapper
                fill={theme.primary}
                name="questionCircleRegular"
                size={15}
              />
            </span>
          }
        >
          <div>
            <p>{details.tooltip}</p>
          </div>
        </Popup>
      </small>
    </Container>
  );
};

CardInfo.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  compared: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.shape({
    tooltip: PropTypes.string,
    value: PropTypes.string,
  }),
};

CardInfo.defaultProps = {
  icon: 'FaQuestion',
  compared: null,
  value: 0,
  details: {},
};

export default CardInfo;
