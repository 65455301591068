import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import api from '../../services/api';
import { formatNumber } from '../../utils';
import { FaSpinnerAnimated, TextArea, ItemStyled } from './styles';

const Answer = ({ entity: { title } }) => (
  <p
    style={{ textAlign: 'center', minWidth: '150px', padding: '5px' }}
  >{`${title}`}</p>
);

Answer.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Item = ({ entity: { secure_thumbnail, price, title } }) => {
  return (
    <ItemStyled>
      <img src={secure_thumbnail} alt="" />
      <p>{title}</p>
      <strong>R$ {formatNumber(price)}</strong>
    </ItemStyled>
  );
};

Item.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Loading = () => <FaSpinnerAnimated />;

const AutoCompleteInput = ({
  name,
  value,
  onChange,
  data,
  handlesubmit,
  ...rest
}) => {
  const [textareaRef, setTextareaRef] = useState();
  const [timeouts, setTimeouts] = useState();

  useEffect(() => {
    if (textareaRef && value) textareaRef.focus();
  }, [textareaRef, value]);

  const handleChange = async (text, resolve) => {
    setTimeouts(
      setTimeout(async () => {
        const res = await api.get('items?limit=20&status=active', {
          params: { text },
        });
        resolve(res?.docs);
      }, 500)
    );
    clearTimeout(timeouts);
  };

  return (
    <>
      <ReactTextareaAutocomplete
        {...rest}
        textAreaComponent={{ component: TextArea, ref: 'inputRef' }}
        loadingComponent={Loading}
        movePopupAsYouType
        name={name}
        innerRef={ref => {
          setTextareaRef(ref);
        }}
        onKeyDown={({ ctrlKey, key }) => {
          if (ctrlKey && key === 'Enter') handlesubmit(name);
        }}
        onChange={onChange}
        value={value}
        dropdownClassName="dropdownAutoComplete"
        containerStyle={{
          width: '100%',
          position: 'relative',
        }}
        dropdownStyle={{
          maxHeight: '150px',
          overflowY: 'auto',
          zIndex: 1,
          marginLeft: '2px',
        }}
        loaderStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
        minChar={0}
        trigger={{
          '@': {
            dataProvider: token => {
              const re = new RegExp(`${token}`, 'i');
              return data.filter(da => da.title.match(re));
            },
            component: Answer,
            output: answer => answer.answer,
          },
          '#': {
            allowWhitespace: true,
            dataProvider: token => {
              return new Promise(resolve => handleChange(token, resolve));
            },
            component: Item,
            output: item => item.permalink,
          },
        }}
      />
    </>
  );
};

AutoCompleteInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  handlesubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AutoCompleteInput.defaultProps = {
  value: '',
};

export default AutoCompleteInput;
