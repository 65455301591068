import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utils/colors';
import { ModalStyled, StyledPopup } from './styles';
import { CustomButton, HeaderModal } from '..';

const ButtonModalYN = ({ title, body, onAccept, children, button, small }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  return (
    <CustomButton small={small} inverted onClick={() => setOpen(true)}>
      {children}
      <StyledPopup open={open} onClose={onClose} nested modal>
        <ModalStyled>
          <HeaderModal title={title} onClose={onClose} />
          <section>{body}</section>
          {button && (
            <div>
              <CustomButton inverted onClick={onClose} type="button">
                Cancelar
              </CustomButton>
              <CustomButton
                onClick={() => onAccept()}
                color={theme.positive}
                type="button"
              >
                Ok
              </CustomButton>
            </div>
          )}
        </ModalStyled>
      </StyledPopup>
    </CustomButton>
  );
};

ButtonModalYN.propTypes = {
  title: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  button: PropTypes.bool,
  small: PropTypes.bool,
};

ButtonModalYN.defaultProps = {
  button: true,
  small: false,
};

export default ButtonModalYN;
