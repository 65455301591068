import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { signUpRequest } from '../../store/modules/auth/actions';

import { FormInput, CustomButton, TextTransition, Logo } from '../../componets';
import {
  Container,
  ContainerDescription,
  AuthContainer,
  ButtonsBarContainer,
} from './styles';

import dashboard from '../../assets/Dashboard-auth.svg';

export default function SignUp() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ first_name, last_name, email, password }) {
    dispatch(signUpRequest(first_name, last_name, email, password));
  }

  return (
    <>
      <Container>
        <ContainerDescription>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </div>
          <TextTransition
            words={[
              'Controle suas vendas',
              'Perguntas',
              'Múltiplas Contas',
              'Feito por vendedores',
            ]}
          />
          <img alt="" src={dashboard} />
        </ContainerDescription>
        <AuthContainer>
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              first_name: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Required'),
              last_name: Yup.string()
                .max(20, 'Must be 20 characters or less')
                .required('Required'),
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
              password: Yup.string()
                .min(6)
                .required('Required'),
            })}
            onSubmit={handleSubmit}
          >
            <Form style={{ width: '100%' }}>
              <FormInput name="first_name" type="text" label="Nome" />
              <FormInput name="last_name" type="text" label="Sobrenome" />
              <FormInput name="email" type="email" label="E-mail" />
              <FormInput name="password" type="password" label="Senha" />

              <ButtonsBarContainer>
                <Link to="/">Já tenho uma conta</Link>
                <CustomButton loading={loading} type="submit" isSignIn>
                  Cadastrar
                </CustomButton>
              </ButtonsBarContainer>
            </Form>
          </Formik>
        </AuthContainer>
      </Container>
    </>
  );
}
