export const formatNumber = num =>
  num &&
  Number(num)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const formatMoney = num =>
  `R$ ${Number(num ?? -0.001)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;

export const formatPercent = num => `${(Number(num ?? 0) * 100).toFixed(2)} %`;

export const formatMoneyWithSuffix = num =>
  Math.abs(num) >= 1000
    ? `R$ ${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)} mil`
    : `R$ ${Number(num ?? -0.001)
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
