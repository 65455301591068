import styled from 'styled-components';
import { theme } from '../../utils';

export const Container = styled.div`
  position: relative;
  width: inherit;
  height: -webkit-fill-available;

  @media (max-width: ${theme.mobile}) {
    display: none;
  }
`;

export const Console = styled.div`
  font-size: 1.5em;
  width: 100%;
  display: block;
  position: absolute;
  color: ${({ color }) => color};
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  & .hidden {
    opacity: 0;
  }

  & .underscore {
    display: inline-block;
    position: relative;
    top: -0.14em;
    left: 10px;
  }
`;
