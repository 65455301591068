import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: ${transparentize(0.9, theme.black)};
  left: 0;
`;
