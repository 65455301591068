import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer, Scroll } from './styles';
import { Loader } from '..';

const ListScroll = ({ loading, children, header }) => {
  return (
    <>
      <ListContainer>
        {children.length !== 0 ? (
          <>
            {header}
            <Scroll>{children}</Scroll>
          </>
        ) : (
          <h3>Lista vazia</h3>
        )}
        {loading && (
          <div>
            <Loader loading={loading} />
            <i>Carregando ...</i>
          </div>
        )}
      </ListContainer>
    </>
  );
};

ListScroll.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  loading: PropTypes.bool,
};

ListScroll.defaultProps = {
  loading: false,
  header: null,
};

export default ListScroll;
