/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import {
  GroupContainer,
  FormInputContainer,
  FormInputLabel,
  MaskedInputStyled,
  ErrorContainer,
} from './styles';

const Input = ({ label, mask, inverted, ...props }) => {
  const [field, meta] = useField(props);
  const { name } = props;
  const { value, error, touched } = meta;

  return (
    <GroupContainer {...props} {...field}>
      {mask.length ? (
        <MaskedInputStyled
          inverted={inverted}
          guide={false}
          {...field}
          {...props}
          mask={mask}
        />
      ) : (
        <FormInputContainer inverted={inverted} {...field} {...props} />
      )}
      {label && (
        <FormInputLabel
          className={value?.length ? 'shrink' : ''}
          value={value}
          htmlFor={name}
          inverted={inverted}
        >
          {label}
        </FormInputLabel>
      )}
      {touched && error ? <ErrorContainer>{error}</ErrorContainer> : null}
    </GroupContainer>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.array,
  inverted: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  mask: [],
  inverted: false,
};

export default Input;
