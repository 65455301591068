import { ReactComponent as indicatorsSidebarIcon } from '../assets/indicatorsSidebarIcon.svg';
import { ReactComponent as questionsSidebarIcon } from '../assets/questionsSidebarIcon.svg';
import { ReactComponent as concurrentSidebarIcon } from '../assets/concurrentSidebarIcon.svg';
import { ReactComponent as messageSidebarIcon } from '../assets/messageSidebarIcon.svg';
import { ReactComponent as padlockSidebarIcon } from '../assets/padlockSidebarIcon.svg';
import { ReactComponent as userSidebarIcon } from '../assets/userSidebarIcon.svg';
import { ReactComponent as settingsSidebarIcon } from '../assets/settingsSidebarIcon.svg';
import { ReactComponent as bellIcon } from '../assets/bellIcon.svg';
import { ReactComponent as chevronDownIcon } from '../assets/chevronDownIcon.svg';
import { ReactComponent as calendarIcon } from '../assets/calendarIcon.svg';
import { ReactComponent as closeCalendarIcon } from '../assets/closeCalendarIcon.svg';
import { ReactComponent as shoppingBagICon } from '../assets/shoppingBagICon.svg';
import { ReactComponent as chatIcon } from '../assets/chatIcon.svg';
import { ReactComponent as invoiceIcon } from '../assets/invoiceIcon.svg';
import { ReactComponent as dollarTagIcon } from '../assets/dollarTagIcon.svg';
import { ReactComponent as claimIcon } from '../assets/claimIcon.svg';
import { ReactComponent as clockIcon } from '../assets/clockIcon.svg';
import { ReactComponent as deliveryIcon } from '../assets/deliveryIcon.svg';
import { ReactComponent as mixerIcon } from '../assets/mixerIcon.svg';
import { ReactComponent as defaultIcon } from '../assets/defaultIcon.svg';
import { ReactComponent as trashIcon } from '../assets/trashIcon.svg';
import { ReactComponent as lockIcon } from '../assets/lockIcon.svg';
import { ReactComponent as truckIcon } from '../assets/truckIcon.svg';
import { ReactComponent as searchIcon } from '../assets/searchIcon.svg';
import { ReactComponent as salesSideBarIcon } from '../assets/salesSideBarIcon.svg';
import { ReactComponent as stockSidebarIcon } from '../assets/stockSidebarIcon.svg';
import { ReactComponent as settingsIcon } from '../assets/settingsIcon.svg';
import { ReactComponent as questionCircleRegular } from '../assets/questionCircleRegular.svg';
import { ReactComponent as copyIcon } from '../assets/copyIcon.svg';
import { ReactComponent as warning } from '../assets/warning.svg';

export const helperIcon = name =>
  ({
    salesSideBarIcon,
    searchIcon,
    trashIcon,
    lockIcon,
    truckIcon,
    indicatorsSidebarIcon,
    questionsSidebarIcon,
    concurrentSidebarIcon,
    messageSidebarIcon,
    padlockSidebarIcon,
    userSidebarIcon,
    settingsSidebarIcon,
    chevronDownIcon,
    calendarIcon,
    bellIcon,
    closeCalendarIcon,
    dollarTagIcon,
    invoiceIcon,
    chatIcon,
    shoppingBagICon,
    claimIcon,
    clockIcon,
    deliveryIcon,
    mixerIcon,
    stockSidebarIcon,
    settingsIcon,
    questionCircleRegular,
    copyIcon,
    warning,
  }[name] || defaultIcon);
