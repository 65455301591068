import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ChatItem } from 'react-chat-elements';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { FaFacebookMessenger, FaTimes } from 'react-icons/fa';
import { DropDownContainer, Scroll, Menu, Button } from './styles';
import { timeAgo, theme } from '../../utils';
import { api, history } from '../../services';
import { selectAccount } from '../../store/modules/user/actions';

const ChatWidget = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState({ page: 1 });
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  async function loadMessages() {
    try {
      setLoading(true);
      const { docs, ...info } = await api.get('inbox', {
        params: query,
      });
      setPagination(info);
      setMessages([
        ...messages,
        ...docs?.map(message => ({
          avatar: message?.order?.item?.secure_thumbnail,
          alt: 'Messagem',
          title: message?.order?.item?.title,
          subtitle: message.text,
          dateString: timeAgo(message.date_received),
          unread: message.unread,
          id: message.packs,
          ...message,
        })),
      ]);
    } catch (error) {
      toast.error(error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!loading) loadMessages();
  }, [query]);

  const hasUnread = useMemo(() => messages?.filter(m => m.unread)?.length, [
    messages,
  ]);

  const handleOnYReachEnd = async scroll => {
    scroll.scrollTop -= 5;
    if (pagination.hasNextPage && !loading) {
      setQuery({
        ...query,
        page: pagination.nextPage,
      });
    }
  };
  return (
    <DropDownContainer>
      <Button
        hasUnread={hasUnread}
        type="button"
        onClick={() => setOpen(!open)}
      >
        {open ? (
          <FaTimes name="FaTimes" size={30} color={theme.primary} />
        ) : (
          <>
            {!!hasUnread && (
              <div>
                <span>{`${hasUnread}${
                  hasUnread === messages.length ? '+' : ''
                }`}</span>
              </div>
            )}
            <FaFacebookMessenger
              name="FaFacebookMessenger"
              size={30}
              color={theme.primary}
            />
          </>
        )}
      </Button>
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        trigger={<span />}
        on="click"
        position="top right"
        contentStyle={{ padding: '0px', border: 'none' }}
        arrow={false}
        offsetX={-100}
        offsetY={50}
      >
        <Menu>
          <Scroll onYReachEnd={scroll => handleOnYReachEnd(scroll)}>
            {messages?.map(message => (
              <ChatItem
                key={message.id}
                onClick={() => {
                  dispatch(selectAccount(message.account?._id));
                  setOpen(false);
                  history.push(`/message/chat`, {
                    packs: message.packs,
                  });
                }}
                {...message}
              />
            ))}
          </Scroll>
        </Menu>
      </Popup>
    </DropDownContainer>
  );
};

ChatWidget.propTypes = {};

ChatWidget.defaultProps = {};

export default ChatWidget;
