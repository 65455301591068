import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  background: ${theme.white};
  border-left: 1px solid ${theme.background};
  padding: 0 20px;
  position: sticky;
  z-index: 1;
  top: 0;
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${theme.mobile}) {
    justify-content: flex-end;
  }

  nav {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      margin-right: 20px;
    }
  }

  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid ${theme.background};

  div {
    text-align: left;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      display: block;
      color: ${theme.text};
    }
    small {
      font-size: 10px;
      font-weight: 100;
    }

    & > button {
      display: flex;
      margin-top: 2px;
      font-size: 12px;
      color: ${lighten(0.3, theme.text)};
      border: none;
      background: transparent;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
`;

export const Menu = styled.section`
  min-width: 200px;
  display: flex;
  padding: 5px;
  flex-direction: column;
  background: ${theme.background};

  button {
    cursor: pointer;
    padding: 5px;
    line-height: 20px;
    color: ${lighten(0.3, theme.text)};
    border: none;
    background: transparent;
    width: 100%;
    text-align: right;
    & + button {
      border-top: 1px solid ${theme.lightgrey};
    }
    &:hover {
      color: ${theme.primary};
    }

    :last-child {
      margin-top: 30px;
      background: ${lighten(0.02, theme.background)};
      border: none;
      color: ${lighten(0.0, theme.negative)};
    }
  }
`;
