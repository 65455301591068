import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../utils/colors';
import { ModalStyled, StyledPopup } from './styles';
import { CustomButton, HeaderModal } from '..';

const ModalYN = ({ title, body, onClose, open, onAccept }) => {
  return (
    <>
      <StyledPopup open={open} onClose={onClose} nested modal>
        <ModalStyled>
          <HeaderModal title={title} onClose={onClose} />
          <section>{body}</section>
          <div>
            <CustomButton inverted onClick={onClose} type="button">
              Cancelar
            </CustomButton>
            <CustomButton
              onClick={() => onAccept()}
              color={theme.positive}
              type="button"
            >
              Ok
            </CustomButton>
          </div>
        </ModalStyled>
      </StyledPopup>
    </>
  );
};

ModalYN.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ModalYN;
