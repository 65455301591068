import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const ContainerSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${theme.mobile}) {
    width: 100%;
  }
`;

export const ContainerInput = styled.div`
  position: relative;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 5px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-gap: 10px;
  align-items: center;

  h4 {
    color: ${theme.white};
  }

  @media (max-width: ${theme.mobile}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const ContainerResult = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: ${theme.white};
  border-radius: 5px;
  padding: 20px;

  h4 {
    font-weight: 600;
  }
  #user {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }

  #info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin: 20px 0;

    @media (max-width: ${theme.mobile}) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }
`;
