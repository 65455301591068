/* eslint-disable react/destructuring-assignment */
// /* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

const AsyncSelectWrapper = ({ renderOption, loadOptions, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const Option = useMemo(
    () => propsOption => {
      return (
        <components.Option {...propsOption}>
          {renderOption && renderOption(propsOption.data)}
        </components.Option>
      );
    },
    [renderOption]
  );

  const [defOpt, setDefOpt] = useState([]);
  const handleLoadOptions = s => {
    return loadOptions(s).then(a => {
      setDefOpt(a);
      return a;
    });
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') setInputValue(newValue);
  };

  return (
    <>
      <AsyncSelect
        noOptionsMessage={() => 'Sem resultados'}
        closeMenuOnSelect={false}
        isClearable={false}
        components={{ Option }}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        loadOptions={handleLoadOptions}
        defaultOptions={defOpt}
        isMulti
        cacheOptions
        {...props}
      />
    </>
  );
};

AsyncSelectWrapper.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  renderOption: PropTypes.oneOfType([PropTypes.func]),
};

AsyncSelectWrapper.defaultProps = {
  renderOption: false,
};

export default AsyncSelectWrapper;
