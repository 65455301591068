import produce from 'immer';

const INITIAL_STATE = {
  questions: null,
};

export default function notification(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@notification/QUESTIONS_UPDATE': {
        draft.questions = action.payload.date;
        break;
      }

      default:
    }
  });
}
