import { lighten } from 'polished';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.section`
  display: flex;
  align-items: center;
  background: ${({ background }) => background};
  border-radius: 5px;
  padding: 2px;
  margin: 0 5px;
  color: ${({ isPositive }) =>
    lighten(0.05, isPositive ? theme.success : theme.negative)};
  justify-content: ${({ justify }) => justify};

  p {
    font-size: ${({ size }) => size}px;
    white-space: nowrap;
  }
`;
