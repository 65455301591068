/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { ListData } from './styles';
import { formatMoney, makePlural } from '../../utils';
import CustomButton from '../CustomButton';

const InfoItem = ({ item, buttonsText }) => {
  const renderButtonWithString = str =>
    str && (
      <CustomButton inverted key={str} small disabled>
        {str}
      </CustomButton>
    );

  return (
    <ListData>
      <a target="_blank" rel="noopener noreferrer" href={item?.permalink}>
        <h4>{item.title}</h4>
      </a>
      <div>
        <small>
          {item.available_quantity !== undefined
            ? `${item.available_quantity} ${makePlural(
                item.available_quantity,
                'disponível'
              )} | `
            : `${item.quantity} ${makePlural(item.quantity, 'Unidade')} | `}
          <strong>{formatMoney(item.unit_price)}</strong>
        </small>
      </div>

      <div>
        <small>Publicação {item.id}</small>
      </div>
      <div>{buttonsText.map(text => renderButtonWithString(text))}</div>
    </ListData>
  );
};

InfoItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    unit_price: PropTypes.number,
    quantity: PropTypes.number,
    title: PropTypes.string,
    permalink: PropTypes.string,
    available_quantity: PropTypes.number,
  }).isRequired,
  buttonsText: PropTypes.arrayOf(PropTypes.string),
};

InfoItem.defaultProps = {
  buttonsText: [],
};

export default InfoItem;
