import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormInput, CustomButton, Logo, TextTransition } from '../../componets';
import {
  Container,
  ContainerDescription,
  AuthContainer,
  FooterContainer,
  ButtonsBarContainer,
} from './styles';

import dashboard from '../../assets/Dashboard-auth.svg';

import { signInRequest, signFailure } from '../../store/modules/auth/actions';

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }
  useEffect(() => {
    dispatch(signFailure());
  }, []);

  return (
    <>
      <Container>
        <ContainerDescription>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </div>
          <TextTransition
            words={[
              'Controle suas vendas',
              'Perguntas',
              'Múltiplas Contas',
              'Feito por vendedores',
            ]}
          />

          <img alt="" src={dashboard} />
        </ContainerDescription>
        <AuthContainer>
          <h1>Login</h1>
          <p>Bem-vindo</p>
          <small>Informe os dados de cadastro</small>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email()
                .required(),
              password: Yup.string()
                .min(6)
                .required(),
            })}
            onSubmit={handleSubmit}
          >
            <Form style={{ width: '100%' }}>
              <FormInput name="email" type="email" label="Seu e-mail" />
              <FormInput name="password" type="password" label="Sua senha" />

              <ButtonsBarContainer>
                <Link to="/forgotpassword">Esqueceu sua senha?</Link>
                <CustomButton loading={loading} type="submit" isSignIn>
                  Acessar
                </CustomButton>
              </ButtonsBarContainer>
            </Form>
          </Formik>
          <FooterContainer>
            <>
              <div>
                <h5>Crie sua conta</h5>
                <small>Bem-vindo !</small>
              </div>
              <CustomButton to="/register" type="submit">
                Criar conta
              </CustomButton>
            </>
          </FooterContainer>
        </AuthContainer>
      </Container>
    </>
  );
}
