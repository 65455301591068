import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  background: ${theme.white};
  border-radius: 5px;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & > div:nth-child(1n + 2) {
    margin-top: 20px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  background: ${theme.white};
  border-radius: 5px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
`;

export const Field = styled.div`
  padding: 20px;
  flex: 1;
  width: 100%;

  & > div {
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    padding: 20px;
    color: ${theme.text};
    font-size: 15px;
    border-radius: 5px;
    min-height: 150px;
    resize: none;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.primary};
  background: ${lighten(0.2, theme.primary)};
  padding: 20px 10px;
  border-radius: 5px;
  border-left: 4px solid ${theme.primary};
  span {
    margin-left: 10px;
  }
  svg {
    height: auto;
    width: 30px;
  }
`;
