import { transparentize } from 'polished';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  width: 100%;
  grid-area: row1;
`;

export const List = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  padding: 15px 0;
  border-bottom: 1px solid ${transparentize(0.8, theme.black)};
`;

export const ListHeader = styled.div`
  width: 100%;
  font-weight: bold;
  text-transform: capitalize;
  div {
    width: fit-content;
    label {
      color: ${theme.lightgrey};
      font-size: 10px;
    }
  }

  section {
    display: grid;
    grid-template-columns: 0.5fr 2fr repeat(4, minmax(50px, 1fr));
    width: 100%;
    align-items: center;
    p {
      text-align: center;
      color: ${theme.text};
      font-size: 16px;
    }

    @media (max-width: ${theme.mobile}) {
      display: none;
    }
  }
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 0.5fr 2fr repeat(4, minmax(50px, 1fr));
  width: 100%;
  align-items: center;

  div:nth-child(1n + 3) {
    text-align: center;
  }

  div {
    padding: 5px;
    color: ${theme.text};
    margin-top: 10px;

    img {
      width: 100px;
      height: 100px;
    }

    strong {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        display: inline;
      }
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
`;
