import styled, { css, keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import { theme } from '../../utils/colors';

const buttonStyles = css`
  background-color: ${theme.primary};
  color: ${theme.white};
  border: none;

  &:active {
    background-color: ${theme.white};
    color: ${theme.primary};
    border: 1px solid ${theme.primary};
  }
`;

const invertedButtonStyles = css`
  background-color: ${theme.white};
  color: ${theme.primary};
  border: 1px solid ${theme.primary};

  &:active {
    background-color: ${theme.primary};
    color: ${theme.white};
    border: none;
  }
`;

const isSignIn = css`
  background-color: ${theme.black};
  color: ${theme.white};

  &:active {
    border: none;
  }
`;

const isTransparent = css`
  background-color: transparent;
  color: ${theme.white};
  border: 1px solid ${theme.white};

  &:active {
    border: none;
  }
`;

const isSmall = css`
  min-width: 0px;
  width: auto;
  letter-spacing: 0px;
  padding: 2px;
  font-size: 10px;
  line-height: unset;
  height: fit-content;
  border-radius: 3px;
  @media (max-width: ${theme.mobile}) {
    font-size: 9px;
  }
`;

const getButtonStyles = props => {
  if (props.isSignIn) {
    return isSignIn;
  }
  if (props.isTransparent) {
    return isTransparent;
  }

  return props.inverted ? invertedButtonStyles : buttonStyles;
};

const getButtonSize = ({ small }) => small && isSmall;

const pulse = keyframes`
   0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.05);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
`;

export const CustomButtonContainer = styled.button`
  white-space: nowrap;
  position: relative;
  border-radius: 6px;
  min-width: 150px;
  width: fit-content;
  height: 35px;
  letter-spacing: 0.5px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
  }

 &:hover {
    opacity: 0.9;
  }

  
  ${getButtonStyles}
  ${getButtonSize}

  ${props =>
    props.alert &&
    css`
      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        content: '';
        border-radius: 5%;
        animation: ${pulse} 1s ease infinite;
        box-shadow: 0 0 5px 1px ${theme.primary};
      }
    `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FaSpinnerAnimated = styled(FaSpinner)`
  .inverted {
    color: ${({ inverted }) => (inverted ? theme.primary : theme.white)};
  }
  animation: ${rotate} 2s linear infinite;
`;
