import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomButton } from '..';

const Button = ({ seller_id }) => {
  const { profile } = useSelector(state => state.user);

  const idToNickname =
    profile?.accounts?.reduce(
      (prev, current) => ({ ...prev, [current.id]: current.nickname }),
      {}
    ) ?? {};
  const nickname = idToNickname[seller_id];
  return (
    <>
      {nickname && (
        <CustomButton inverted key={idToNickname[seller_id]} small disabled>
          {nickname}
        </CustomButton>
      )}
    </>
  );
};

Button.propTypes = {
  seller_id: PropTypes.number,
};

Button.defaultProps = {
  seller_id: '',
};

export default Button;
