export const QUESTIONS_STATUS = {
  UNANSWERED: 'UNANSWERED',
  ANSWERED: 'ANSWERED',
  CLOSED: 'CLOSED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  DELETED: 'DELETED',
};

export const ORDER_STATUS = {
  ready_to_ship: 'Pronto para enviar',
  shipped: 'Despachado',
  delivered: 'Entregue',
  not_delivered: 'Não entregue',
  paid: 'Cobrado',
  cancelled: 'Cancelado',
};

export const STOCK = {
  in: 'Entrada',
  out: 'Saída',
  balance: 'Balanço',
};
