import React from 'react';
import Proptypes from 'prop-types';
import { StyledBurger } from './styles';

const Burger = ({ open }) => {
  return (
    <StyledBurger open={open}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: Proptypes.bool.isRequired,
};
export default Burger;
