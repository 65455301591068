/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import { Container, Content, AnswersStyled } from './styles';
import api from '../../services/api';
import { Button } from '../../componets';
import { theme } from '../../utils';

const createOption = label => ({
  label,
  value: label,
});
const components = {
  DropdownIndicator: null,
};

export default function QuestionsAnswer() {
  const [fastAnswers, setFastAnswers] = useState([]);
  const [formFields, setFormFields] = useState({
    title: '',
    answer: '',
    keywords: [],
  });
  const [idEditing, setIdEditing] = useState();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let ignore = false;
    api.get('fast_answers').then(async res => {
      if (!ignore) {
        setFastAnswers(res.fast_answers);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const handleChange = event => {
    const { value, name } = event.target;

    setFormFields({
      ...formFields,
      [name]: value === null ? [] : value,
    });
  };

  const handleKeyDown = event => {
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        if (formFields.keywords.find(({ label }) => label === inputValue))
          return;

        setFormFields({
          ...formFields,
          keywords: [...formFields.keywords, createOption(inputValue)],
        });
        setInputValue('');
        break;
      default:
    }
  };

  const handleSubmit = () => {
    const formatedData = {
      ...formFields,
      keywords: formFields.keywords.map(({ value }) => value),
    };

    api
      .post('fast_answers', formatedData)
      .then(res => {
        setFastAnswers([...fastAnswers, res]);
        setFormFields({
          title: '',
          answer: '',
          keywords: [],
        });
        toast.success('Opoeração realizada com sucesso');
      })
      .catch(() => {
        toast.error('Preencha todos os campos');
      });
  };

  const handleSubmitEdit = () => {
    const formatedData = {
      ...formFields,
      keywords: formFields.keywords.map(({ value }) => value),
    };

    api
      .put(`fast_answers/${idEditing}`, formatedData)
      .then(res => {
        setFastAnswers(fastAnswers.map(fa => (fa._id === res._id ? res : fa)));
        setFormFields({
          title: '',
          answer: '',
          keywords: [],
        });
        setIdEditing(null);
        toast.success('Opoeração realizada com sucesso');
      })
      .catch(() => {
        toast.error('Preencha todos os campos');
      });
  };

  const handleEdit = ({ keywords, ...fields }, id) => {
    setFormFields({
      ...fields,
      keywords: keywords.map(value => createOption(value)),
    });
    setIdEditing(id);
  };

  const handleDelete = id => {
    api.delete(`fast_answers/${id}`).then(() => {
      setFastAnswers(fastAnswers.filter(({ _id }) => _id !== id));
    });
  };
  return (
    <>
      <Container>
        <Content>
          <h2>Respostas rápidas</h2>
          <div className="form" onSubmit={e => e.preventDefault()}>
            <div>
              <p>Título</p>
              <input
                value={formFields.title}
                onChange={handleChange}
                type="text"
                name="title"
              />
            </div>
            <div>
              <p>Resposta</p>
              <TextareaAutosize
                value={formFields.answer}
                onChange={handleChange}
                type="text"
                name="answer"
              />
            </div>
            <div>
              <p>Palavras Chaves</p>
              <CreatableSelect
                name="keywords"
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={value =>
                  handleChange({
                    target: { value, name: 'keywords' },
                  })
                }
                onInputChange={value => setInputValue(value)}
                onKeyDown={handleKeyDown}
                placeholder="Digite algo e pressione enter ..."
                value={formFields.keywords}
              />
            </div>

            {idEditing ? (
              <div>
                <Button
                  onClick={() => {
                    setFormFields({
                      title: '',
                      answer: '',
                      keywords: [],
                    });
                    setIdEditing(null);
                  }}
                  type="button"
                  color={theme.negative}
                >
                  Cancelar
                </Button>
                <Button onClick={handleSubmitEdit} type="button">
                  Editar
                </Button>
              </div>
            ) : (
              <Button onClick={handleSubmit} type="button">
                Salvar
              </Button>
            )}
          </div>

          <AnswersStyled>
            <thead>
              <tr>
                <th>Título</th>
                <th>Resposta</th>
                <th>Palavras Chaves</th>
              </tr>
            </thead>
            <tbody>
              {fastAnswers.map(({ _id, title, answer, keywords }) => (
                <tr key={_id}>
                  <td>{title}</td>
                  <td>{answer}</td>
                  <td>{keywords.map(v => `${v} `)}</td>
                  <td>
                    <FaEdit
                      size={20}
                      onClick={() =>
                        handleEdit({ title, answer, keywords }, _id)
                      }
                    />
                    <FaTrashAlt size={20} onClick={() => handleDelete(_id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </AnswersStyled>
        </Content>
      </Container>
    </>
  );
}
