/* eslint-disable import/no-cycle */
import axios from 'axios';
import { signOut } from '../store/modules/auth/actions';
import { store } from '../store';

const api = axios.create({
  baseURL: '/api/',
});

api.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (
      error?.response?.data?.error === 'Token jwt invalid' &&
      store.getState()?.auth?.signed
    ) {
      store.dispatch(signOut());
    }

    return Promise.reject(error);
  }
);

export default api;
