import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import * as locales from 'react-date-range/dist/locale';
import { Container, Wrapper } from './styles';
import { useOnClickOutside } from '../../utils';
import { Input, Button, IconWrapper } from '..';

const DateRangeWrapped = ({
  name,
  onChange,
  placeholder,
  months,
  minDate,
  primary,
}) => {
  const [open, setOpen] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [value, setValue] = useState('');
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const node = useRef();
  useOnClickOutside(node, () => {
    setCustomDate(false);
    setOpen(false);
  });

  const handleTrigger = () => {
    onChange({ target: { name, value } });
    setOpen(false);
    setCustomDate(false);
  };

  const handleOnchange = item => {
    const { startDate, endDate } = item.selection;
    setState([item.selection]);

    setValue({ startDate, endDate });
  };

  const handleOnClick = item => {
    const { startDate, endDate } = item.selection;
    setState([item.selection]);
    setValue({ startDate, endDate });
    onChange({ target: { name, value: { startDate, endDate } } });
    setOpen(false);
    setCustomDate(false);
  };

  const handleClean = async () => {
    setValue('');
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    onChange({ target: { name, value: '' } });
  };

  const fabricDates = (label, from, to = 0) => ({
    label,
    selection: {
      startDate: moment()
        .subtract(from, 'days')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .subtract(to, 'days')
        .endOf('day')
        .toDate(),
      key: 'selection',
    },
  });
  const values = [
    fabricDates('Hoje', 0),
    fabricDates('Ontem', 1, 1),
    fabricDates('Últimos 7 dias', 7),
    fabricDates('Últimos 15 dias', 15),
    fabricDates('Este mês', new Date().getDate() - 1),
  ];

  return (
    <>
      <Container ref={node}>
        <Input
          primary={primary}
          readOnly
          placeholder={placeholder}
          onClick={() => setOpen(!open)}
          icon="calendarIcon"
          value={
            value &&
            `${moment(value.startDate).format('DD/MM/YYYY')} - ${moment(
              value.endDate
            ).format('DD/MM/YYYY')}`
          }
        />
        {value && (
          <IconWrapper
            name="closeCalendarIcon"
            size={15}
            className="close"
            onClick={handleClean}
          />
        )}
        {open && (
          <Wrapper>
            {!customDate ? (
              <ul>
                {values.map(v => (
                  <li key={v.label}>
                    <div
                      role="button"
                      tabIndex={0}
                      aria-hidden="true"
                      onClick={() => {
                        handleOnClick(v);
                      }}
                    >
                      {v.label}
                    </div>
                  </li>
                ))}

                <li>
                  <div
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                    onClick={() => setCustomDate(true)}
                  >
                    Escolher data
                  </div>
                </li>
              </ul>
            ) : (
              <>
                <DateRange
                  className="picker"
                  editableDateInputs
                  onChange={handleOnchange}
                  ranges={state}
                  months={months}
                  direction="horizontal"
                  locale={locales.ptBR}
                  maxDate={new Date()}
                  minDate={new Date(minDate || moment().subtract(5, 'year'))}
                  shownDate={moment()
                    .subtract(months - 1, 'months')
                    .toDate()}
                  dateDisplayFormat="dd-MM-yyyy"
                />
                <Button onClick={handleTrigger} type="button">
                  Aplicar
                </Button>
              </>
            )}
          </Wrapper>
        )}
      </Container>
    </>
  );
};

DateRangeWrapped.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  primary: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  months: PropTypes.oneOf([1, 2]),
};

DateRangeWrapped.defaultProps = {
  placeholder: 'Select',
  minDate: null,
  months: 2,
  primary: false,
};

export default DateRangeWrapped;
