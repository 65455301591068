import { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export const LoadData = () => {
  const [loading, setLoading] = useState(false);

  const promiseOptions = async inputValue => {
    try {
      setLoading(true);
      const { docs } = await api.get('items', {
        params: { populate: 'true', text: inputValue },
      });
      return docs.map(item => ({ label: item.id, value: item.id, item }));
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return [];
    } finally {
      setLoading(false);
    }
  };

  return [promiseOptions, loading];
};
