import styled from 'styled-components';
import { theme } from '../../../utils/colors';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
  background: ${theme.background};
`;
