/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Hidden = styled.div`
  width: 100px;
  height: 100px;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Heatmap = ({ data }) => {
  useEffect(() => {
    const chart = am4core.create('chartdiv', am4maps.MapChart);
    chart.geodata = am4geodata_brazilLow;
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = [...data];
    polygonSeries.useGeodata = true;

    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color(theme.white),
      max: am4core.color(theme.error).brighten(0),
    });

    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = 'right';
    heatLegend.width = am4core.percent(25);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = Math.max(...data.map(d => d?.value));
    heatLegend.valign = 'bottom';

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color(theme.primary);
    polygonTemplate.events.on('hit', ev => {
      ev.target.series.chart.zoomToMapObject(ev.target);
    });
  }, [data]);
  return (
    <Container>
      <div id="chartdiv" />
      <Hidden />
    </Container>
  );
};

export default Heatmap;
