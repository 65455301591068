import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import 'moment/locale/pt';

import './config/ReactotronConfig';

import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

import Routes from './routes';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <DndProvider backend={Backend}>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
          </Router>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
