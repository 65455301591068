import styled from 'styled-components';
import Popup from 'reactjs-popup';

export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 5px;
    padding: 0px;
    width: unset;
  }
`;

export const ModalStyled = styled.div`
  section {
    padding: 10px;
    margin: 30px 0;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  button {
    margin: 5px;
  }
`;
