import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { api, history } from '../../services';

import { FormInput, CustomButton, TextTransition, Logo } from '../../componets';
import {
  Container,
  ContainerDescription,
  AuthContainer,
  ButtonsBarContainer,
} from './styles';

import dashboard from '../../assets/Dashboard-auth.svg';

export default function ForgotPassword() {
  const [tokenIsValid, setTokenIsValid] = useState(false);
  async function handleSubmit({ email }) {
    try {
      await api.post('forgotpassword', { email });
      toast.info('Verifique seu email');
    } catch (error) {
      toast.error(error.response?.data?.error);
    }
  }

  async function handleChangePassword({ password }) {
    try {
      await api.put(`forgotpassword/${tokenIsValid}`, { password });
      toast.success('Senha alterada com sucesso');
      history.push('/');
    } catch (error) {
      toast.error(error.response?.data?.error);
    }
  }

  async function checkToken() {
    try {
      const [, token] =
        history.location.pathname.match(/(?:\/forgotpassword\/)(.+)/) ?? [];
      if (token) {
        await api.put(`forgotpassword/${token}`);
        setTokenIsValid(token);
      }
    } catch (error) {
      setTokenIsValid(false);
      toast.error(error.response?.data?.error);
    }
  }

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <Container>
        <ContainerDescription>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </div>
          <TextTransition
            words={[
              'Controle suas vendas',
              'Perguntas',
              'Múltiplas Contas',
              'Feito por vendedores',
            ]}
          />

          <img alt="" src={dashboard} />
        </ContainerDescription>
        <AuthContainer>
          {/* <h1>Login</h1>
          <p>Bem-vindo</p>
          <small>Informe os dados de cadastro</small> */}
          {tokenIsValid ? (
            <Formik
              initialValues={{
                password: '',
              }}
              validationSchema={Yup.object({
                password: Yup.string()
                  .min(6)
                  .required('Insira a senha'),
              })}
              onSubmit={handleChangePassword}
            >
              <Form style={{ width: '100%' }}>
                <FormInput
                  name="password"
                  type="password"
                  label="Sua nova senha"
                />
                <ButtonsBarContainer>
                  <Link to="/">início</Link>
                  <CustomButton type="submit">Enviar</CustomButton>
                </ButtonsBarContainer>
              </Form>
            </Formik>
          ) : (
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Required'),
              })}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormInput name="email" type="email" label="Seu e-mail" />

                <ButtonsBarContainer>
                  <Link to="/">início</Link>
                  <CustomButton type="submit" isSignIn>
                    Enviar
                  </CustomButton>
                </ButtonsBarContainer>
              </Form>
            </Formik>
          )}
        </AuthContainer>
      </Container>
    </>
  );
}
