import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 10px repeat(3, minmax(250px, 1fr)) 1fr minmax(
      300px,
      600px
    );
  grid-gap: 10px;
  align-items: center;
  background: ${theme.white};
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: ${theme.background};
  height: 100px;
  padding: 15px;
  border-top-left-radius: 5px;

  h4 {
    color: ${theme.primary};
  }

  section {
    display: grid;
    align-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    div {
      color: ${lighten(0.2, theme.text)};
      font-size: 14px;
    }
  }
  @media (max-width: ${theme.mobile}) {
    grid-row: 1;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template:
    'left right'
    'row0 row0'
    'row1 row1' / minmax(200px, 750px) minmax(200px, 750px);
  grid-gap: 20px;
  padding: 20px;
  width: 100%;
  justify-content: center;
  @media (max-width: ${theme.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.section`
  grid-area: left;
  width: 100%;
  @media (max-width: ${theme.mobile}) {
    margin-right: 0px;
    grid-template-columns: 1fr;
    max-width: unset;
  }
`;

export const ColumnRight = styled.section`
  grid-area: right;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  align-content: flex-start;
  width: 100%;
  @media (max-width: ${theme.mobile}) {
    grid-template-columns: 1fr;
  }
`;
