import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  background: ${theme.background};
  padding: 20px;
  position: relative;
  padding-bottom: 50px;
  @media (max-width: ${theme.mobile}) {
    padding: 0 10px 50px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & > div:last-child {
    margin-top: 20px;
  }
`;

export const DropDownContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 400px;
`;

export const Header = styled.div`
  position: relative;
  background: ${theme.card};
  min-height: 150px;
  margin-bottom: 20px;

  section {
    padding: 20px 20px;

    & > div {
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }
    }
    & > p {
      color: ${theme.text};
      padding: 5px 0;
      font-weight: bold;
    }
  }
`;
