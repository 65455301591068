import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Console } from './styles';
import { TextHandler } from './TextHandler';
import { theme } from '../../utils';

const TextElement = ({ words, colors = ['#fff'] }) => {
  const [color, changeTextColor] = useState('#fff');
  const [text, changeText] = useState('');
  const [underscore, setUnderscore] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setUnderscore(!underscore);
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, [underscore]);

  const textHandler = useCallback(() => {
    new TextHandler(
      words,
      {
        changeTextColor,
        changeText,
      },
      colors
    ).init();
  }, [colors, words]);

  useEffect(() => {
    textHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Console color={color}>
        <span>{text}</span>
        <div className={`underscore ${underscore ? ' hidden' : ''}`}>&#95;</div>
      </Console>
    </Container>
  );
};
export default TextElement;

TextElement.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
};

TextElement.defaultProps = {
  colors: [theme.text],
};
