import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const HeaderModal = ({ title, onClose }) => {
  return (
    <Container>
      <h3>
        {title}
        <button type="button" className="close" onClick={onClose}>
          &times;
        </button>
      </h3>
    </Container>
  );
};

HeaderModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

HeaderModal.defaultProps = {
  title: '',
  onClose: null,
};

export default HeaderModal;
