import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';
import { Container, Tooltip, HeaderChart } from './styles';
import { formatMoneyWithSuffix, formatMoney } from '../../utils';
import { RateIcon, CustomButton } from '..';

const LineChart = ({ reference, data }) => {
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    setDataIndex(0);
  }, []);

  const dataSelected = data[dataIndex];
  const dataFormated = dataSelected?.data?.map((values, index) => {
    const points = reference.map((x, i) => ({
      y: values[i] ?? null,
      x,
      tooltip: dataSelected?.tooltip[index][i],
    }));
    return {
      id: index,
      data: points,
    };
  });

  const tickValues = reference?.reduce((previousValue, currentValue, index) => {
    const step = Math.ceil(reference.length / 8);
    if (!(index % step)) previousValue.push(currentValue);
    return previousValue;
  }, []);

  return (
    <>
      <Container>
        <HeaderChart>
          <div>
            <h3>{dataSelected?.value}</h3>
            <RateIcon rate={dataSelected?.details?.rate} size={12} />
          </div>
          {data.map((info, index) => (
            <CustomButton
              key={info.description}
              onClick={() => setDataIndex(index)}
              small
              type="button"
              inverted={index !== dataIndex}
            >
              {info.description}
            </CustomButton>
          ))}
        </HeaderChart>
        {dataFormated && (
          <ResponsiveLine
            data={dataFormated}
            margin={{
              top: 15,
              right: 15,
              bottom: 60,
              left: dataSelected.formatMoney ? 65 : 35,
            }}
            colors={{ scheme: 'set2' }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto' }}
            curve="monotoneX"
            enableArea
            enableGridX={false}
            sliceTooltip={({ slice }) => {
              return (
                <Tooltip>
                  {slice.points
                    .sort((a, b) => a.serieId - b.serieId)
                    .map(point => (
                      <div key={point.id}>
                        <p>{point.data.tooltip.title}</p>
                        <h3
                          style={{
                            color: point.serieColor,
                          }}
                        >
                          {dataSelected.formatMoney
                            ? formatMoney(point.data.tooltip.value)
                            : point.data.tooltip.value}
                        </h3>
                        <p>{point.data.tooltip.description}</p>
                      </div>
                    ))}
                </Tooltip>
              );
            }}
            axisBottom={{
              tickValues,
              orient: 'bottom',
            }}
            axisLeft={{
              orient: 'left',
              format: value =>
                dataSelected.formatMoney ? formatMoneyWithSuffix(value) : value,
            }}
            enablePoints={false}
            useMesh={false}
            enableSlices="x"
          />
        )}
      </Container>
    </>
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  reference: PropTypes.arrayOf(PropTypes.string).isRequired,
  tooltip: PropTypes.arrayOf(PropTypes.array).isRequired,
};

LineChart.defaultProps = {};

export default LineChart;
