import { useEffect, useState, useMemo } from 'react';

import { useSelector } from 'react-redux';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export function useScroll({ onYReachEnd } = { onYReachEnd: () => {} }) {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [yReachEnd, setyReachEnd] = useState(false);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = () => {
    const bodyOffset = document.body.getBoundingClientRect();
    setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
    setLastScrollTop(-bodyOffset.top);
    setyReachEnd(
      (1 - (-bodyOffset.top + bodyOffset.height) / document.body.scrollHeight) *
        100 <
        1
    );
  };

  if (yReachEnd && scrollDirection === 'up') {
    setyReachEnd(false);
    onYReachEnd();
  }

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return {
    yReachEnd,
  };
}

export const IdToNickname = () => {
  return (
    useSelector(state => state.user)?.profile?.accounts?.reduce(
      (prev, current) => ({ ...prev, [current.id]: current.nickname }),
      {}
    ) ?? {}
  );
};

export const AccountOptions = () => {
  return (
    useSelector(state => state.user)?.profile?.accounts?.map(acc => ({
      value: acc.id,
      label: acc.nickname,
    })) ?? []
  );
};
