import React from 'react';
import PropTypes from 'prop-types';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { formatPercent } from '../../utils';
import { Container } from './styles';

const RateIcon = ({ rate, background, size, justify }) => {
  const isPositive = rate >= 0;

  return (
    <Container
      size={size}
      isPositive={isPositive}
      background={background}
      justify={justify}
    >
      {rate !== 0 && (
        <>
          {isPositive ? <FaCaretUp size={size} /> : <FaCaretDown size={size} />}
          <p>{formatPercent(rate)}</p>
        </>
      )}
    </Container>
  );
};

RateIcon.propTypes = {
  rate: PropTypes.number,
  background: PropTypes.string,
  size: PropTypes.number,
  justify: PropTypes.string,
};

RateIcon.defaultProps = {
  background: 'transparent',
  rate: 0,
  size: 14,
  justify: 'center',
};

export default RateIcon;
