/* eslint-disable dot-notation */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CSV from 'csv-js';
import { Container } from './styles';

export default function ReadCSV({ onload }) {
  const [fileName, setFileName] = useState('');

  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = evt => {
      const stringCSV = evt.target.result;

      if (file.name.endsWith('.txt')) {
        onload({ id: stringCSV.match(/\d+/g) });
      } else {
        onload(parseData(stringCSV));
      }
    };
    if (file) {
      reader.readAsBinaryString(file);
      setFileName(file.name);
    }
  };

  return (
    <Container>
      <input
        type="file"
        name="file"
        id="file"
        accept=".csv,.xlsx,.xls,.txt"
        onChange={handleFileUpload}
        className="inputfile"
      />
      <label htmlFor="file">{fileName || 'Importar lista'}</label>
    </Container>
  );
}

function sanitizeCSV(str) {
  return str.replace(/[^\w,\s]/g, '');
}

function parseData(data) {
  const [header, ...lines] = CSV.parse(sanitizeCSV(data));
  return header.reduce(
    (result, col, col_index) => ({
      ...result,
      [col]: lines.filter(removeEmptyLine).map(line => line[col_index]),
    }),
    {}
  );
}

ReadCSV.propTypes = {
  onload: PropTypes.number.isRequired,
};

ReadCSV.defaultProps = {};

function removeEmptyLine(line) {
  return !line.every(v => !v);
}
