export const ADD_TO_COPY = 'copy@ADD_TO_COPY';
export const REMOVE_TO_COPY = 'copy@REMOVE_TO_COPY';
export const EDIT_TO_COPY = 'copy@EDIT_TO_COPY';
export const REQUEST_TO_COPY = 'copy@REQUEST_TO_COPY';
export const SUCCESS_TO_COPY = 'copy@SUCCESS_TO_COPY';
export const FAILURE_TO_COPY = 'copy@FAILURE_TO_COPY';
export const FETCH_ITEMS_REQUEST = 'adverts@FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'adverts@FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'adverts@FETCH_ITEMS_FAILURE';
export const GET_BY_NICKNAME = 'adverts@GET_BY_NICKNAME';
export const GET_BY_KEYWORDS = 'adverts@GET_BY_KEYWORDS';
export const GET_BY_URL = 'adverts@GET_BY_URL';
