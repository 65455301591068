/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FaMapMarkedAlt } from 'react-icons/fa';
import {
  DateRangeWrapped,
  Input,
  Rating,
  Select,
  TextIcon,
  ListScroll,
} from '../../componets';
import api from '../../services/api';
import { formatNumber, useScroll } from '../../utils';
import {
  Container,
  Content,
  List,
  ListContainer,
  Item,
  ListHeader,
  SummaryContainer,
  SummarySeller,
} from './styles';

export default function QuestionsHistory() {
  const [concurrentsOptions, setConcurrentsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seller, setSeller] = useState({});
  const [sales, setSales] = useState([]);
  const [total, setTotal] = useState({});
  const [pagination, setPagination] = useState({});
  const [query, setQuery] = useState({
    text: '',
    startDate: moment()
      .startOf('day')
      .format(),
    endDate: moment()
      .endOf('day')
      .format(),
    page: 1,
  });

  async function loadConcurrents() {
    try {
      setLoading(true);
      const res = await api.get('concurrent');
      setSeller(res[0] || {});
      setConcurrentsOptions(
        res.map(concurrent => ({
          value: concurrent._id,
          label: concurrent.nickname,
          ...concurrent,
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  useEffect(() => {
    loadConcurrents();
  }, []);

  async function loadSales() {
    try {
      setLoading(true);

      if (seller._id) {
        const response = await api.get(`concurrent/target/${seller._id}`, {
          params: query,
        });
        const { docs, ...info } = response.sales;
        setSales([...sales, ...docs]);

        setPagination(info);
        setTotal(response?.total || {});
      }

      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    loadSales();
  }, [seller, query]);

  const handleChange = event => {
    const { value, name } = event.target;

    setSales([]);
    if (name === 'date') {
      const date = {
        startDate: value.startDate,
        endDate: value.endDate,
      };

      setQuery({
        ...query,
        ...date,
        page: 1,
      });
    } else {
      setQuery({
        ...query,
        [name]: value,
        page: 1,
      });
    }
  };

  const handleOnYReachEnd = () => {
    if (pagination.hasNextPage && !loading) {
      setQuery({
        ...query,
        page: pagination.nextPage,
      });
    }
  };

  useScroll({ onYReachEnd: handleOnYReachEnd });

  return (
    <>
      <Container>
        <Content>
          <div>
            <h4>Selecione o Concorrente</h4>
            <div />
            <Select
              primary
              name="concurrent"
              onChange={concurrent => {
                setSales([]);
                setQuery({
                  ...query,
                  page: 1,
                });
                setSeller(concurrent);
              }}
              isLoading={loading}
              options={concurrentsOptions}
              blurInputOnSelect
              placeholder="Concorrente"
            />

            <DateRangeWrapped
              primary
              name="date"
              placeholder="Selecione uma data"
              onChange={handleChange}
              minDate={seller.createdAt}
              months={1}
            />
          </div>
        </Content>
        <SummaryContainer>
          <p>
            {moment(query.startDate).isSame(query.endDate, 'day')
              ? query.startDate && moment(query.startDate).format('DD/MM/YYYY')
              : `De ${moment(query.startDate).format('DD/MM/YYYY')} a
            ${moment(query.endDate).format('DD/MM/YYYY')}`}
          </p>
          <section>
            <div>
              <h3>{seller.nickname}</h3>
              <Rating
                rate={parseInt(
                  seller.seller_reputation?.level_id?.match(/\d|[a-z]+/g)[0],
                  10
                )}
                color={
                  seller.seller_reputation?.level_id?.match(/\d|[a-z]+/g)[1]
                }
                label="Reputação do vendedor"
                text={seller?.seller_reputation?.power_seller_status}
              />
            </div>
            <div />
            <div>
              <FaMapMarkedAlt />
              <p>{`${seller?.address?.city}${seller?.address?.state?.match(
                /-.*/
              )}`}</p>
            </div>
            <div>
              <p>
                Seguindo desde{' '}
                <strong>{moment(seller.createdAt).format('DD/MM/YYYY')}</strong>
              </p>
            </div>
          </section>

          <SummarySeller>
            <section>
              <TextIcon icon="shoppingBagICon" text="Unidades vendidas" />
              <h4>{total.quantity || 0}</h4>
            </section>
            <section>
              <TextIcon icon="dollarTagIcon" text="Faturamento" />
              <h4>R$ {formatNumber(total.billing || 0)}</h4>
            </section>
            <section>
              <TextIcon icon="invoiceIcon" text="Ticket médio" />
              <h4>R$ {formatNumber(total.mean || 0)}</h4>
            </section>
          </SummarySeller>
        </SummaryContainer>

        {!!sales.length && (
          <ListContainer>
            <ListScroll
              loading={loading}
              header={
                <ListHeader>
                  <div>
                    <label htmlFor="text">Burcar em minhas vendas</label>
                    <Input
                      name="text"
                      onChange={handleChange}
                      value={query.text}
                      icon="searchIcon"
                      loading={loading}
                      minLength={0}
                      debounceTimeout={800}
                      placeholder="Titulo/Publicação"
                    />
                  </div>
                  <section>
                    <p />
                    <p />
                    <p>Unidades vendidas</p>
                    <p>Preço unitário</p>
                    <p>Faturamento</p>
                  </section>
                </ListHeader>
              }
            >
              {sales.map(sale => (
                <List key={sale.item_id}>
                  <Item>
                    <img src={sale.img?.replace(/(http)(?=:)/, '$1s')} alt="" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={sale.url}
                    >
                      <p>{sale.product}</p>
                    </a>

                    <p>
                      {sale.quantity} <strong>Unidades vendidas</strong>
                    </p>
                    <p>
                      R$ {formatNumber(sale.price)}
                      <strong>Preço unitário</strong>
                    </p>
                    <p>
                      R$ {formatNumber(sale.billing)}
                      <strong>Faturamento</strong>
                    </p>
                  </Item>
                </List>
              ))}
            </ListScroll>
          </ListContainer>
        )}
      </Container>
    </>
  );
}
