import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaMapMarkedAlt } from 'react-icons/fa';
import api from '../../services/api';
import { Container, Content, Summary, ContainerAction } from './styles';
import { CustomButton, SearchUser, Rating } from '../../componets';

export default function Concurrent() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const [concurrents, setConcurrents] = useState([]);

  async function loadConcurrents() {
    try {
      setLoading(true);
      const res = await api.get('concurrent');
      setConcurrents(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  useEffect(() => {
    loadConcurrents();
    api.put('concurrent');
  }, []);

  const handleSearch = async ({ nickname }) => {
    if (!nickname) return;
    try {
      setLoading(true);
      const res = await api.get(`concurrent/${nickname}`);
      setUser(res);
      setLoading(false);
    } catch (e) {
      setUser(null);
      setLoading(false);
      toast.error('Usuário não encontrado', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSubmit = async ({ nickname }) => {
    try {
      setLoading(true);
      await api.post(`concurrent/${nickname}`);
      setUser(null);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      loadConcurrents();
    }
  };

  const handleDelete = async sellerId => {
    try {
      setLoading(true);
      await api.delete(`concurrent/${sellerId}`);
      setUser(null);
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      loadConcurrents();
    }
  };

  return (
    <Container>
      <Content>
        <SearchUser
          loading={loading}
          handleSearch={handleSearch}
          title="Concorrentes que você segue"
          user={user}
        >
          {user && (
            <ContainerAction>
              <CustomButton onClick={() => handleSubmit(user)} type="button">
                Seguir
              </CustomButton>
            </ContainerAction>
          )}
        </SearchUser>
        <Summary>
          <ul>
            {concurrents.map(concurrent => {
              const [rate, color] =
                concurrent.seller_reputation.level_id?.match(/\d|[a-z]+/g) ||
                [];

              return (
                <li key={concurrent._id}>
                  <div>
                    <h3>{concurrent.nickname}</h3>
                    <Rating
                      rate={parseInt(rate, 10)}
                      color={color}
                      label="Reputação do vendedor"
                      text={concurrent?.seller_reputation?.power_seller_status}
                    />
                  </div>
                  <div>
                    <FaMapMarkedAlt />
                    <p>{`${
                      concurrent?.address?.city
                    }${concurrent?.address?.state?.match(/-.*/)}`}</p>
                  </div>
                  <div>
                    <p>
                      Seguindo desde{' '}
                      <strong>
                        {moment(concurrent.createdAt).format('DD/MM/YYYY')}
                      </strong>
                    </p>
                  </div>
                  <div>
                    <CustomButton
                      onClick={() => handleDelete(concurrent._id)}
                      type="button"
                      inverted
                    >
                      Deixar de seguir
                    </CustomButton>
                  </div>
                </li>
              );
            })}
          </ul>
        </Summary>
      </Content>
    </Container>
  );
}
