import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../store/modules/adverts/actions';
import {
  CustomButton,
  ListScroll,
  Select,
  SellerNickname,
  SubHeader,
} from '../../componets';
import { ModalEdit } from './componentes';
import { Container, ListData, Item } from './styles';
import { formatMoney, makePlural } from '../../utils';

export default function AdvertsCopyConfirmation() {
  const {
    copy,
    user: { profile },
  } = useSelector(state => state);

  const [accounts_id, setAccounts_id] = useState([]);

  const handleChangeSelect = value => {
    const seller_ids = value ? value.map(({ value: id }) => id) : [];
    setAccounts_id(seller_ids);
  };

  const options = useMemo(
    () =>
      profile?.accounts?.map(acc => ({
        value: acc.id,
        label: acc.nickname,
      })),
    [profile]
  );

  const dispatch = useDispatch();

  const handleRemoveItem = id => {
    dispatch(actions.removeToCopy(id));
  };

  const handleEditItem = item => {
    dispatch(actions.editToCopy(item));
  };

  const handleCopy = () => {
    if (accounts_id.length > 0) dispatch(actions.requestToCopy(accounts_id));
    else toast.error('Selecione pelo menos uma conta de destino.');
  };

  return (
    <Container>
      <SubHeader text="Confirmação">
        <Select
          primary
          onChange={handleChangeSelect}
          options={options}
          closeMenuOnSelect={false}
          placeholder="Selecionar contas"
          isMulti
          allowSelectAll
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton to="/adverts/copy" inverted>
            Voltar
          </CustomButton>
          <CustomButton loading={copy.loading} onClick={handleCopy} inverted>
            Copiar
          </CustomButton>
        </div>
      </SubHeader>

      <ListScroll header={<h4>Anúncios selecionados {copy.items.length}</h4>}>
        {copy.items?.map(item => (
          <Item key={item.id}>
            <div>
              <img
                src={item?.secure_thumbnail?.replace(/(http)(?=:)/, '$1s')}
                alt=""
              />
            </div>
            <ListData>
              <a target="_blank" rel="noopener noreferrer" href={item?.link}>
                <h4>{item.title}</h4>
              </a>
              <div>
                <small>
                  {`${item.available_quantity} ${makePlural(
                    item.available_quantity,
                    'disponível'
                  )} | `}

                  <strong>{formatMoney(item.price)}</strong>
                </small>
              </div>

              <div>
                <small>Publicação {item.id}</small>
                <SellerNickname seller_id={item.seller_id} />
              </div>
            </ListData>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: '5px',
              }}
            >
              <CustomButton onClick={() => handleRemoveItem(item.id)} inverted>
                Remover
              </CustomButton>
              <ModalEdit onSubmit={handleEditItem} item={item} />
            </div>
          </Item>
        ))}
      </ListScroll>
    </Container>
  );
}
