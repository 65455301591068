import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  height: 400px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: ${theme.white};
  @media (max-width: ${theme.mobile}) {
    height: 350px;
  }
`;

export const HeaderChart = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr repeat(3, 1fr);
  column-gap: 5px;
  div {
    display: flex;
  }

  @media (max-width: ${theme.mobile}) {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 100%;
    }
    button {
      flex: 1;
    }
  }
`;

export const Tooltip = styled.div`
  background: white;
  padding: 9px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;

  & > p {
    color: ${lighten(0.1, theme.text)};
  }

  & > small {
    color: ${lighten(0.1, theme.text)};
    font-weight: 300;
  }

  div {
    div {
      display: flex;
    }
  }
`;
