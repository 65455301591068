import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { timeAgo } from '../../utils';

export const LoadInbox = packs => {
  const [loading, setLoading] = useState(false);
  const [inbox, setInbox] = useState({});
  const { account } = useSelector(state => state.user);

  const loadInbox = async () => {
    try {
      setLoading(true);
      const { order, ...res } = await api.get(`inbox/${packs}`);

      const messages = res.messages.map(message => {
        const isSeller = account.id === message.from;
        return {
          position: isSeller ? 'right' : 'left',
          title: isSeller ? 'Você' : order.buyer.first_name,
          titleColor: isSeller ? 'lightGreen' : 'gray',
          text: message.text?.replace(/(<a[^>]*>)|(<\/a>)/g, ''),
          dateString: timeAgo(message.date_received),
          status: 'sent',
          id: message.id,
          isSeller,
          read: message.read,
        };
      });
      const id = messages.reduce((prevValue, currentValue) => {
        if (currentValue.isSeller || currentValue.read) {
          return prevValue;
        }
        return `${currentValue.id},${prevValue}`;
      }, '');

      if (id) {
        api.get('inbox/mark_read', {
          params: { id },
        });
      }

      setInbox({ messages, order });
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const sendMenssage = async (order, text) => {
    try {
      const { buyer_id, id: order_id } = order;

      setInbox({
        ...inbox,
        messages: [
          ...inbox.messages,
          {
            position: 'right',
            title: 'Você',
            titleColor: 'lightGreen',
            text,
            dateString: timeAgo(new Date()),
            status: 'waiting',
          },
        ],
      });
      await api.post('inbox', { buyer_id, order_id, text });
      loadInbox();
    } catch (error) {
      toast.error(error.response?.data.error || error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (packs) loadInbox();
  }, [packs]);

  return [inbox, sendMenssage, loading];
};
