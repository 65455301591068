import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const BlockingResult = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.white};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;

  label {
    margin: 5px 0;
    cursor: pointer;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  background: ${theme.white};
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;

  thead {
    @media (max-width: ${theme.mobile}) {
      display: none;
    }
  }
  th {
    font-weight: 600;
  }
  tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    margin: 5px 0px;
    padding: 5px 0;
    align-items: center;

    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${transparentize(0.6, theme.lightgrey)};

    @media (max-width: ${theme.mobile}) {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 300;

    strong {
      display: none;
    }
    @media (max-width: ${theme.mobile}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      strong {
        display: inline;
      }
    }
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2px 0;
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.primary};
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  h3 {
    color: ${theme.white};
    font-size: 16px;
  }
  small {
    font-weight: 100;
  }
`;
