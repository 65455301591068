import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { Container, Content } from './styles';
import { CardInfo, DateRangeWrapped } from '..';

const MetricsBoard = ({ cardsData, handleChange, options }) => {
  return (
    <Container>
      <CreatableSelect
        isMulti
        onChange={value => handleChange({ target: { value, name: 'select' } })}
        options={options}
      />
      <DateRangeWrapped
        name="date"
        placeholder="Selecione uma data"
        onChange={handleChange}
        months={1}
      />
      <Content>
        {cardsData.map(({ icon, value, description, rate, compared }) => (
          <CardInfo
            key={description}
            icon={icon}
            description={description}
            value={value}
            rate={rate}
            compared={compared}
          />
        ))}
      </Content>
    </Container>
  );
};

MetricsBoard.propTypes = {
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.string,
      rate: PropTypes.number,
      compared: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  handleChange: PropTypes.func.isRequired,
};

MetricsBoard.defaultProps = {
  cardsData: [],
  options: [],
};

export default MetricsBoard;
