import React from 'react';
import PropTypes from 'prop-types';
import { MenuStyled, MenuItemStyled } from './styled';

export default function Menu({ routes }) {
  const renderMenuItem = route => (
    <MenuItemStyled
      exact
      to={route.path}
      key={route.title}
      activeClassName="active"
    >
      {route.title}
    </MenuItemStyled>
  );

  return (
    <>
      <MenuStyled>
        <ul>{routes.map(renderMenuItem)}</ul>
      </MenuStyled>
    </>
  );
}

Menu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
