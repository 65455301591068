import styled from 'styled-components';
import { lighten } from 'polished';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  box-shadow: 0 0 5px 3px ${theme.lightgrey};
  border-radius: 5px;
  background: ${theme.gradient};
  width: 100%;
  color: ${theme.text};
  min-width: 200px;
`;

export const Content = styled.div`
  padding: 25px;
  display: grid;
  grid-template:
    'title title title' 40px
    '. . .' 80px / 1fr 1fr 1fr;

  gap: 5px;
  h3 {
    grid-area: title;
    color: ${theme.white};
  }
  div {
    align-self: center;
    color: ${theme.white};

    p {
      font-weight: 600;
    }
    div {
      display: grid;
      grid-template-columns: 1fr 5fr;
      gap: 5px;
      align-items: center;
      padding: 10px 0;

      span {
        background: ${lighten(0.15, theme.primary)};
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin: 2px;
        }
      }
    }
  }
`;
