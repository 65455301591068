import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalStyled, StyledPopup } from './styles';
import { CustomButton, HeaderModal } from '..';
import { formatNumber } from '../../utils';

const SummaryModalSales = ({ title, sale, children }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  return (
    <CustomButton small inverted onClick={() => setOpen(true)}>
      {children}
      <StyledPopup open={open} onClose={onClose} nested modal>
        <ModalStyled>
          <HeaderModal title={title} onClose={onClose} />
          <ul>
            <li>
              <strong>Unidades vendidas</strong>
              <p>{sale.quantity}</p>
            </li>
            <li>
              <strong>Valor médio de venda</strong>
              <p>R$ {formatNumber(sale.unit_price_mean)}</p>
            </li>
            <li>
              <strong>Faturamento</strong>
              <p>R$ {formatNumber(sale.total_amount)}</p>
            </li>
            <li>
              <strong>Preço de custo</strong>
              <p className="draw">R$ {formatNumber(sale.cost_price)}</p>
            </li>
            <li>
              <strong>Custos</strong>
              <p className="draw">R$ {formatNumber(sale.custom_cost)}</p>
            </li>
            <li>
              <strong>Taxa Mercado Envios</strong>
              <p className="draw">R$ {formatNumber(sale.cost_shipping)}</p>
            </li>
            <li>
              <strong>Taxa Mercado Livre</strong>
              <p className="draw">R$ {formatNumber(sale.sale_fee)}</p>
            </li>
            <li>
              <strong>Lucro</strong>
              <p className={sale.profit > 0 ? 'profit' : 'draw'}>
                R$ {formatNumber(sale.profit)}
              </p>
            </li>
          </ul>
        </ModalStyled>
      </StyledPopup>
    </CustomButton>
  );
};

SummaryModalSales.propTypes = {
  title: PropTypes.string.isRequired,
  sale: PropTypes.shape({
    sale_fee: PropTypes.number,
    cost_shipping: PropTypes.number,
    custom_cost: PropTypes.number,
    cost_price: PropTypes.number,
    total_amount: PropTypes.number,
    unit_price_mean: PropTypes.number,
    quantity: PropTypes.number,
    profit: PropTypes.number,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SummaryModalSales;
