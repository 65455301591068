export const theme = {
  primary: '#554AE5',
  white: '#ffffff',
  black: '#000000',
  sidebar: '#1E1E30',
  background: '#F1F1F1',
  error: '#f8270c',
  success: '#0BBB3B',
  text: '#1E1E30',
  negative: '#f89e92',
  positive: '#3bc067',
  notification: '#ff892e',
  lightgrey: '#BCBCBC',
  mobile: '576px',
  desktop: '1366px',
  gradient: 'transparent linear-gradient(142deg, #554AE5 30%, #9F98F3 100%)',
  reputation: ['#fa5f59', '#feb657', '#fde944', '#b3e421', '#4bba3f'],
};
