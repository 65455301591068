import React from 'react';
import { NavLink } from 'react-router-dom';
import Proptypes from 'prop-types';
import { IconWrapper } from '../../..';

import { StyledMenu, Container } from './styles';

const Menu = ({ open, tabs }) => {
  return (
    <Container open={open}>
      <StyledMenu open={open}>
        <ul>
          {tabs.map(tab => (
            <li key={tab.path}>
              <NavLink activeClassName="active" to={tab.path}>
                <IconWrapper name={tab.icon} />
                <p>{tab.text}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      </StyledMenu>
    </Container>
  );
};

Menu.propTypes = {
  open: Proptypes.bool.isRequired,
  tabs: Proptypes.arrayOf(Proptypes.object).isRequired,
};
export default Menu;
