import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyToClipboardWraper = ({ text, children }) => {
  const onCopy = () => {
    toast.info('Copiado para a área de tranferência', {
      autoClose: 1500,
      position: 'bottom-center',
    });
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={text}>
      {children}
    </CopyToClipboard>
  );
};

CopyToClipboardWraper.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

CopyToClipboardWraper.defaultProps = {};

export default CopyToClipboardWraper;
