import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 5px 10px;
  ul {
    overflow-y: scroll;
    max-height: 70vh;
  }

  form {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const Product = styled.div`
  display: flex;
  align-items: center;
  small {
    display: block;
    font-weight: 100;
  }

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
  button {
    margin: 0 5px;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template:
    'a b' 1fr
    'a b' 1fr
    'select select' 1fr
    'description description' 1fr
    / 1fr 1fr;

  & > .select {
    grid-area: select;
  }

  & > .description {
    grid-area: description;
  }

  justify-content: center;
  grid-gap: 20px 40px;
  margin: 40px 0;

  label {
    font-size: smaller;
    font-weight: 100;
  }
`;
