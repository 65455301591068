import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalYN, CustomButton } from '../../../../componets';
import { ItemsSelected } from '../ModalMerge/styles';

const ModalDelete = ({ onSubmit, stocks }) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = id => {
    return onSubmit(id).then(() => setOpen(false));
  };

  return (
    <CustomButton disabled={!stocks?.length} onClick={() => setOpen(true)}>
      Deletar
      <ModalYN
        open={open}
        onClose={() => setOpen(false)}
        onAccept={() => handleSubmit(stocks.map(({ _id }) => _id))}
        title="Excluir produto"
        body={
          <ItemsSelected>
            {stocks.map(i => (
              <button key={i._id} type="button">
                {i.items_id.join(', ')}
              </button>
            ))}
          </ItemsSelected>
        }
      />
    </CustomButton>
  );
};

ModalDelete.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stocks: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default ModalDelete;
