import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { theme } from '../../utils';

export const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 5px;
    padding: 0px;
    width: unset;
  }
`;

export const ModalStyled = styled.div`
  .draw {
    color: ${theme.negative};
  }

  .profit {
    color: ${theme.success};
  }
  ul {
    padding: 15px;
  }

  li {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    p {
      margin-left: 50px;
    }

    strong {
      font-weight: 600;
    }
  }
`;
