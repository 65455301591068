import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-spinners/CircleLoader';
import { theme } from '../../utils';
import { Container } from './styles';

const LoaderWraper = ({ color, loading, full, ...props }) => {
  return full ? (
    loading && (
      <Container>
        <Loader loading={loading} {...props} color={color} />
      </Container>
    )
  ) : (
    <Loader loading={loading} {...props} color={color} />
  );
};

LoaderWraper.propTypes = {
  loading: PropTypes.bool,
  full: PropTypes.bool,
  color: PropTypes.string,
};

LoaderWraper.defaultProps = {
  loading: false,
  full: false,
  color: theme.primary,
};

export default LoaderWraper;
