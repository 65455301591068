import React from 'react';
import PropTypes from 'prop-types';
import { ModalStyled, StyledPopup } from './styles';
import { HeaderModal } from '..';

const FormModal = ({ onClose, open, title, children }) => {
  return (
    <StyledPopup open={open} onClose={onClose} modal>
      <ModalStyled>
        <HeaderModal title={title} onClose={onClose} />
        {children}
      </ModalStyled>
    </StyledPopup>
  );
};

FormModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

FormModal.defaultProps = {
  title: 'Cadastrar novo produto',
};

export default FormModal;
