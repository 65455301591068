import styled from 'styled-components';
import { theme } from '../../utils/colors';

export const Container = styled.div`
  color: ${theme.white};
  background: ${theme.primary};
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
  position: relative;

  .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: ${theme.white};
    opacity: 0.85;
    border-radius: 18px;
    color: ${theme.primary};
    border: 1px solid ${theme.white};
  }
`;
