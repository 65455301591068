import React from 'react';
import PropTypes from 'prop-types';
import CsvDownloader from 'react-csv-downloader';
import CustomButton from '../CustomButton';

export default function ExportCSV({ data, filename }) {
  return (
    <CsvDownloader filename={filename} extension=".csv" datas={data}>
      <CustomButton small inverted>
        Download
      </CustomButton>
    </CsvDownloader>
  );
}

ExportCSV.propTypes = {
  data: PropTypes.oneOf([PropTypes.array, PropTypes.func]),
  filename: PropTypes.string,
};

ExportCSV.defaultProps = {
  data: [],
  filename: 'ozix',
};
